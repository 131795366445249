import React, { useState, useEffect, useLayoutEffect } from "react";
import Recipe from "../interfaces/RecipeInterface";
import { GoPlus } from "react-icons/go";
import { FiClock } from "react-icons/fi";
import RecipeThumbnail from "./RecipeThumbnail";
import { useQueryClient, useQuery, useMutation } from "react-query";
import { getRecipesFromCart, getRecipesFromFavorites } from "./API/API";

import {
  MdPerson,
  MdFavorite,
  MdFavoriteBorder,
  MdUpdate,
  MdRemove,
} from "react-icons/md";

import {
  TiPlus,
  TiMinus,
  TiDeleteOutline,
  TiSortAlphabetically,
  TiDelete,
} from "react-icons/ti";
import FocusRecipe from "./FocusRecipe";
import { useRoute } from "wouter";
import { indexOfRecipeInRecipeArray, sortRecipes } from "./API/OfflineAPI";
import { reverse } from "dns";

let componentScrollYOffset = 0;

let formatTime = (timeInMinutes: number): string => {
  let result = "";
  let hours = Math.floor(timeInMinutes / 60);
  if (hours > 0) {
    result += hours + "hr";
  }

  let minutes = timeInMinutes % 60;
  result += minutes + "m";
  return result;
};

const YourRecipes = () => {
  const [focusedRecipe, params] = useRoute("/cart/:recipeid");

  const [sortValue, setSortValue] = useState("abc");
  const [isReverse, setReverse] = useState(false);
  // useEffect((

  // ) => {}, [sortABC, sortServing, sortTime])

  //const [recipes, setRecipes] = useState([...cartRecipes]);

  const [recipes, setRecipes] = useState([] as Recipe[]);

  // const { data: cartRecipes = [] as Recipe[] } = useQuery(
  const {isFetching} = useQuery(
    ["cart.me"],
    getRecipesFromCart,
    {
      onSuccess: (data) => {
        if (data) {
          ///do something better
          setRecipes(sortRecipes(sortValue, isReverse, [...data]));
        }
      },
    }
  );

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    setRecipes(sortRecipes(sortValue, isReverse, [...recipes]));
  }, [sortValue, isReverse]);

  let indexOfFocusedRecipe = -1;
  if (focusedRecipe) {
    indexOfFocusedRecipe = indexOfRecipeInRecipeArray(params.recipeid, recipes);
  }

  return (
    <div className="flex flex-wrap justify-around w-full">
      {indexOfFocusedRecipe >= 0 && (
        <FocusRecipe recipe={recipes[indexOfFocusedRecipe]} panel="cart" />
      )}
      {recipes.length === 0 && !isFetching && (
        <label className="m-auto text-3xl">Looks like your cart is empty.</label>
      )}
      {indexOfFocusedRecipe == -1 && recipes.length > 0 && (
        <div>
          <div className="flex flex-nowrap justify-center mt-[20px] text-4xl">
            <span
              className="mx-[10px] border-[3px] cursor-pointer bg-gray1 p-[3px]"
              style={{borderColor: sortValue === "abc" ? "#f3f4f6" : "#252526"}}
              onClick={() => {
                setReverse(sortValue === "abc" ? !isReverse : false);
                setSortValue("abc");
              }}
            >
              <TiSortAlphabetically />
            </span>
            <span
              className="mx-[10px] border-[3px] border-gray3 cursor-pointer bg-gray1 p-[3px]"
              style={{borderColor: sortValue === "serving" ? "#f3f4f6" : "#252526"}}
              onClick={() => {
                setReverse(sortValue === "serving" ? !isReverse : false);
                setSortValue("serving");
              }}
            >
              <MdPerson />
            </span>
            <span
              className="mx-[10px] border-[3px] border-gray3 cursor-pointer bg-gray1 p-[3px]"
              style={{borderColor: sortValue === "time" ? "#f3f4f6" : "#252526"}}
              onClick={() => {
                setReverse(sortValue === "time" ? !isReverse : false);
                setSortValue("time");
              }}
            >
              <FiClock />
            </span>
          </div>
          <div className={"w-full flex flex-wrap justify-around"}>
            {recipes &&
              recipes.map((recipe) => {
                return (
                  <RecipeThumbnail
                    key={recipe.ID}
                    recipe={recipe}
                    panel="cart"
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default YourRecipes;
