import React, { useState, useEffect } from "react";
import UserInterface from "../../interfaces/UserInterface";
import { setSettings } from "../API/API";

interface UserSettingsProps {
  user: UserInterface
  setUser: (user: (UserInterface | null)) => void
}

async function sha256(hash: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(hash);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => ("00" + b.toString(16)).slice(-2))
    .join("");

  return hashHex;
}



const UserSettings = (props: UserSettingsProps) => {
  
  const [displayName, setDisplayName] = useState(props.user.DisplayName);
  const [hideFavorites, setHideFavorites] = useState(props.user.HideFavorites);

  useEffect(() => {
    window.scrollTo(0, 0);
  });


  const updateUser = () => {
    let updatedUser = {...props.user}

    updatedUser.DisplayName = "" + displayName
    if(hideFavorites !== undefined) {
      updatedUser.HideFavorites = hideFavorites
    }
    
    setSettings(updatedUser.HideFavorites, updatedUser.DisplayName)
    props.setUser(updatedUser)
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        textAlign: "center",
      }}
    >
      <div>
        <span>DisplayName: {displayName}</span>
        <input className="ml-[10px] text-black rounded-md"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>

      <div>
        <span>Hide Favorites: </span>
        <span className="cursor-pointer" onClick={() => {
          setHideFavorites(!hideFavorites)
        }}>{""+hideFavorites}</span>
      </div>


      <span className="mt-[10px] cursor-pointer" onClick={() => {updateUser()}}>Update User</span>
    </div>
  );
};

export default UserSettings;
