import React, { useState, useEffect, useLayoutEffect } from "react";
import Recipe from "../interfaces/RecipeInterface";
import RecipeThumbnail from "./RecipeThumbnail";
import { FiClock } from "react-icons/fi";
import {
  MdPerson,
} from "react-icons/md";

import {
  TiSortAlphabetically,
} from "react-icons/ti";
import { useQuery, useQueryClient } from "react-query";
import { getRecipesFromFavorites } from "./API/API";
import FocusRecipe from "./FocusRecipe";
import { useRoute } from "wouter";
import { indexOfRecipeInRecipeArray, sortRecipes } from "./API/OfflineAPI";

const Favorites = () => {
  const {isFetching} = useQuery(
    ["favorites.me"],
    getRecipesFromFavorites, 
    {
      onSuccess: (data) => {
        if(data) {
          setRecipes(sortRecipes(sortValue, isReverse, [...data]))
        }
      }
    },
  );



  const [sortValue, setSortValue] = useState("abc");
  const [isReverse, setReverse] = useState(false);
  const [recipes, setRecipes] = useState([] as Recipe[]);



  const [focusedRecipe, params] = useRoute("/favorites/:recipeid");
  let indexOfFocusedRecipe = -1;
  if (focusedRecipe) {
    indexOfFocusedRecipe = indexOfRecipeInRecipeArray(
      params.recipeid,
      recipes
    );
  }

  useEffect(() => {
    setRecipes(sortRecipes(sortValue, isReverse, [...recipes]))
  },[sortValue, isReverse])

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="flex flex-wrap justify-around w-full">
      {recipes.length === 0 && !isFetching && <div className="w-full justify-around flex text-3xl">
          <label>Oops! We don't see any favorites.</label>
      </div>}

      {indexOfFocusedRecipe >= 0 && (
        <FocusRecipe
          recipe={
            recipes[
              indexOfFocusedRecipe
            ]
          }
          panel="favorites"
        />
      )}

      {indexOfFocusedRecipe == -1 && recipes.length > 0 && (
        <div>
          <div className="flex flex-nowrap justify-center mt-[20px] text-4xl">
            <span
              className="mx-[10px] border-[3px] bg-gray1 cursor-pointer p-[3px]"
              style={{borderColor: sortValue === "abc" ? "#f3f4f6" : "#252526"}}
              onClick={() => {
                setReverse(sortValue === "abc" ? !isReverse : false);
                setSortValue("abc");
              }}
            >
              <TiSortAlphabetically />
            </span>
            <span
              className="mx-[10px] border-[3px] bg-gray1 cursor-pointer p-[3px]"
              style={{borderColor: sortValue === "serving" ? "#f3f4f6" : "#252526"}}
              onClick={() => {
                setReverse(sortValue === "serving" ? !isReverse : false);
                setSortValue("serving");
              }}
            >
              <MdPerson />
            </span>
            <span
              className="mx-[10px] border-[3px] bg-gray1 cursor-pointer p-[3px]"
              style={{borderColor: sortValue === "time" ? "#f3f4f6" : "#252526"}}
              onClick={() => {
                setReverse(sortValue === "time" ? !isReverse : false);
                setSortValue("time");
              }}
            >
              <FiClock />
            </span>
          </div>
          <div className={"w-full flex flex-wrap justify-around"}>
            {recipes &&
              recipes.map((recipe) => {
                return (
                  <RecipeThumbnail
                    key={recipe.ID}
                    recipe={recipe}
                    panel="favorites"
                  />
                );
              })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Favorites;
