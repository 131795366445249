import React from "react";
import UserInterface from "../interfaces/UserInterface";
import CreateOrEditRecipe from "./Profile/CreateOrEditRecipe";
import OwnedRecipes from "./Profile/OwnedRecipes";
import UserSettings from "./Profile/UserSettings";
import { Link, Route, useLocation, useRoute } from "wouter";

interface ProfileProps {
  setUser: (user: UserInterface | null) => void;
  user: UserInterface;
}

const Profile = (props: ProfileProps) => {
  // Declare a new state variable, which we'll call "count"

  const [location, setLocation] = useLocation();
  const [focusedRecipe, params] = useRoute("/profile/ownedrecipes/:recipeid");
  let paramsValue = params ? params.recipeid : "";

  return (
    <div>
      <div className="flex flex-nowrap justify-evenly w-full">
        <Link
          href="/profile/ownedrecipes"
          onClick={() => {
            setLocation("/profile/ownedrecipes");
          }}
        >
          <a>Owned Recipes</a>
        </Link>

        <Link
          href="/profile/createrecipe"
          onClick={() => {
            setLocation("/profile/createrecipe");
          }}
        >
          <a>Create Recipe</a>
        </Link>

        <Link
          href="/profile/settings"
          onClick={() => {
            setLocation("/profile/settings");
          }}
        >
          <a>User Settings</a>
        </Link>

        <Link
          href="/Browse"
          onClick={() => {
            props.setUser(null);
            setLocation("/");
          }}
        >
          <a>Sign Out</a>
        </Link>
      </div>
      <div className="mt-[50px]">
        <Route path="/profile/ownedrecipes">
          <OwnedRecipes recipeID="" />
        </Route>

        <Route path="/profile/ownedrecipes/:recipeid">
          <OwnedRecipes recipeID={paramsValue} />
        </Route>

        <Route path="/profile/createrecipe">
          <CreateOrEditRecipe recipe={null} />
        </Route>

        <Route path="/profile/settings">
          <UserSettings setUser={props.setUser} user={props.user} />
        </Route>

        {/* {getProfileComponentByName(componentName)} */}
      </div>
    </div>
  );
};

export default Profile;
