import Ingredient from "../../interfaces/Ingredient";
import Instruction from "../../interfaces/Instruction";
import InstructionSegment from "../../interfaces/InstructionSegment";
import Recipe from "../../interfaces/RecipeInterface";
import SearchOptions from "../../interfaces/SearchOptions";
import SpecialTextWithinInstruction from "../../interfaces/SpecialTextWithinInstruction";

// export const login = async(username: string, password: string): Promise<boolean> => {
//   const response = await axios.post('login.???', {username, password});

//   token = response.token;

//   return true;
// }
let cart: Recipe[] = [];
let favorites: Recipe[] = [];
let ingredientsClone: Ingredient[] = []
let ingredientClassificationRanking = [
  "recipe",
  "fresh produce",
  "bread",
  "meat",
  "seafood",
  "dairy",
  "pantry",
  "frozen",
  "other",
];
let ingredientClassificationColor: { [id: string]: string } = {};
ingredientClassificationColor["recipe"] = "#D20F98";
ingredientClassificationColor["fresh produce"] = "#AAFF00";
ingredientClassificationColor["bread"] = "#7B3F00	";
ingredientClassificationColor["meat"] = "#fc0303";
ingredientClassificationColor["seafood"] = "#fc03d2";
ingredientClassificationColor["dairy"] = "#44cbe3";
ingredientClassificationColor["pantry"] = "#fc8c03";
ingredientClassificationColor["frozen"] = "#3503fc";
ingredientClassificationColor["other"] = "#fcfc03";
ingredientClassificationColor["black"] = "#000000";

let pluralIngredients = ["garlic", "broccoli"];

type Unit = {
  unit: string;
  abbreviation: string;
};

let usUnits: Unit[] = [];
usUnits.push({ unit: "none", abbreviation: "x" });
usUnits.push({ unit: "teaspoon", abbreviation: "tsp" });
usUnits.push({ unit: "tablespoon", abbreviation: "tbsp" });
usUnits.push({ unit: "ounce", abbreviation: "oz" });
usUnits.push({ unit: "fluid ounce", abbreviation: "fl oz" });
usUnits.push({ unit: "cup", abbreviation: "c" });
usUnits.push({ unit: "pint", abbreviation: "pt" });
usUnits.push({ unit: "quart", abbreviation: "qt" });
usUnits.push({ unit: "pound", abbreviation: "lb" });
usUnits.push({ unit: "gallon", abbreviation: "gal" });
usUnits.push({ unit: "gram", abbreviation: "g" });
usUnits.push({ unit: "kilogram", abbreviation: "kg" });
usUnits.push({ unit: "milliliter", abbreviation: "ml" });
usUnits.push({ unit: "liter", abbreviation: "l" });

let searchOptions: SearchOptions = {
  From: 0,
  Title: "",
  Country: "",
  TimeMinutesMin: 0,
  TimeMinutesMax: 0,
  Source: "",
  Ingredients: [],
  NotIngredients: [],
  NotClassification: [],
  UserID: 0,
};

let browseYScroll = 0;

export const getBrowseYScroll = (): number => {
  return browseYScroll;
};

export const setBrowseYScroll = (scrollValue: number) => {
  browseYScroll = scrollValue;
};

export const getOfflineSearchOptions = (): SearchOptions => {
  return { ...searchOptions };
};

export const setOfflineSearchOptions = (options: SearchOptions) => {
  searchOptions = options;
};

export const getUSUnits = (): Unit[] => {
  return [...usUnits];
};

export const getHexColor = (ingredientClassification: string): string => {
  if (ingredientClassificationColor[ingredientClassification]) {
    return ingredientClassificationColor[ingredientClassification];
  }

  return ingredientClassificationColor["#f3f4f6"];
};

export const getRandomHexColor = (): string => {
  let randomIndex = Math.floor(
    Math.random() * ingredientClassificationRanking.length
  );
  return getHexColor(ingredientClassificationRanking[randomIndex]);
};

export const formatTime = (timeInMinutes: number): string => {
  let result = "";
  let hours = Math.floor(timeInMinutes / 60);
  if (hours > 0) {
    result += hours + "hr";
  }

  let minutes = timeInMinutes % 60;
  result += minutes + "m";
  return result;
};

export const getIngredientClassificationRanking = () => {
  return [...ingredientClassificationRanking];
};

export const getClassificationRanking = (classification: string): number => {
  return ingredientClassificationRanking.indexOf(classification);
};

export const getOfflineCart = () => {
  const currentCart = [...cart];
  return currentCart;
};

export const getOfflineFavorites = () => {
  const currentFavorites = [...favorites];
  return currentFavorites;
};

export const setOfflineCart = (recipes: Recipe[]) => {
  cart = [...recipes];
};

export const setOfflineFavorites = (recipes: Recipe[]) => {
  favorites = [...recipes];
};

export const addOfflineRecipeToCart = (recipe: Recipe) => {
  const currentRecipe = { ...recipe };
  const index = indexOfRecipeInRecipeArray(currentRecipe.ID, cart);
  if (index == -1) {
    cart = [currentRecipe, ...cart];
  }
};

export const removeOfflineRecipeFromCart = (recipe: Recipe) => {
  const index = indexOfRecipeInRecipeArray(recipe.ID, cart);
  if (index >= 0) {
    cart.splice(index, 1);
  }
};

export const updateOfflineRecipeInCart = (recipe: Recipe) => {
  const index = indexOfRecipeInRecipeArray(recipe.ID, cart);
  if (index >= 0) {
    cart[index].CustomServings = recipe.CustomServings;
  }
};

export const addOfflineRecipeToFavorites = (recipe: Recipe) => {
  const currentRecipe = { ...recipe };
  const index = indexOfRecipeInRecipeArray(currentRecipe.ID, favorites);
  if (index == -1) {
    favorites = [currentRecipe, ...favorites];
  }
};

export const updateOfflineRecipeInFavorites = (recipe: Recipe) => {
  const index = indexOfRecipeInRecipeArray(recipe.ID, favorites);
  if (index >= 0) {
    favorites[index].CustomServings = recipe.CustomServings;
  }
};

export const removeOfflineRecipeFromFavorites = (recipe: Recipe) => {
  const index = indexOfRecipeInRecipeArray(recipe.ID, favorites);
  if (index >= 0) {
    favorites.splice(index, 1);
  }
};

export const indexOfRecipeInRecipeArray = (
  id: string,
  recipes: Recipe[]
): number => {
  const index = recipes.map((recipe: Recipe) => recipe.ID).indexOf(id);
  return index;
};

export const setIngredientsClone = (ingredients: Ingredient[]) => {
  ingredientsClone = [...ingredients]
}

export const getIngredientsClone = (): Ingredient[] => {
  return [...ingredientsClone]
}

// async function sha256(hash: string) {
//   // encode as UTF-8
//   const msgBuffer = new TextEncoder().encode(hash);

//   // hash the message
//   const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

//   // convert ArrayBuffer to Array
//   const hashArray = Array.from(new Uint8Array(hashBuffer));

//   // convert bytes to hex string
//   const hashHex = hashArray
//     .map((b) => ("00" + b.toString(16)).slice(-2))
//     .join("");

//   return hashHex;
// }

export const sortRecipes = (
  sortValue: string,
  reverse: boolean,
  currentRecipes: Recipe[]
): Recipe[] => {
  //let currentRecipes = [...recipes]
  if (sortValue === "abc") {
    currentRecipes.sort((a, b) => {
      if (a.Title > b.Title) {
        return 1;
      } else if (a.Title === b.Title) {
        return 0;
      } else {
        return -1;
      }
    });
    currentRecipes.sort();
  } else if (sortValue === "serving") {
    currentRecipes.sort((a, b) => {
      if (a.CustomServings > b.CustomServings) {
        return 1;
      } else if (a.CustomServings === b.CustomServings) {
        if (a.Title > b.Title) {
          return 1;
        } else if (a.Title === b.Title) {
          return 0;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    });
    currentRecipes.sort();
  } else if (sortValue === "time") {
    currentRecipes.sort((a, b) => {
      if (a.TimeMinutes > b.TimeMinutes) {
        return 1;
      } else if (a.TimeMinutes === b.TimeMinutes) {
        if (a.Title > b.Title) {
          return 1;
        } else if (a.Title === b.Title) {
          return 0;
        } else {
          return -1;
        }
      } else {
        return -1;
      }
    });
    currentRecipes.sort();
  }

  if (reverse) {
    currentRecipes = currentRecipes.reverse();
  }

  return currentRecipes;
};

export const parseJsonRecipe = (jsonString: string): Recipe | undefined => {
  jsonString = jsonString.replaceAll("*", "");

  let jsonRecipe: any;
  type source = {
    source: string;
    Source: string;
    origin: string;
    Origin: string;
  };

  let currentSource: source;

  try {
    jsonRecipe = JSON.parse(jsonString.toLowerCase());
    currentSource = JSON.parse(jsonString);
  } catch (e) {
    return;
  }

  let currentIngredients = new Map<string, Ingredient>([]);

  if (
    typeof jsonRecipe.ingredients !== "string" &&
    Array.isArray(jsonRecipe.ingredients)
  ) {
    let currentValue = jsonRecipe.ingredients as [];
    currentValue.forEach((value: any) => {
      let skip = false;
      let currentName = "";
      let currentQuantity = 0;
      let currentUnit = "";
      let currentComment = "";

      if (typeof value === "string") {
        if ((value as string).indexOf("<hr>") >= 0) {
          skip = true;
        } else {
          value = value.replace("\r", "");

          if ((value as string).indexOf(",") > 0) {
            currentComment = (value as string).substring(
              (value as string).indexOf(",")
            );
          }
          let words: string[];
          if (currentComment.length > 0) {
            words = (value as string)
              .substring(0, (value as string).indexOf(","))
              .split(" ");
          } else {
            words = (value as string).split(" ");
          }

          for (let i = 0; i < words.length; i++) {
            let fractionIndex = words[i].indexOf("/");

            if (fractionIndex > 0) {
              let numerator = parseFloat(words[i].substring(0, fractionIndex));
              let denominator = parseFloat(
                words[i].substring(fractionIndex + 1)
              );

              if (numerator !== NaN && denominator !== NaN) {
                currentQuantity += numerator / denominator;
              }
            }

            let numberValue = parseFloat(words[i]);
            if (!isNaN(numberValue) && fractionIndex < 0) {
              currentQuantity += numberValue;
            }

            let unit = getUSUnits().find((value) => {
              if (value.abbreviation === words[i] || value.unit === words[i]) {
                return true;
              }
              return false;
            });

            if (unit) {
              currentUnit = unit.unit;
            } else if (
              words[i].charAt(words[i].length - 1) === "g" &&
              numberValue > 0
            ) {
              currentUnit = "gram";
            }

            if (
              isNaN(numberValue) &&
              fractionIndex === -1 &&
              unit === undefined
            ) {
              currentName += words[i] + " ";
            }
          }
          currentName = currentName.substring(0, currentName.length - 1);
        }
      }

      let currentIngredient: Ingredient = {
        Name:
          currentName.length > 0 ? currentName : value.name ? value.name : "",
        Quantity:
          currentQuantity > 0
            ? currentQuantity
            : value.quantity
            ? value.quantity
            : 0,
        Comment:
          currentComment.length > 0
            ? currentComment
            : value.comment
            ? value.comment
            : "",
        Classification:
          value.classification && typeof value.classification === "string"
            ? value.classification
            : "",
        Unit:
          currentUnit.length > 0 ? currentUnit : value.unit ? value.unit : "",
      };
      if (!skip) {
        let ingredientInMap = currentIngredients.get(
          currentIngredient.Name + currentIngredient.Unit
        );
        if (ingredientInMap) {
          ingredientInMap.Quantity += currentIngredient.Quantity;
        } else {
          currentIngredients.set(
            currentIngredient.Name + currentIngredient.Unit,
            currentIngredient
          );
        }
      }
    });
  }

  let currentInstructions: Instruction[] = [];
  if (typeof jsonRecipe.instructions === "string") {
    const result = jsonRecipe.instructions.split(/\r?\n/) as string[];
    result.forEach((value, index) => {
      if (value.length > 0) {
        let capitalizeLetter = true;
        let formattedString = "";
        for (let i = 0; i < value.length; i++) {
          if (
            capitalizeLetter &&
            value[i].toUpperCase() != value[i].toLowerCase()
          ) {
            formattedString += value[i].toUpperCase();
            capitalizeLetter = false;
          } else {
            formattedString += value[i];
            if (value[i] === "." || value[i] === "?" || value[i] === "!") {
              capitalizeLetter = true;
            }
          }
        }
        currentInstructions.push({
          Header: "",
          Instruction: formattedString,
          Order: index,
          ImgID: "",
        } as Instruction);
      }
    });
  } else if (Array.isArray(jsonRecipe.instructions)) {
    let currentValue = jsonRecipe.instructions as [];
    currentValue.forEach((value: any, index) => {
      let currentInstruction: Instruction = {
        Header:
          value.header && typeof value.header === "string"
            ? (value.header as string)
            : "",
        Instruction:
          value.instruction && typeof value.instruction === "string"
            ? value.instruction
            : typeof value === "string"
            ? value
            : "",
        Order: index,
        ImgID: "",
      };

      let capitalizeLetter = true;
      let formattedString = "";
      for (let i = 0; i < currentInstruction.Instruction.length; i++) {
        if (
          capitalizeLetter &&
          currentInstruction.Instruction[i].toUpperCase() !=
            currentInstruction.Instruction[i].toLowerCase()
        ) {
          formattedString += currentInstruction.Instruction[i].toUpperCase();
          capitalizeLetter = false;
        } else {
          formattedString += currentInstruction.Instruction[i];
          if (
            currentInstruction.Instruction[i] === "." ||
            currentInstruction.Instruction[i] === "?" ||
            currentInstruction.Instruction[i] === "!"
          ) {
            capitalizeLetter = true;
          }
        }
      }

      currentInstruction.Instruction = formattedString;

      currentInstructions.push(currentInstruction);
    });
  }

  let currentCookware: string[] = [];
  if (typeof jsonRecipe.cookware === "string") {
    currentCookware.push(jsonRecipe.cookware);
  } else if (Array.isArray(jsonRecipe.cookware)) {
    (jsonRecipe.cookware as []).forEach((value) => {
      if (typeof value === "string") {
        currentCookware.push(value);
      }
    });
  }

  let parsedRecipe: Recipe = {
    Title: jsonRecipe.title
      ? jsonRecipe.title
      : jsonRecipe.name
      ? jsonRecipe.name
      : "",
    Cuisine: jsonRecipe.Cuisine ? jsonRecipe.Cuisine : "",
    Servings: jsonRecipe.servings ? jsonRecipe.servings : "",
    TimeMinutes: jsonRecipe.timeminutes
      ? jsonRecipe.timeminutes
      : jsonRecipe.time
      ? jsonRecipe.time
      : 0,
    Source: currentSource.source
      ? currentSource.source
      : currentSource.Source
      ? currentSource.Source
      : currentSource.Origin
      ? currentSource.Origin
      : currentSource.origin
      ? currentSource.origin
      : "",
    IsSearchable: false,
    IsUserIDVisible: false,
    IsHistoryVisible: false,
    Cookware: currentCookware,
    Ingredients: Array.from(currentIngredients.values()),
    Instructions: currentInstructions,
    ID: "",
    UserID: 0,
    CustomServings: 0,
  };

  return parsedRecipe;
};

export const getQuantityFromText = (text: string): number => {
  const words = text.split(" ");

  const wholeNumbers: string[] = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ];

  const fractionNumbers: string[] = [
    "half", // 0 
    "third", // 1
    "fourth", // 2
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
  ];

  let quantity = 0;

  words.forEach((word) => {
    if (isNaN(Number(word))) {
      const fractionIndex = word.indexOf("/");
      if (fractionIndex !== -1) {
        let numerator = parseFloat(word.substring(0, fractionIndex));
        let denominator = parseFloat(word.substring(fractionIndex + 1));

        if (!isNaN(numerator) && !isNaN(denominator)) {
          quantity += numerator / denominator;
        }
      } else {
        const indexOfWholeNumber = wholeNumbers.indexOf(word);
        const indexOfFractionNumber = fractionNumbers.indexOf(word)
        if (indexOfWholeNumber === 0) {
          quantity = indexOfWholeNumber + 1;
        } else if (indexOfFractionNumber === 0) {
          quantity = 1 / (indexOfFractionNumber + 2)
        } else {  
          //quantity tied in with units ex 400g
          const potentialQuantity = parseFloat(word)
          if(potentialQuantity > 0) {
            quantity += potentialQuantity
          }
        }
      }
      //idk what word we have, skip
    } else {
      quantity += parseFloat(word);
    }
  });

  
  return Math.floor(quantity * 1000) / 1000;
};


//change to ImportantValues

export const formatInstructionFromSpecialText = (
  instruction: string,
  instructionIndex: number,
  specialTextWithinInstructions: SpecialTextWithinInstruction[]
): InstructionSegment[] => {
  //special text is naturally sorted
  let instructionSegments: InstructionSegment[] = [];
  let lastSpecialTextIndex = -1;

  let firstTimeIn = true;
  specialTextWithinInstructions.forEach(
    (currentSpecialText, specialTextIndex) => {
      if (currentSpecialText.instructionIndex === instructionIndex) {

        let regularTextStart = firstTimeIn
          ? 0
          : specialTextWithinInstructions[specialTextIndex - 1].textStop;
        firstTimeIn = false;

        let regularText = instruction.substring(
          regularTextStart,
          currentSpecialText.textStart
        );

        let regularTextSegment: InstructionSegment = {
          text: regularText,
          isIngredient: false,
          classification: "",
        };
        instructionSegments.push(regularTextSegment);

        let specialText = instruction.substring(
          currentSpecialText.textStart,
          currentSpecialText.textStop
        );

        let specialTextSegment: InstructionSegment = {
          text: specialText,
          isIngredient: true,
          classification: currentSpecialText.currentIngredient? currentSpecialText.currentIngredient.Classification: "",
        };

        instructionSegments.push(specialTextSegment);
        lastSpecialTextIndex = specialTextIndex;
      }
    }
  );

  if (lastSpecialTextIndex >= 0) {
    let regularText = instruction.substring(
      specialTextWithinInstructions[lastSpecialTextIndex].textStop
    );
    let regularTextSegment: InstructionSegment = {
      text: regularText,
      isIngredient: false,
      classification: "",
    };
    instructionSegments.push(regularTextSegment);
  } else {
    let regularTextSegment: InstructionSegment = {
      text: instruction,
      isIngredient: false,
      classification: "",
    };
    instructionSegments.push(regularTextSegment);
  }

  return instructionSegments;
};

// export const parseIngredientsFromInstructions = (
//   instructions: Instruction[],
//   ingredients: Ingredient[]
// ): IngredientInInstruction[] => {
//   console.log("parsing ingredients");

//   let fillerWords: string[] = ["of", "the"];
//   let importantWords: string[] = [
//     "remaining",
//     "half",
//     "third",
//     "fourth",
//     "fifth",
//     "all",
//     "quarter",
//     "pinch",
//   ];

//   let foundIngredients: IngredientInInstruction[] = [];

//   instructions.forEach((instruction, instructionIndex) => {
//     instruction.Instruction = instruction.Instruction.toLowerCase();

//     ingredients.forEach((ingredient) => {
//       ingredient.Name = ingredient.Name.toLowerCase();
//       let foundIndexes: number[] = [];

//       let foundIndex = instruction.Instruction.indexOf(ingredient.Name);
//       while (foundIndex >= 0) {
//         const characterAfterWord = instruction.Instruction.charAt(
//           foundIndex + ingredient.Name.length
//         );
//         if (
//           characterAfterWord.toUpperCase() === characterAfterWord.toLowerCase()
//         ) {
//           foundIndexes.push(foundIndex);
//         }

//         foundIndex = instruction.Instruction.indexOf(
//           ingredient.Name,
//           foundIndex + 1
//         );
//       }

//       foundIndexes.forEach((foundIndex, index) => {
//         let currentQuantity = 0;
//         let currentUnit = "";
//         let importantWord = "";
//         let stopIndex = foundIndex - 1;
//         let loopStop = index > 0 ? foundIndexes[index - 1] : 0;
//         for (let i = foundIndex - 2; i >= loopStop; i--) {
//           if (instruction.Instruction[i] === " ") {
//             let word = instruction.Instruction.substring(i + 1, stopIndex);
//             let keepGoing = false;

//             if (fillerWords.indexOf(word) >= 0) {
//               keepGoing = true;
//             } else {
//               let quantity = getQuantityFromText(word);
//               if (quantity > 0 && currentQuantity === 0) {
//                 currentQuantity = quantity;
//                 stopIndex = i;
//               }

//               if (currentUnit === "") {
//                 let unit = getUSUnits().find((unit) => {
//                   return unit.unit === word;
//                 });
//                 if (unit) {
//                   keepGoing = true;
//                   currentUnit = unit.unit;
//                 } else if (
//                   quantity > 0 &&
//                   word.charAt(word.length - 1).toUpperCase() !==
//                     word.charAt(word.length - 1).toLowerCase()
//                 ) {
//                   getUSUnits().forEach((value) => {
//                     if (
//                       word.indexOf(value.unit) > 0 ||
//                       word.indexOf(value.abbreviation) > 0
//                     ) {
//                       currentUnit = value.unit;
//                     }
//                   });
//                 }
//               }

//               if (importantWord === "" && importantWords.indexOf(word) >= 0) {
//                 importantWord = word;
//                 stopIndex = i;
//               }
//             }

//             if (!keepGoing) {
//               let newFoundIngredient: IngredientInInstruction = {
//                 currentIngredient: ingredient,
//                 importantWord: importantWord,
//                 instructionIndex: instructionIndex,
//                 foundIndex: foundIndex,
//                 descriptionIndexStart: stopIndex,
//               };

//               foundIngredients.push(newFoundIngredient);
//               break;
//             } else {
//               stopIndex = i;
//               //console.log(word)
//             }
//           }
//         }
//       });
//     });
//   });

//   return foundIngredients;
// };

// export const formatInstruction = (
//   instruction: Instruction,
//   ingredients: Ingredient[]
// ): InstructionSegment[] => {
//   {
//     let instructionSegments: InstructionSegment[] = [];
//     let foundIngredients = parseIngredientsFromInstructions(
//       [instruction],
//       ingredients
//     );

//     let uppercaseNextLetter = true;
//     let instructionWithCapitalizedSentences = "";
//     for (let i = 0; i < instruction.Instruction.length; i++) {
//       if (
//         uppercaseNextLetter &&
//         instruction.Instruction[i].toLowerCase() !==
//           instruction.Instruction[i].toUpperCase()
//       ) {
//         instructionWithCapitalizedSentences +=
//           instruction.Instruction[i].toUpperCase();
//         uppercaseNextLetter = false;
//       } else {
//         if (
//           instruction.Instruction[i] === "." ||
//           instruction.Instruction[i] === "?" ||
//           instruction.Instruction[i] === "!"
//         ) {
//           uppercaseNextLetter = true;
//         }
//         instructionWithCapitalizedSentences += instruction.Instruction[i];
//       }
//     }

//     instruction.Instruction = instructionWithCapitalizedSentences;

//     if (foundIngredients.length > 0) {
//       foundIngredients.forEach((ingredient, index) => {
//         const initialSegmentStart =
//           index === 0
//             ? 0
//             : foundIngredients[index - 1].foundIndex +
//               foundIngredients[index - 1].currentIngredient.Name.length;
//         let initialSegment: InstructionSegment = {
//           text: instruction.Instruction.substring(
//             initialSegmentStart,
//             ingredient.descriptionIndexStart + 1
//           ),
//           isIngredient: false,
//           classification: "",
//         };
//         instructionSegments.push(initialSegment);

//         let ingredientSegment: InstructionSegment = {
//           text: instruction.Instruction.substring(
//             ingredient.descriptionIndexStart + 1,
//             ingredient.foundIndex + ingredient.currentIngredient.Name.length
//           ),
//           isIngredient: true,
//           classification: ingredient.currentIngredient.Classification,
//         };
//         instructionSegments.push(ingredientSegment);
//       });

//       let lastSegment: InstructionSegment = {
//         text: instruction.Instruction.substring(
//           foundIngredients[foundIngredients.length - 1].foundIndex +
//             foundIngredients[foundIngredients.length - 1].currentIngredient.Name
//               .length
//         ),
//         isIngredient: false,
//         classification: "",
//       };

//       instructionSegments.push(lastSegment);
//     } else {
//       let currentSegment: InstructionSegment = {
//         text: instruction.Instruction,
//         isIngredient: false,
//         classification: "",
//       };
//       instructionSegments.push(currentSegment);
//     }
//     return instructionSegments;
//   }
// };
export const formatIngredientAmount = (
  unit: string,
  quantity: number,
  smallFormat?: boolean
): string => {

  if(smallFormat && unit){
    const foundUnit = usUnits.find((currentUnit) => {
      return currentUnit.unit === unit
    })

    if(foundUnit){
      unit = foundUnit.abbreviation
    }
  }
  // if (units.length > 0 && ingredient.Quantity > 1) {
  //   units += "'s";
  // } else
  if (unit.length == 0 && quantity > 0) {
    unit = "x";
  }

  // let flooredQuantity = Math.floor(quantity);
  // let denominator = 0;

  // if (quantity % 1 !== 0) {
  //   let decimal = quantity - flooredQuantity;
  //   denominator = Math.round(1 / decimal);
  // }

  const mixedNumber = getMixedNumber(quantity)

  // let quantity = "";
  // if (ingredient.Quantity < 1 && ingredient.Quantity > 0) {
  //   quantity = "1/" + 1 / (ingredient.Quantity % 1);
  // } else if (ingredient.Quantity >= 1) {
  //   quantity = ingredient.Quantity.toString();
  // }

  return (
    (mixedNumber.whole > 0 ? mixedNumber.whole + " ": "") + (mixedNumber.numerator !== 0 ? mixedNumber.numerator + "/" +  mixedNumber.denominator + " ": "") + (unit !== "none" ? unit : "x")
  );
};

interface mixedNumber {
  whole: number,
  numerator: number,
  denominator: number
}

export const getMixedNumber = (value: number):mixedNumber => {
  const decimal = Math.floor((value - Math.floor(value)) * 1000)
  const whole = Math.floor(value)


  let greatestCommonFactor = 1
  if(decimal > 0) {
    if(decimal >= 600 && decimal <= 667) {
      return {whole: whole, numerator: 2, denominator: 3}
    }
    if(decimal >= 300 && decimal <= 334) {
      return {whole: whole, numerator: 1, denominator: 3}
    }

    //1000 factors, 2, 2, 2, 5, 5, 5
    let numerator = decimal
    let numberOfTwos = 0
    let numberOfFives = 0
    while(numerator % 2 === 0 && numberOfTwos < 3) {
      numberOfTwos++
      numerator = numerator / 2
    }

    while(numerator % 5 === 0 && numberOfFives < 3) {
      numberOfFives++
      numerator = numerator / 5
    }

    greatestCommonFactor = Math.pow(2, numberOfTwos) * Math.pow(5, numberOfFives)
    // for(let i = 0; i <= decimal; i++) {
    //   if(decimal % i === 0 && 1000 % i === 0){
    //     greatestCommonFactor = i
    //   }
    // }

  }

  return {whole: whole, numerator: decimal / greatestCommonFactor, denominator: 1000 / greatestCommonFactor}
}


//Units VOLUME VS MASS
//1 teaspoon = 5.69 gram


// usUnits.push({ unit: "none", abbreviation: "n/a" });
// usUnits.push({ unit: "teaspoon", abbreviation: "tsp" });
// usUnits.push({ unit: "tablespoon", abbreviation: "tbsp" });
// usUnits.push({ unit: "ounce", abbreviation: "oz" });
// usUnits.push({ unit: "fluid ounce", abbreviation: "fl oz" });
// usUnits.push({ unit: "cup", abbreviation: "c" });
// usUnits.push({ unit: "pint", abbreviation: "pt" });
// usUnits.push({ unit: "quart", abbreviation: "qt" });
// usUnits.push({ unit: "pound", abbreviation: "lb" });
// usUnits.push({ unit: "gallon", abbreviation: "gal" });
// usUnits.push({ unit: "gram", abbreviation: "g" });
// usUnits.push({ unit: "kilogram", abbreviation: "kg" });
// usUnits.push({ unit: "milliliter", abbreviation: "ml" });
// usUnits.push({ unit: "liter", abbreviation: "l" });

export const convertQuantityToUnit = (quantity: number, currentUnit: string, toUnit: string):number => {
  let unitsOfVolume = new Map<string, number>()
  //ml
  unitsOfVolume.set("milliliter", 1)
  unitsOfVolume.set("teaspoon", 4.92892)
  unitsOfVolume.set("tablespoon", 14.7868)
  unitsOfVolume.set("fluid ounce", 29.5735)
  unitsOfVolume.set("cup", 240)
  unitsOfVolume.set("pint", 473.176)
  unitsOfVolume.set("quart", 946.353)
  unitsOfVolume.set("gallon", 3785.41)
  unitsOfVolume.set("liter", 1000)
  
  let unitsOfMass = new Map<string, number>()
  unitsOfMass.set("kilogram", 1000)
  unitsOfMass.set("pound", 453.592)
  unitsOfMass.set("ounce", 28.3495)
  unitsOfMass.set("gram", 1)

  //1 kilogram 1000
  //1 lb 453.592g
  //1 ounce 28.3495 gram

  //units that can't be converted will return -1 
  if(currentUnit === toUnit) {
    return quantity
  }
  
  if(unitsOfVolume.get(currentUnit) && unitsOfVolume.get(toUnit)){
    quantity = quantity * unitsOfVolume.get(currentUnit)! / unitsOfVolume.get(toUnit)!
    return quantity
  }

  if(unitsOfMass.get(currentUnit) && unitsOfMass.get(toUnit)){
    quantity = quantity * unitsOfMass.get(currentUnit)! / unitsOfMass.get(toUnit)!
    return quantity
  }
  //will use mL and g as a conversion point

  return -1
}

let ingredientViewClone = ""
export const setIngredientViewClone = (currentView: string) => {
  ingredientViewClone = currentView
}

export const getIngredientViewClone = () => {
  return ingredientViewClone
}

// export const isValidCountry = (countryOrCode: string):boolean => {
//   const foundCountry = countries.find(country => {
//     if(country.code === countryOrCode || country.name === countryOrCode){
//       return true
//     }
//   })
//   if(foundCountry){ 
//     return true
//   }

//   return false
// } 

// const countries = [ 
//   {"name": "Afghanistan", "code": "AF"}, 
//   {"name": "land Islands", "code": "AX"}, 
//   {"name": "Albania", "code": "AL"}, 
//   {"name": "Algeria", "code": "DZ"}, 
//   {"name": "American Samoa", "code": "AS"}, 
//   {"name": "AndorrA", "code": "AD"}, 
//   {"name": "Angola", "code": "AO"}, 
//   {"name": "Anguilla", "code": "AI"}, 
//   {"name": "Antarctica", "code": "AQ"}, 
//   {"name": "Antigua and Barbuda", "code": "AG"}, 
//   {"name": "Argentina", "code": "AR"}, 
//   {"name": "Armenia", "code": "AM"}, 
//   {"name": "Aruba", "code": "AW"}, 
//   {"name": "Australia", "code": "AU"}, 
//   {"name": "Austria", "code": "AT"}, 
//   {"name": "Azerbaijan", "code": "AZ"}, 
//   {"name": "Bahamas", "code": "BS"}, 
//   {"name": "Bahrain", "code": "BH"}, 
//   {"name": "Bangladesh", "code": "BD"}, 
//   {"name": "Barbados", "code": "BB"}, 
//   {"name": "Belarus", "code": "BY"}, 
//   {"name": "Belgium", "code": "BE"}, 
//   {"name": "Belize", "code": "BZ"}, 
//   {"name": "Benin", "code": "BJ"}, 
//   {"name": "Bermuda", "code": "BM"}, 
//   {"name": "Bhutan", "code": "BT"}, 
//   {"name": "Bolivia", "code": "BO"}, 
//   {"name": "Bosnia and Herzegovina", "code": "BA"}, 
//   {"name": "Botswana", "code": "BW"}, 
//   {"name": "Bouvet Island", "code": "BV"}, 
//   {"name": "Brazil", "code": "BR"}, 
//   {"name": "British Indian Ocean Territory", "code": "IO"}, 
//   {"name": "Brunei Darussalam", "code": "BN"}, 
//   {"name": "Bulgaria", "code": "BG"}, 
//   {"name": "Burkina Faso", "code": "BF"}, 
//   {"name": "Burundi", "code": "BI"}, 
//   {"name": "Cambodia", "code": "KH"}, 
//   {"name": "Cameroon", "code": "CM"}, 
//   {"name": "Canada", "code": "CA"}, 
//   {"name": "Cape Verde", "code": "CV"}, 
//   {"name": "Cayman Islands", "code": "KY"}, 
//   {"name": "Central African Republic", "code": "CF"}, 
//   {"name": "Chad", "code": "TD"}, 
//   {"name": "Chile", "code": "CL"}, 
//   {"name": "China", "code": "CN"}, 
//   {"name": "Christmas Island", "code": "CX"}, 
//   {"name": "Cocos (Keeling) Islands", "code": "CC"}, 
//   {"name": "Colombia", "code": "CO"}, 
//   {"name": "Comoros", "code": "KM"}, 
//   {"name": "Congo", "code": "CG"}, 
//   {"name": "Congo, The Democratic Republic of the", "code": "CD"}, 
//   {"name": "Cook Islands", "code": "CK"}, 
//   {"name": "Costa Rica", "code": "CR"}, 
//   {"name": "Cote D\"Ivoire", "code": "CI"}, 
//   {"name": "Croatia", "code": "HR"}, 
//   {"name": "Cuba", "code": "CU"}, 
//   {"name": "Cyprus", "code": "CY"}, 
//   {"name": "Czech Republic", "code": "CZ"}, 
//   {"name": "Denmark", "code": "DK"}, 
//   {"name": "Djibouti", "code": "DJ"}, 
//   {"name": "Dominica", "code": "DM"}, 
//   {"name": "Dominican Republic", "code": "DO"}, 
//   {"name": "Ecuador", "code": "EC"}, 
//   {"name": "Egypt", "code": "EG"}, 
//   {"name": "El Salvador", "code": "SV"}, 
//   {"name": "Equatorial Guinea", "code": "GQ"}, 
//   {"name": "Eritrea", "code": "ER"}, 
//   {"name": "Estonia", "code": "EE"}, 
//   {"name": "Ethiopia", "code": "ET"}, 
//   {"name": "Falkland Islands (Malvinas)", "code": "FK"}, 
//   {"name": "Faroe Islands", "code": "FO"}, 
//   {"name": "Fiji", "code": "FJ"}, 
//   {"name": "Finland", "code": "FI"}, 
//   {"name": "France", "code": "FR"}, 
//   {"name": "French Guiana", "code": "GF"}, 
//   {"name": "French Polynesia", "code": "PF"}, 
//   {"name": "French Southern Territories", "code": "TF"}, 
//   {"name": "Gabon", "code": "GA"}, 
//   {"name": "Gambia", "code": "GM"}, 
//   {"name": "Georgia", "code": "GE"}, 
//   {"name": "Germany", "code": "DE"}, 
//   {"name": "Ghana", "code": "GH"}, 
//   {"name": "Gibraltar", "code": "GI"}, 
//   {"name": "Greece", "code": "GR"}, 
//   {"name": "Greenland", "code": "GL"}, 
//   {"name": "Grenada", "code": "GD"}, 
//   {"name": "Guadeloupe", "code": "GP"}, 
//   {"name": "Guam", "code": "GU"}, 
//   {"name": "Guatemala", "code": "GT"}, 
//   {"name": "Guernsey", "code": "GG"}, 
//   {"name": "Guinea", "code": "GN"}, 
//   {"name": "Guinea-Bissau", "code": "GW"}, 
//   {"name": "Guyana", "code": "GY"}, 
//   {"name": "Haiti", "code": "HT"}, 
//   {"name": "Heard Island and Mcdonald Islands", "code": "HM"}, 
//   {"name": "Holy See (Vatican City State)", "code": "VA"}, 
//   {"name": "Honduras", "code": "HN"}, 
//   {"name": "Hong Kong", "code": "HK"}, 
//   {"name": "Hungary", "code": "HU"}, 
//   {"name": "Iceland", "code": "IS"}, 
//   {"name": "India", "code": "IN"}, 
//   {"name": "Indonesia", "code": "ID"}, 
//   {"name": "Iran, Islamic Republic Of", "code": "IR"}, 
//   {"name": "Iraq", "code": "IQ"}, 
//   {"name": "Ireland", "code": "IE"}, 
//   {"name": "Isle of Man", "code": "IM"}, 
//   {"name": "Israel", "code": "IL"}, 
//   {"name": "Italy", "code": "IT"}, 
//   {"name": "Jamaica", "code": "JM"}, 
//   {"name": "Japan", "code": "JP"}, 
//   {"name": "Jersey", "code": "JE"}, 
//   {"name": "Jordan", "code": "JO"}, 
//   {"name": "Kazakhstan", "code": "KZ"}, 
//   {"name": "Kenya", "code": "KE"}, 
//   {"name": "Kiribati", "code": "KI"}, 
//   {"name": "Korea, Democratic People\"S Republic of", "code": "KP"}, 
//   {"name": "Korea, Republic of", "code": "KR"}, 
//   {"name": "Kuwait", "code": "KW"}, 
//   {"name": "Kyrgyzstan", "code": "KG"}, 
//   {"name": "Lao People\"S Democratic Republic", "code": "LA"}, 
//   {"name": "Latvia", "code": "LV"}, 
//   {"name": "Lebanon", "code": "LB"}, 
//   {"name": "Lesotho", "code": "LS"}, 
//   {"name": "Liberia", "code": "LR"}, 
//   {"name": "Libyan Arab Jamahiriya", "code": "LY"}, 
//   {"name": "Liechtenstein", "code": "LI"}, 
//   {"name": "Lithuania", "code": "LT"}, 
//   {"name": "Luxembourg", "code": "LU"}, 
//   {"name": "Macao", "code": "MO"}, 
//   {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"}, 
//   {"name": "Madagascar", "code": "MG"}, 
//   {"name": "Malawi", "code": "MW"}, 
//   {"name": "Malaysia", "code": "MY"}, 
//   {"name": "Maldives", "code": "MV"}, 
//   {"name": "Mali", "code": "ML"}, 
//   {"name": "Malta", "code": "MT"}, 
//   {"name": "Marshall Islands", "code": "MH"}, 
//   {"name": "Martinique", "code": "MQ"}, 
//   {"name": "Mauritania", "code": "MR"}, 
//   {"name": "Mauritius", "code": "MU"}, 
//   {"name": "Mayotte", "code": "YT"}, 
//   {"name": "Mexico", "code": "MX"}, 
//   {"name": "Micronesia, Federated States of", "code": "FM"}, 
//   {"name": "Moldova, Republic of", "code": "MD"}, 
//   {"name": "Monaco", "code": "MC"}, 
//   {"name": "Mongolia", "code": "MN"}, 
//   {"name": "Montenegro", "code": "ME"},
//   {"name": "Montserrat", "code": "MS"},
//   {"name": "Morocco", "code": "MA"}, 
//   {"name": "Mozambique", "code": "MZ"}, 
//   {"name": "Myanmar", "code": "MM"}, 
//   {"name": "Namibia", "code": "NA"}, 
//   {"name": "Nauru", "code": "NR"}, 
//   {"name": "Nepal", "code": "NP"}, 
//   {"name": "Netherlands", "code": "NL"}, 
//   {"name": "Netherlands Antilles", "code": "AN"}, 
//   {"name": "New Caledonia", "code": "NC"}, 
//   {"name": "New Zealand", "code": "NZ"}, 
//   {"name": "Nicaragua", "code": "NI"}, 
//   {"name": "Niger", "code": "NE"}, 
//   {"name": "Nigeria", "code": "NG"}, 
//   {"name": "Niue", "code": "NU"}, 
//   {"name": "Norfolk Island", "code": "NF"}, 
//   {"name": "Northern Mariana Islands", "code": "MP"}, 
//   {"name": "Norway", "code": "NO"}, 
//   {"name": "Oman", "code": "OM"}, 
//   {"name": "Pakistan", "code": "PK"}, 
//   {"name": "Palau", "code": "PW"}, 
//   {"name": "Palestinian Territory, Occupied", "code": "PS"}, 
//   {"name": "Panama", "code": "PA"}, 
//   {"name": "Papua New Guinea", "code": "PG"}, 
//   {"name": "Paraguay", "code": "PY"}, 
//   {"name": "Peru", "code": "PE"}, 
//   {"name": "Philippines", "code": "PH"}, 
//   {"name": "Pitcairn", "code": "PN"}, 
//   {"name": "Poland", "code": "PL"}, 
//   {"name": "Portugal", "code": "PT"}, 
//   {"name": "Puerto Rico", "code": "PR"}, 
//   {"name": "Qatar", "code": "QA"}, 
//   {"name": "Reunion", "code": "RE"}, 
//   {"name": "Romania", "code": "RO"}, 
//   {"name": "Russian Federation", "code": "RU"}, 
//   {"name": "RWANDA", "code": "RW"}, 
//   {"name": "Saint Helena", "code": "SH"}, 
//   {"name": "Saint Kitts and Nevis", "code": "KN"}, 
//   {"name": "Saint Lucia", "code": "LC"}, 
//   {"name": "Saint Pierre and Miquelon", "code": "PM"}, 
//   {"name": "Saint Vincent and the Grenadines", "code": "VC"}, 
//   {"name": "Samoa", "code": "WS"}, 
//   {"name": "San Marino", "code": "SM"}, 
//   {"name": "Sao Tome and Principe", "code": "ST"}, 
//   {"name": "Saudi Arabia", "code": "SA"}, 
//   {"name": "Senegal", "code": "SN"}, 
//   {"name": "Serbia", "code": "RS"}, 
//   {"name": "Seychelles", "code": "SC"}, 
//   {"name": "Sierra Leone", "code": "SL"}, 
//   {"name": "Singapore", "code": "SG"}, 
//   {"name": "Slovakia", "code": "SK"}, 
//   {"name": "Slovenia", "code": "SI"}, 
//   {"name": "Solomon Islands", "code": "SB"}, 
//   {"name": "Somalia", "code": "SO"}, 
//   {"name": "South Africa", "code": "ZA"}, 
//   {"name": "South Georgia and the South Sandwich Islands", "code": "GS"}, 
//   {"name": "Spain", "code": "ES"}, 
//   {"name": "Sri Lanka", "code": "LK"}, 
//   {"name": "Sudan", "code": "SD"}, 
//   {"name": "Suriname", "code": "SR"}, 
//   {"name": "Svalbard and Jan Mayen", "code": "SJ"}, 
//   {"name": "Swaziland", "code": "SZ"}, 
//   {"name": "Sweden", "code": "SE"}, 
//   {"name": "Switzerland", "code": "CH"}, 
//   {"name": "Syrian Arab Republic", "code": "SY"}, 
//   {"name": "Taiwan, Province of China", "code": "TW"}, 
//   {"name": "Tajikistan", "code": "TJ"}, 
//   {"name": "Tanzania, United Republic of", "code": "TZ"}, 
//   {"name": "Thailand", "code": "TH"}, 
//   {"name": "Timor-Leste", "code": "TL"}, 
//   {"name": "Togo", "code": "TG"}, 
//   {"name": "Tokelau", "code": "TK"}, 
//   {"name": "Tonga", "code": "TO"}, 
//   {"name": "Trinidad and Tobago", "code": "TT"}, 
//   {"name": "Tunisia", "code": "TN"}, 
//   {"name": "Turkey", "code": "TR"}, 
//   {"name": "Turkmenistan", "code": "TM"}, 
//   {"name": "Turks and Caicos Islands", "code": "TC"}, 
//   {"name": "Tuvalu", "code": "TV"}, 
//   {"name": "Uganda", "code": "UG"}, 
//   {"name": "Ukraine", "code": "UA"}, 
//   {"name": "United Arab Emirates", "code": "AE"}, 
//   {"name": "United Kingdom", "code": "GB"}, 
//   {"name": "United States", "code": "US"}, 
//   {"name": "United States Minor Outlying Islands", "code": "UM"}, 
//   {"name": "Uruguay", "code": "UY"}, 
//   {"name": "Uzbekistan", "code": "UZ"}, 
//   {"name": "Vanuatu", "code": "VU"}, 
//   {"name": "Venezuela", "code": "VE"}, 
//   {"name": "Viet Nam", "code": "VN"}, 
//   {"name": "Virgin Islands, British", "code": "VG"}, 
//   {"name": "Virgin Islands, U.S.", "code": "VI"}, 
//   {"name": "Wallis and Futuna", "code": "WF"}, 
//   {"name": "Western Sahara", "code": "EH"}, 
//   {"name": "Yemen", "code": "YE"}, 
//   {"name": "Zambia", "code": "ZM"}, 
//   {"name": "Zimbabwe", "code": "ZW"} 
//   ]