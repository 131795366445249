import React, { useLayoutEffect } from "react";
import Recipe from "../../interfaces/RecipeInterface";
import { useQuery } from "react-query";
import { getOwnedRecipes } from "../API/API";
import RecipeThumbnail from "../RecipeThumbnail";
import { indexOfRecipeInRecipeArray } from "../API/OfflineAPI";
import CreateOrEditRecipe from "./CreateOrEditRecipe";

interface OwnedRecipesProps {
  recipeID: string;
}

const OwnedRecipes = (props: OwnedRecipesProps) => {
  const { data: ownedRecipes = [] as Recipe[] } = useQuery(
    ["ownedrecipes.me"],
    getOwnedRecipes,
    {
      onSuccess: (result) => {

      },
    }
  );

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);

  let indexOfFocusedRecipe = -1;

  if (props.recipeID) {
    indexOfFocusedRecipe = indexOfRecipeInRecipeArray(
      props.recipeID,
      ownedRecipes
    );
  }

  // useEffect(() => {
  //   getOwnUserRecipes();
  // }, []);

  // let getOwnUserRecipes = () => {

  // };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      {ownedRecipes.length === 0 && <span>Try adding a recipe.</span>}
      {indexOfFocusedRecipe === -1 &&
        ownedRecipes.map((recipe) => {
          return (
            <div key={recipe.ID}>
              <RecipeThumbnail recipe={recipe} panel="owned" />
            </div>
          );
        })}
      {indexOfFocusedRecipe >= 0 && (
        <CreateOrEditRecipe recipe={ownedRecipes[indexOfFocusedRecipe]} />
      )}
    </div>
  );
};

export default OwnedRecipes;
