const PrivacyPolicy = () => {
  return (
    <div className="mx-auto min-h-screen w-[300px] md:w-[500px] lg:w-[700px] bg-gray4 w-full flex flex-col ">
      {/* <div className="mt-[80px] mx-auto text-xl ring-2 ring-blue-200 rounded-lg p-[5px] hover:bg-blue-200 hover:text-gray-800">
        <Link href="/browse">
          <a>Home</a>
        </Link>
      </div> */}
      <div className="flex flex-col mt-[80px]">
        <span className="mx-auto mt-[20px]">
          Thank you for using our cooking website!
        </span>
        <span>
          We take your privacy very seriously, and we want you to know how we
          collect, use, and protect your personal information. This privacy
          policy applies to our website and any related mobile applications
          (calpie.com).
        </span>
      </div>

      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Information We Collect</span>
        <span>
          We collect information that you provide directly to us when you use
          our Services, such as when you register for an account, update your
          profile, or use the platform. This information may include email
          address and any other information you choose to provide. We also
          collect information automatically when you use our Services, such as
          your IP address, browser type, device type, operating system, and
          usage data. We use this information to analyze how our Services are
          being used and to improve their performance and functionality.
        </span>
      </div>

      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">How We Use Your Information</span>
        <span>
          We use the information we collect to provide and improve our Services,
          to communicate with you about your account and orders, and to
          personalize your experience on our website. We may also use your
          information to send you marketing messages about our products and
          services, but you can opt out of receiving these messages at any time.
          We do not sell, rent, or share your personal information with third
          parties for their marketing purposes without your consent. We may,
          however, share your information with third-party service providers who
          help us provide our Services.
        </span>
      </div>

      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Data Security</span>
        <span>
          We take reasonable measures to protect your personal information from
          unauthorized access, disclosure, alteration, or destruction. However,
          no method of transmission over the Internet or method of electronic
          storage is completely secure, so we cannot guarantee absolute
          security.
        </span>
      </div>
      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Cookies and Other Tracking Technologies</span>
        <span>
          We use cookies and other tracking technologies to improve your
          experience on our website and to analyze how our Services are being
          used. You can control the use of cookies at the individual browser
          level, but if you choose to disable cookies, it may limit your use of
          certain features or functions on our website.
        </span>
      </div>
      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Third-Party Links</span>
        <span>
          Our website may contain links to third-party websites or services that
          are not owned or controlled by us. We are not responsible for the
          privacy practices or content of these third-party websites or
          services, so we encourage you to review their privacy policies before
          using them.
        </span>
      </div>
      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Children's Privacy</span>
        <span>
          Our Services are not intended for children under the age of 13, and we
          do not knowingly collect personal information from children under the
          age of 13. If you are a parent or guardian and believe that your child
          has provided us with personal information, please contact us
          immediately.
        </span>
      </div>
      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Updates to this Policy</span>
        <span>
          We may update this privacy policy from time to time. If we make any
          material changes, we will notify you by email or by posting a notice
          on our website.
        </span>
      </div>
      <div className="flex flex-col mt-[20px]">
        <span className="mx-auto">Contact Us</span>
        <span>
          If you have any questions or concerns about our privacy policy, please
          contact us at splish0000 at gmail.
        </span>
      </div>
      {/* <div className="mx-auto mt-[20px] text-xl ring-2 ring-blue-200 rounded-lg p-[5px] hover:bg-blue-200 hover:text-gray-800">
        <Link href="/browse">
          <a>Home</a>
        </Link>
      </div> */}
    </div>
  );
};

export default PrivacyPolicy;
