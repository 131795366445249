import React, { useState, useEffect, useLayoutEffect } from "react";
import Profile from "./Profile";
import Recipe from "../interfaces/RecipeInterface";
import UserInterface from "../interfaces/UserInterface";
import OAuthToken from "../interfaces/OAuthToken";

import { useQuery, useQueryClient } from "react-query";
import {
  getRecipesFromCart,
  getRecipesFromFavorites,
  oAuthLogin,
  clearToken,
  refreshToken,
} from "./API/API";
import { Link, useLocation } from "wouter";
import { setOfflineCart, setOfflineFavorites } from "./API/OfflineAPI";
import { Toaster } from "react-hot-toast";
import AdminView from "./Profile/AdminView";
import { setInterval } from "timers";
import PreventSleep from "./Tools/PreventSleep";

//let searchTimeout: NodeJS.Timeout;

// let cartRecipes: Recipe[] = []
// let favoritesRecipes: Recipe[] = []

const Dashboard = () => {
  const queryClient = useQueryClient();
  //const [searchValue, setSearchValue] = useState("");
  const [user, setUser] = useState<UserInterface | null>(null);
  const [location, setLocation] = useLocation();

  const { data: favoriteRecipes = [] as Recipe[] } = useQuery(
    ["favorites.me"],
    getRecipesFromFavorites
  );
  const { data: cartRecipes = [] as Recipe[] } = useQuery(
    ["cart.me"],
    getRecipesFromCart
  );

  const [isMobile] = useState(navigator.userAgent.indexOf("Mobile") >= 0);
  // const [browserType] = useState(() =>{
  //   if(navigator.userAgent.indexOf("Firefox") >= 0){
  //     return "Firefox"
  //   } else if (navigator.userAgent.indexOf("Chrome") >= 0){
  //     return "Chrome"
  //   } else if (navigator.userAgent.indexOf("Safari") >= 0){
  //     return "Safari"
  //   } else {
  //     return "Unknown"
  //   }
  // })

  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  const [locationDropdown, setLocationDropdown] = useState<string>("Browse");

  const [sleep, setSleep] = useState(true);

  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.hash[0] === "#") {
      const queryParams = new URLSearchParams(
        window.location.hash.substring(1)
      );
      const token: OAuthToken = {
        access_token: queryParams.get("access_token") || "",
        expires_in: parseInt(queryParams.get("expires_in") || "0") || 0,
        state: queryParams.get("state") || "",
      };
      if (token.access_token.length > 0 && token.state.length > 0) {
        //oAuthLogin(token, successfulLogin)
        oAuthLogin(token, successfulLogin);
      }
    }
    // window.location.hash = "";
    // console.log(window.location.hash)
    if (location === "/") {
      setLocation("/browse");
    }
  });

  //const refreshInterval = 780000
  const refreshInterval = 2000;

  let refreshTokenTimer: NodeJS.Timeout | null = null;
  const recursivelyRefreshTokens = () => {
    refreshTokenTimer = setTimeout(async () => {
      const receivedNewToken = await refreshToken();
      if (receivedNewToken) {
        recursivelyRefreshTokens();
      } else {
        if (refreshTokenTimer) {
          clearTimeout(refreshTokenTimer);
        }
        refreshTokenTimer = null;
        setUser(null);
      }
    }, refreshInterval);
  };

  useEffect(() => {
    if (!user) {
      clearToken();
    }

    if (user) {
      recursivelyRefreshTokens();
    }

    setOfflineCart([]);
    setOfflineFavorites([]);
    queryClient.invalidateQueries(["cart.me"]);
    queryClient.invalidateQueries(["favorites.me"]);
    queryClient.invalidateQueries(["ownedrecipes.me"]);
    setLocationDropdown("Browse");
    setLocation("/browse");
  }, [user]);

  const successfulLogin = async (currentUser: UserInterface) => {
    setUser(currentUser);
  };

  // let search = (searchValue: string) => {
  //   setSearchValue(searchValue);

  //   if (searchTimeout) {
  //     clearTimeout(searchTimeout);
  //   }

  //   searchTimeout = setTimeout(() => {
  //     //do stuff
  //   }, 3000);
  // };

  useLayoutEffect(() => {
    let setSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", setSize);

    return () => window.removeEventListener("resize", setSize);
  }, []);

  useEffect(() => {
    if (locationDropdown === "Browse") {
      if (location !== "/browse") {
        setLocation("/browse");
      }
    } else if (locationDropdown === "Recipes") {
      if (location !== "/cart") {
        setLocation("/cart");
      }
    } else if (locationDropdown === "Ingredients") {
      if (location !== "/ingredients") {
        setLocation("/ingredients");
      }
    } else if (locationDropdown === "Favorites") {
      if (location !== "/favorites") {
        setLocation("/favorites");
      }
    } else if (locationDropdown === "Login") {
      if (location !== "/login") {
        setLocation("/login");
      }
    } else if (locationDropdown === "Profile") {
      if (location !== "/profile") {
        setLocation("/profile");
      }
    } else if (locationDropdown === "Admin View") {
      if (location !== "/adminview") {
        setLocation("/adminview");
      }
    } else if (locationDropdown === "Privacy Policy") {
      if (location !== "/privacy") {
        setLocation("/privacy");
      }
    }
    setToggleDropdown(false);
  }, [locationDropdown]);

  const getUniqueIngredientsCount = (recipes: Recipe[]): number => {
    let currentIngredientsCount = 0;
    const ingredientsCountMap: { [ingredientName: string]: number } = {};
    recipes.forEach((recipe) => {
      recipe.Ingredients.forEach((ingredient) => {
        if (ingredientsCountMap[ingredient.Name]) {
          ingredientsCountMap[ingredient.Name] += 1;
        } else {
          ingredientsCountMap[ingredient.Name] = 1;
          currentIngredientsCount++;
        }
      });
    });
    return currentIngredientsCount;
  };
  return (
    <div>
      <div className="w-full mt-[0px]">
        <Toaster position="bottom-center" reverseOrder={false} />
        {/* <div className="bg-yellow-700 h-[100px] w-full select-none fixed z-10"> */}
        <div className="h-[75px] w-full flex flex-col select-none fixed z-10 bg-gray4 items-center justify-between">
          <div className="flex flex-nowrap w-full justify-between mt-[5px] mx-[5px] text-xs sm:text-lg">
            <span className="flex-1 text-center">
              Hello{user ? ", " + user.DisplayName : ", Guest"}{" "}
            </span>
            {isMobile && (
              <div className="flex-1 text-center">
                <PreventSleep setSleep={setSleep} />
              </div>
            )}
            {!isMobile && <span className="flex-1 text-center">Alpha</span>}
            <div className="flex-1 text-center">
              <Link
                href="/privacy"
                onClick={() => {
                  setLocationDropdown("Privacy Policy");
                }}
              >
                <a href="/privacy">Privacy Policy</a>
              </Link>
            </div>
          </div>

          {windowWidth >= 730 && (
            <div className="bg-gray1 sm:flex sm:flex-nowrap sm:justify-around text-lg mt-[5px] text-lg rounded-lg w-[90%] p-[5px] space-around uppercase">
              <div className="flex-1 flex flex-nowrap justify-center">
                {locationDropdown !== "Browse" && (
                  <Link
                    href="/browse"
                    onClick={() => {
                      setLocationDropdown("Browse");
                    }}
                  >
                    <a className="px-[5px]">Browse</a>
                  </Link>
                )}
                {locationDropdown === "Browse" && (
                  <Link
                    href="/browse"
                    onClick={() => {
                      setLocationDropdown("Browse");
                    }}
                  >
                    <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                      Browse
                    </a>
                  </Link>
                )}
              </div>
              <div className="flex-1 flex flex-nowrap justify-center">
                {locationDropdown !== "Recipes" && (
                  <Link
                    href="/cart"
                    onClick={() => {
                      setLocationDropdown("Recipes");
                    }}
                  >
                    <a className="px-[5px]">Recipes {cartRecipes.length}</a>
                  </Link>
                )}
                {locationDropdown === "Recipes" && (
                  <Link
                    href="/cart"
                    onClick={() => {
                      setLocationDropdown("Recipes");
                    }}
                  >
                    <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                      Recipes {cartRecipes.length}
                    </a>
                  </Link>
                )}
              </div>
              <div className="flex-1 flex flex-nowrap justify-center">
                {locationDropdown !== "Ingredients" && (
                  <Link
                    href="/ingredients"
                    onClick={() => {
                      setLocationDropdown("Ingredients");
                    }}
                  >
                    <a className="px-[5px]">
                      Ingredients {getUniqueIngredientsCount(cartRecipes)}
                    </a>
                  </Link>
                )}
                {locationDropdown === "Ingredients" && (
                  <Link
                    href="/ingredients"
                    onClick={() => {
                      setLocationDropdown("Ingredients");
                    }}
                  >
                    <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                      Ingredients {getUniqueIngredientsCount(cartRecipes)}
                    </a>
                  </Link>
                )}
              </div>
              <div className="flex-1 flex flex-nowrap justify-center">
                {locationDropdown !== "Favorites" && (
                  <Link
                    href="/favorites"
                    onClick={() => {
                      setLocationDropdown("Favorites");
                    }}
                  >
                    <a className="px-[5px]">
                      Favorites {favoriteRecipes.length}
                    </a>
                  </Link>
                )}
                {locationDropdown === "Favorites" && (
                  <Link
                    href="/favorites"
                    onClick={() => {
                      setLocationDropdown("Favorites");
                    }}
                  >
                    <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                      Favorites {favoriteRecipes.length}
                    </a>
                  </Link>
                )}
              </div>
              <div className="flex-1 flex flex-nowrap justify-center">
                {locationDropdown !== "Profile" && user && (
                  <Link
                    href="/profile"
                    onClick={() => {
                      setLocationDropdown("Profile");
                    }}
                  >
                    <a className="px-[5px]">Profile</a>
                  </Link>
                )}
                {locationDropdown === "Profile" && (
                  <Link
                    href="/profile"
                    onClick={() => {
                      setLocationDropdown("Profile");
                    }}
                  >
                    <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                      Profile
                    </a>
                  </Link>
                )}
                {locationDropdown !== "Login" && !user && (
                  <Link
                    href="/login"
                    onClick={() => {
                      setLocationDropdown("Login");
                    }}
                  >
                    <a className="px-[5px]">Login</a>
                  </Link>
                )}
                {locationDropdown === "Login" && (
                  <Link
                    href="/login"
                    onClick={() => {
                      setLocationDropdown("Login");
                    }}
                  >
                    <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                      Login
                    </a>
                  </Link>
                )}
              </div>

              {user?.IsAdmin && (
                <div className="flex-1 flex flex-nowrap justify-center">
                  {locationDropdown !== "Admin View" && (
                    <Link
                      href="/adminview"
                      onClick={() => {
                        setLocationDropdown("Admin View");
                      }}
                    >
                      <a className="px-[5px]">Admin</a>
                    </Link>
                  )}
                  {locationDropdown === "Admin View" && user?.IsAdmin && (
                    <Link
                      href="/adminview"
                      onClick={() => {
                        setLocationDropdown("Admin View");
                      }}
                    >
                      <a className="underline text-lightblue1 decoration-lightblue1 underline-offset-2 decoration-2 rounded-sm px-[5px]">
                        Admin
                      </a>
                    </Link>
                  )}
                </div>
              )}

              {/* <Link href="/browse" onClick={() => {setLocationDropdown("Browse")}}>
              <a>Browse</a>
            </Link>

            <Link href="/cart" onClick={() => {setLocationDropdown("Your Recipes")}}>
              <a>Your Recipes {cartRecipes.length}</a>
            </Link>

            <Link href="/ingredients" onClick={() => {setLocationDropdown("Your Ingredients")}}>
              <a>Your Ingredients {getUniqueIngredientsCount(cartRecipes)}</a>
            </Link>

            <Link href="/favorites" onClick={() => {setLocationDropdown("Favorites")}}>
              <a>Favorites {favoriteRecipes.length}</a>
            </Link>

            {user && (
              <Link href="/profile" onClick={() => {setLocationDropdown("Profile")}}>
                <a>Profile</a>
              </Link>
            )}
            {!user && (
              <Link href="/login" onClick={() => {setLocationDropdown("Login")}}>
                <a>Sign In</a>
              </Link>
            )} */}
            </div>
          )}

          {windowWidth < 730 && (
            <div
              className="w-[200px] text-2xl z-50 justify-end relative bg-gray2 mb-[5px]"
              style={{
                borderTopLeftRadius: "0.375rem",
                borderTopRightRadius: "0.375rem",
                borderBottomLeftRadius: toggleDropdown ? "0" : "0.375rem",
                borderBottomRightRadius: toggleDropdown ? "0" : "0.375rem",
              }}
            >
              <label
                className="flex justify-center underline decoration-gray-100 underline-offset-2 decoration-2"
                onClick={() => {
                  setToggleDropdown(!toggleDropdown);
                }}
              >
                {locationDropdown}
              </label>

              {toggleDropdown && (
                <div className="flex flex-col flex-wrap w-full text-2xl items-center absolute bg-gray2 rounded-b-md">
                  {locationDropdown !== "Browse" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Browse");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Browse
                    </label>
                  )}
                  {locationDropdown !== "Recipes" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Recipes");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Recipes {cartRecipes.length}{" "}
                    </label>
                  )}
                  {locationDropdown !== "Ingredients" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Ingredients");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Ingredients {getUniqueIngredientsCount(cartRecipes)}
                    </label>
                  )}
                  {locationDropdown !== "Favorites" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Favorites");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Favorites {favoriteRecipes.length}{" "}
                    </label>
                  )}
                  {user && locationDropdown !== "Profile" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Profile");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Profile
                    </label>
                  )}
                  {!user && locationDropdown !== "Login" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Login");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Login
                    </label>
                  )}
                  {user?.IsAdmin && locationDropdown !== "Admin View" && (
                    <label
                      className="hover:underline"
                      onClick={() => {
                        setLocationDropdown("Admin View");
                        setToggleDropdown(!toggleDropdown);
                      }}
                    >
                      Admin View
                    </label>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {location.indexOf("/profile") === 0 && user && (
          <div className="pt-[75px]">
            <Profile setUser={setUser} user={user as UserInterface} />
          </div>
        )}

        {location.indexOf("/adminview") === 0 && user?.IsAdmin && (
          <div className="pt-[75px]">
            <AdminView />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
