import { useState } from "react";
import toast from "react-hot-toast";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Recipe from "../../interfaces/RecipeInterface";
import { approveRecipe, getPendingRecipes, unapproveRecipe } from "../API/API";

const AdminView = () => {
  const [view, setView] = useState("PendingApproval");
  const [focusedRecipe, setFocusedRecipe] = useState<null | Recipe>(null);
  const [from, setFrom] = useState(0);

  const { data: pendingApprovalRecipes = [] as Recipe[] } = useQuery(
    ["pendingrecipes", from],
    () => {
      return getPendingRecipes(from);
    }
  );

  const queryClient = useQueryClient();

  // useEffect(() => {
  //   setFocusedRecipe(null);
  // }, [view]);

  const approveRecipeMutation = useMutation(
    (recipeID: string) => approveRecipe(recipeID),
    {
      onSuccess: () => {
        toast("Approved Recipe", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === "pendingrecipes",
        });
        setFocusedRecipe(null);
      },
    }
  );

  const denyRecipeMutation = useMutation(
    (recipeID: string) => unapproveRecipe(recipeID),
    {
      onSuccess: () => {
        toast("Denied Recipe", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey[0] === "pendingrecipes",
        });
        setFocusedRecipe(null);
      },
    }
  );

  return (
    <div className="text-white w-full">
      <div className="flex flex-nowrap justify-around">
        <span
          className="cursor-pointer"
          onClick={() => {
            setView("PendingApproval");
            setFocusedRecipe(null);
          }}
        >
          Pending Approval
        </span>
        {/* <span
          className="cursor-pointer"
          onClick={() => {
            setView("UnapproveRecipe");
            setFocusedRecipe(null);
          }}
        >
          Unapprove Recipe
        </span> */}
      </div>
      {view === "PendingApproval" && (
        <div className="mt-[10px]">
          {!focusedRecipe &&
            pendingApprovalRecipes.map((recipe) => {
              return (
                <div
                  className="w-full flex flex-wrap justify-center"
                  key={recipe.ID}
                >
                  <span
                    className="cursor-pointer"
                    onClick={() => {
                      setFocusedRecipe(recipe);
                    }}
                  >
                    {recipe.ID} {recipe.Title}
                  </span>

                  {/* <span
                  onClick={() => {
                    approveRecipeMutation.mutate(recipe.ID);
                  }}
                >
                  {recipe.ID}
                </span> */}
                </div>
              );
            })}

          {focusedRecipe && (
            <div className="w-full mx-auto p-[5px] flex flex-wrap justify-center mt-[10px]">
              <div className="w-full">
                {focusedRecipe.Source.indexOf(
                  "https://www.youtube.com/embed/"
                ) === 0 && (
                  <div className="w-[300px] h-[200px] mx-auto flex justify-center flex-nowrap">
                    <iframe
                      title="uniquetitle"
                      referrerPolicy="no-referrer-when-downgrade"
                      className="flex-1 rounded-lg"
                      // height="315"
                      src={focusedRecipe.Source}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>

              <div className="m-[5px]">
                <div className="w-full flex flex-col justify-center w-full w-[300px] bg-gray-700 p-[5px]">
                  <span className="mx-auto">ID: {focusedRecipe.ID}</span>
                  <span className="mx-auto">Title: {focusedRecipe.Title}</span>
                  {focusedRecipe.Source && (
                    <span>Source: {focusedRecipe.Source}</span>
                  )}
                  {focusedRecipe.Cuisine && (
                    <span>Cuisine: {focusedRecipe.Cuisine}</span>
                  )}

                  <div className="flex flex-nowrap justify-between">
                    <span>Time: {focusedRecipe.TimeMinutes}</span>
                    <span>Servings: {focusedRecipe.Servings}</span>
                  </div>

                  {/* <span>{JSON.stringify(focusedRecipe)}</span> */}
                </div>
                <div>
                  <div className="w-[300px] bg-gray-700 flex flex-col p-[5px]">
                    <span className="mx-auto">Ingredients</span>
                    {focusedRecipe.Ingredients.map((ingredient, index) => {
                      return (
                        <div key={index} className="text-sm">
                          <span>
                            {ingredient.Quantity}{" "}
                            {ingredient.Unit !== "none" && ingredient.Unit}{" "}
                            {ingredient.Name}
                            {ingredient.Comment && ingredient.Comment}{" "}
                            {ingredient.Classification}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              {focusedRecipe.Cookware.length > 0 && (
                <div className="m-[5px]">
                  <div className="w-[300px] flex flex-col bg-gray-700">
                    <span className="mx-auto">Cookware</span>
                    {focusedRecipe.Cookware.map((cookware, index) => {
                      return (
                        <div key={index} className="flex flex-col">
                          <span className="text-sm">{cookware}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="m-[5px]">
                <div className="w-[300px] flex flex-col bg-gray-700">
                  <span className="mx-auto">Instructions</span>
                  {focusedRecipe.Instructions.map((instruction, index) => {
                    return (
                      <div key={index} className="flex flex-col m-[5px]">
                        <span className="capitalize">
                          {index + 1}: {instruction.Header}
                        </span>
                        <span className="text-sm">
                          {instruction.Instruction}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-full">
                <div className="mx-auto flex flex-nowrap text-center w-full sm:w-[640px]">
                  <div className="flex-1">
                    <span className="cursor-pointer">Delete (Coming Soon)</span>
                  </div>
                  <div className="flex-1">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        denyRecipeMutation.mutate(focusedRecipe.ID);
                      }}
                    >
                      Deny
                    </span>
                  </div>
                  <div className="flex-1">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        approveRecipeMutation.mutate(focusedRecipe.ID);
                      }}
                    >
                      Approve
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {view === "UnapproveRecipe" && (
        <div className="flex flex-nowrap justify-center">
          <span>Coming Soon</span>
        </div>
      )}
    </div>
  );
};

export default AdminView;
