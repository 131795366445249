import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import Recipe from "../../interfaces/RecipeInterface";
import Ingredient from "../../interfaces/Ingredient";
import { TiDeleteOutline } from "react-icons/ti";
import { FiClock } from "react-icons/fi";
import { MdPerson } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import Instruction from "../../interfaces/Instruction";
import { useMutation, useQueryClient } from "react-query";
import { newRecipe, updateRecipe } from "../API/API";
import {
  convertQuantityToUnit,
  formatIngredientAmount,
  formatInstructionFromSpecialText,
  formatTime,
  getHexColor,
  getIngredientClassificationRanking,
  getQuantityFromText,
  getUSUnits,
  parseJsonRecipe,
} from "../API/OfflineAPI";
import { parseSpecialTextInsideInstruction } from "../API/RecipeParser";
import { AiFillCaretDown } from "react-icons/ai";
import { CgScreen } from "react-icons/cg";
import { VscJson } from "react-icons/vsc";
import { TbJson } from "react-icons/tb";
import SpecialTextWithinInstruction from "../../interfaces/SpecialTextWithinInstruction";
import {
  IoIosCheckmarkCircle,
  IoIosCheckmarkCircleOutline,
  IoMdCheckmark,
} from "react-icons/io";

import toast, { CheckmarkIcon, Toaster, ToastOptions } from "react-hot-toast";
import { useLocation } from "wouter";

// const isTypingIngredient = (cursorPosition: number, text: string): boolean => {
//   let currentIndex = cursorPosition - 1;
//   if (currentIndex > 1 && currentIndex <= text.length) {
//     while (currentIndex >= 1) {
//       if (text[currentIndex] === "{") {
//         return true;
//       } else if (text[currentIndex] === "}") {
//         return false;
//       }
//       currentIndex--;
//     }
//   }
//   return false;
// };

// const isInstructionLetterValid = (text: string): boolean => {
//   if (text.length > 0) {
//     const newValue = text[text.length - 1];

//     if (newValue === "{") {
//       let currentIndex = text.length - 2;
//       while (currentIndex >= 0) {
//         if (text[currentIndex] === "{") {
//           return false;
//         } else if (text[currentIndex] === "}") {
//           return true;
//         }
//         currentIndex--;
//       }
//     }

//     if (newValue === "}") {
//       let currentIndex = text.length - 2;
//       while (currentIndex >= 0) {
//         if (text[currentIndex] === "}") {
//           return false;
//         } else if (text[currentIndex] === "{") {
//           return true;
//         }
//         currentIndex--;
//       }
//     }
//   }

//   return true;
// };

let instructionsTimeout: NodeJS.Timeout;
let instructionsTimeoutHandler = (onFinish: Function) => {
  if (instructionsTimeout) {
    clearTimeout(instructionsTimeout);
  }

  instructionsTimeout = setTimeout(() => {
    if (onFinish) {
      onFinish();
    }
  }, 1000);
};

const ingredientAmountsInInstructions = (
  specialText: SpecialTextWithinInstruction[], ingredients: Ingredient[]
): Map<string, number> => {
  let ingredientAmounts: Map<string, number> = new Map();

  specialText.forEach((currentSpecialText) => {
    console.log(currentSpecialText)
    if (currentSpecialText.searchText === "ingredients") {
      if (currentSpecialText.importantWord === "all") {
        ingredients.forEach(ingredient => {

          let ingredientExists = ingredientAmounts.get(
            ingredient.Name
          );

          if (ingredientExists) {
            ingredientAmounts.set(
              ingredient.Name,
              ingredientExists + ingredient.Quantity
            );
          } else {
            console.log("Making Magic")
            ingredientAmounts.set(
              ingredient.Name,
              ingredient.Quantity
            );
          }
        })

      } else if (currentSpecialText.importantWord === "remaining") {
        ingredients.forEach(ingredient => {
          console.log("here?")
          let ingredientExists = ingredientAmounts.get(
            ingredient.Name
          );

          if (!ingredientExists) {
            ingredientAmounts.set(
              ingredient.Name,
              ingredient.Quantity
            );
          }
        })
      }
    }
    
    if (
      currentSpecialText.currentIngredient &&
      currentSpecialText.searchText
    ) {
      let ingredientExists = ingredientAmounts.get(
        currentSpecialText.searchText
      );

      let convertedSpecialTextQuantity = convertQuantityToUnit(
        currentSpecialText.quantity,
        currentSpecialText.unit,
        currentSpecialText.currentIngredient.Unit
      );

      if (ingredientExists) {
        ingredientAmounts.set(
          currentSpecialText.searchText,
          ingredientExists + convertedSpecialTextQuantity
        );
      } else {
        ingredientAmounts.set(
          currentSpecialText.searchText,
          convertedSpecialTextQuantity
        );
      }
    }
  });

  return ingredientAmounts;
};

const cookwareInInstructions = (
  specialText: SpecialTextWithinInstruction[]
): Map<string, number> => {
  let cookware: Map<string, number> = new Map();
  specialText.forEach((currentSpecialText) => {
    if (!currentSpecialText.currentIngredient) {
      let currentCookware = cookware.get(currentSpecialText.searchText);
      let currentQuantity = currentCookware
        ? currentCookware
        : currentSpecialText.quantity;
      if (currentCookware) {
        currentCookware += currentQuantity;
      } else {
        cookware.set(currentSpecialText.searchText, currentQuantity);
      }
    }
  });

  return cookware;
};

interface CreateOrEditRecipeProps {
  recipe: Recipe | null;
}

const CreateOrEditRecipe = (props: CreateOrEditRecipeProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let queryClient = useQueryClient();

  // const [recipe, setRecipe] = useState<Recipe>({
  //   Title: "",
  //   Country: "",
  //   Servings: 0,
  //   TimeMinutes: 0,
  //   Source: "",
  //   IsSearchable: false,
  //   IsUserIDVisible: false,
  //   IsHistoryVisible: false,
  //   Cookware: [] as string[],
  //   Ingredients: [] as Ingredient[],
  //   Instructions: [] as Instruction[],
  //   ID: "",
  //   UserID: 0,
  //   CustomServings: 0,
  // } as Recipe);

  const [location, setLocation] = useLocation();

  const [recipe, setRecipe] = useState<Recipe>(() => {
    console.log(props.recipe?.IsSearchable);
    console.log(props.recipe?.IsUserIDVisible);
    return {
      Title: props.recipe?.Title ? props.recipe.Title : "",
      Cuisine: props.recipe?.Cuisine ? props.recipe.Cuisine : "",
      Servings: props.recipe?.Servings ? props.recipe.Servings : 0,
      TimeMinutes: props.recipe?.TimeMinutes ? props.recipe.TimeMinutes : 0,
      Source: props.recipe?.Source ? props.recipe.Source : "",
      IsSearchable: props.recipe?.IsSearchable
        ? props.recipe.IsSearchable
        : false,
      IsUserIDVisible: props.recipe?.IsUserIDVisible
        ? props.recipe.IsUserIDVisible
        : false,
      IsHistoryVisible: props.recipe?.IsHistoryVisible
        ? props.recipe.IsHistoryVisible
        : false,
      Cookware: props.recipe?.Cookware
        ? props.recipe.Cookware
        : ([] as string[]),
      Ingredients: props.recipe?.Ingredients
        ? props.recipe.Ingredients
        : ([] as Ingredient[]),
      Instructions: props.recipe?.Instructions
        ? props.recipe.Instructions
        : ([] as Instruction[]),
      ID: props.recipe?.ID ? props.recipe.ID : "",
      UserID: props.recipe?.UserID ? props.recipe.UserID : "",
      CustomServings: props.recipe?.CustomServings
        ? props.recipe.CustomServings
        : 0,
    } as Recipe;
  });

  const addRecipeToUser = useMutation((recipe: Recipe) => newRecipe(recipe), {
    onSuccess: () => {
      queryClient.invalidateQueries(["ownedrecipes.me"]);
      modifiedRecipeToast("Successfully created recipe!");
      resetRecipe();
    },
  });

  const updateUserRecipe = useMutation(
    (recipe: Recipe) => updateRecipe(recipe),
    {
      onSuccess: () => {
        //modifiedRecipeToast("Successfully updated recipe!")
        queryClient.invalidateQueries(["ownedrecipes.me"]);
        setLocation("/profile/ownedrecipes");
        // let queryCurrentRecipes: Recipe[] | undefined = queryClient.getQueryData(["ownedrecipes.me"])
        // if(queryCurrentRecipes){
        //   const recipeIndex = queryCurrentRecipes.findIndex((queryCurrentRecipe => {
        //     return queryCurrentRecipe.ID === recipe.ID
        //   }))
        //   if(recipeIndex >= 0){
        //     const recipeIDFragments = recipe.ID.split(":")
        //     if(recipeIDFragments.length === 2){
        //       recipe.ID = recipeIDFragments[0] + ":" + (parseInt(recipeIDFragments[1]) + 1)
        //       console.log("setting query data " + recipeIDFragments[0] + ":" + (parseInt(recipeIDFragments[1]) + 1))
        //       queryCurrentRecipes[recipeIndex] = recipe
        //       queryClient.setQueriesData(["ownedrecipes.me"], queryCurrentRecipes)
        //       setLocation("/profile/ownedrecipes")
        //     }
        //   }
        // }

        // queryClient.prefetchQuery({
        //   queryKey: ["ownedrecipes.me"],
        //   queryFn: getOwnedRecipes,
        // })
        // console.log(test)

        // const oldData = queryClient.getQueryData(["ownedrecipes.me"]) as Recipe[]
        // const currentIndex = oldData.indexOf(recipe)
        // if(currentIndex >= 0) {
        //   const recipeIDFragment = recipe.ID.split(":")
        //   if(recipeIDFragment.length === 2) {
        //     const newData = [...oldData]
        //     newData[currentIndex].ID = recipeIDFragment[0] + ":" + (parseInt(recipeIDFragment[1]) + 1)
        //     queryClient.setQueryData(["ownedrecipes.me"], newData )
        //     setLocation(location.substring(0, location.lastIndexOf("/")))
        //   }

        // }
      },
    }
  );

  // let formatIngredientAmount = (
  //   ingredient: Ingredient,
  //   defaultServings: number,
  //   customServings: number
  // ): string => {
  //   let units = ingredient.Unit;
  //   if (units.length > 0 && ingredient.Quantity > 1) {
  //     units += "s";
  //   } else if (units.length == 0 && ingredient.Quantity > 0) {
  //     units = "x";
  //   }

  //   let servingRatio = 1;
  //   let convertedIngredientQuantity =
  //     Math.round(ingredient.Quantity * servingRatio * 100) / 100;

  //   // let quantity = "";
  //   // if (ingredient.Quantity < 1 && ingredient.Quantity > 0) {
  //   //   quantity = "1/" + 1 / (convertedIngredientQuantity % 1);
  //   // } else if (ingredient.Quantity >= 1) {
  //   //   quantity = convertedIngredientQuantity.toString();
  //   // }

  //   if (convertedIngredientQuantity === 0) {
  //     return "pinch";
  //   }

  //   if (units === "x") {
  //     return convertedIngredientQuantity + units;
  //   }

  //   return convertedIngredientQuantity + " " + units;
  // };

  let invalidParameterToast = (displayText: string) => {
    const toastOptions: ToastOptions = {
      duration: 5000,
    };

    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full normal-case bg-gray-700 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 text-gray-100 items-center justify-between`}
        >
          <span className="p-3 ml-5px">{displayText}</span>
          <div className="p-3 mr-5px">
            <TiDeleteOutline
              className="cursor-pointer text-2xl"
              onClick={() => {
                toast.remove(t.id);
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.color = "red";
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.color = "white";
              }}
            />
          </div>
        </div>
      ),
      toastOptions
    );
  };

  const modifiedRecipeToast = (message: string) => {
    const toastOptions: ToastOptions = {
      duration: 1000,
    };

    toast.custom(
      (t) => (
        <div
          className={`${
            t.visible ? "animate-enter" : "animate-leave"
          } max-w-md w-full normal-case bg-gray-700 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5 text-gray-100 items-center justify-between`}
        >
          <span className="p-3 ml-5px">{message}</span>
          <div className="p-3 mr-5px">
            <CheckmarkIcon className="text-green-600 text-2xl" />
          </div>
        </div>
      ),
      toastOptions
    );
  };

  const isRecipeValid = (): boolean => {
    let validRecipe = true;
    if (recipe.Title.length < 3) {
      validRecipe = false;
      invalidParameterToast("Recipe title too short.");
    }
    if (recipe.Title.length > 45) {
      validRecipe = false;
      invalidParameterToast("Recipe title too short.");
    }
    // if (recipe.Country.length < 1) {
    //   //validRecipe = false;
    //   invalidParameterToast("Country too short.");
    // }
    if (recipe.Ingredients.length <= 0) {
      validRecipe = false;
      invalidParameterToast("Missing ingredients.");
    }
    if (recipe.Instructions.length <= 0) {
      validRecipe = false;
      invalidParameterToast("Missing instructions.");
    }
    if (recipe.Servings === 0) {
      validRecipe = false;
      invalidParameterToast("Servings can't be 0.");
    }
    if (recipe.TimeMinutes === 0) {
      validRecipe = false;
      invalidParameterToast("Time can't be 0.");
    }

    let duplicateIngredients = new Set<string>();
    recipe.Ingredients.forEach((ingredient) => {
      if (doesIngredientAlreadyExist(ingredient)) {
        validRecipe = false;
        duplicateIngredients.add(ingredient.Name);
      }
    });

    duplicateIngredients.forEach((duplicateIngredient) => {
      invalidParameterToast(
        "Duplicate ingredient found " + duplicateIngredient + "."
      );
    });

    return validRecipe;
  };

  const areIngredientsValid = (): boolean => {
    let ingredientsValid = true;
    ingredientChecker.forEach((ingredientCheck, index) => {
      if (!ingredientCheck) {
        ingredientsValid = false;
        invalidParameterToast(
          "Could not find " +
            recipe.Ingredients[index].Name +
            " within instructions."
        );
      }
    });

    return ingredientsValid;
  };

  const resetRecipe = () => {
    let currentRecipe = { ...recipe };
    currentRecipe.Title = "";
    currentRecipe.Cuisine = "";
    currentRecipe.Servings = 0;
    currentRecipe.TimeMinutes = 0;
    currentRecipe.Ingredients = [];
    currentRecipe.Instructions = [];
    currentRecipe.Cookware = [];
    setRecipe(currentRecipe);
    setUndetectedIngredients(false);
  };

  let createOrUpdateRecipe = (bypassIngredients?: boolean): boolean => {
    let recipeDeepCopy = JSON.parse(JSON.stringify(recipe));

    if (isRecipeValid()) {
      if (bypassIngredients) {
        if (!props.recipe) {
          addRecipeToUser.mutate(recipeDeepCopy);
        } else {
          updateUserRecipe.mutate(recipeDeepCopy);
        }
      } else {
        const validIngredients = areIngredientsValid();
        if (validIngredients) {
          if (!props.recipe) {
            addRecipeToUser.mutate(recipeDeepCopy);
          } else {
            updateUserRecipe.mutate(recipeDeepCopy);
          }
        } else {
          setUndetectedIngredients(true);
        }
      }

      //validate ingredients (prompt are you sure)

      // console.log(recipe);
      // let request = {
      //   User: props.user,
      //   Recipe: recipe,
      // };

      // Axios.post("http://127.0.0.1:8080/user/createrecipe", recipe, {
      //   headers: { Authorization: "basic " + props.user.Token },
      // })
      //   .then((res) => {
      //     console.log(res);
      //     console.log("are we herE?");
      //     if (res.status === 200) {
      //       setStatus("Added " + recipe.Title);
      //     } else {
      //       setStatus("Something went wrong2.");
      //     }
      //   })
      //   .catch((error) => {
      //     setStatus(JSON.stringify(recipe));
      //   });

      return true;
    }

    //setStatus("Invalid Recipe.");
    return false;
  };

  const [ingredientsSelect, setIngredientsSelect] = useState<boolean[]>(() => {
    let ingredientsSelect: boolean[] = [];
    recipe.Ingredients.forEach(() => {
      ingredientsSelect.push(false);
    });
    return ingredientsSelect;
  });

  const [cookwareSelect, setCookwareSelect] = useState<boolean[]>(() => {
    let cookwareSelect: boolean[] = [];
    recipe.Cookware.forEach(() => {
      cookwareSelect.push(false);
    });
    return cookwareSelect;
  });

  const [instructionsSelect, setInstructionsSelect] = useState<boolean[]>(
    () => {
      let instructionsSelect: boolean[] = [];
      recipe.Instructions.forEach(() => {
        instructionsSelect.push(false);
      });
      return instructionsSelect;
    }
  );

  const [undetectedIngredients, setUndetectedIngredients] =
    useState<boolean>(false);

  const [ingredientChecker, setIngredientChecker] = useState<boolean[]>(() => {
    let ingredientChecker: boolean[] = [];
    recipe.Instructions.forEach(() => {
      ingredientChecker.push(false);
    });
    return ingredientChecker;
  });

  const [cookwareChecker, setCookwareChecker] = useState<boolean[]>(() => {
    let cookwareChecker: boolean[] = [];
    recipe.Cookware.forEach(() => {
      cookwareChecker.push(false);
    });
    return cookwareChecker;
  });

  const [viewSelect, setViewSelect] = useState("screen"); // or json

  const [cursorIndex, setCursorIndex] = useState(0);
  const [clockSelect, setClockSelect] = useState(false);
  const [servingSelect, setServingSelect] = useState(false);
  const [titleSelect, setTitleSelect] = useState(false);

  const [classificationDropdown, setClassificationDropdown] = useState(false);
  const [unitDropdown, setUnitDropdown] = useState(false);

  const [specialTextWithinInstruction, setSpecialTextWithinInstruction] =
    useState<SpecialTextWithinInstruction[]>([]);

  const instructionsTextInputEle = useRef<HTMLTextAreaElement[]>([]);
  const typingInstructorEle = useRef<HTMLDivElement>(null);

  let deselectAll = () => {
    let currentIngredientsSelect: boolean[] = [];
    recipe.Ingredients.forEach(() => {
      currentIngredientsSelect.push(false);
    });

    let currentCookwareSelect: boolean[] = [];
    recipe.Cookware.forEach(() => {
      currentCookwareSelect.push(false);
    });

    let currentInstructionsSelect: boolean[] = [];
    recipe.Instructions.forEach(() => {
      currentInstructionsSelect.push(false);
    });

    setClassificationDropdown(false);
    setClockSelect(false);
    setServingSelect(false);
    setTitleSelect(false);

    setInstructionsSelect(currentInstructionsSelect);
    setIngredientsSelect(currentIngredientsSelect);
    setCookwareSelect(currentCookwareSelect);
  };

  useLayoutEffect(() => {
    instructionsTextInputEle.current.forEach((textarea) => {
      textarea.style.height = "inherit";
      textarea.style.height = textarea.scrollHeight + "px";
    });
  }, [recipe.Instructions]);

  useLayoutEffect(() => {
    if (typingInstructorEle.current) {
      typingInstructorEle.current.style.margin =
        typingInstructorEle.current.clientHeight * -1 + "px 0px 0px 0px";
    }
  }, [typingInstructorEle]);

  useEffect(() => {
    let specialText: SpecialTextWithinInstruction[] = [];

    let clonedIngredients: Ingredient[] = structuredClone(recipe.Ingredients);
    clonedIngredients.forEach((ingredient) => {
      const indexOfComment = ingredient.Name.indexOf(",");
      if (indexOfComment > 0) {
        ingredient.Name = ingredient.Name.substring(0, indexOfComment);
      }
    });

    let clonedCookware: string[] = structuredClone(recipe.Cookware);
    let clonedInstructions: Instruction[] = structuredClone(
      recipe.Instructions
    );

    clonedInstructions.forEach((instruction, index) => {
      specialText = [
        ...specialText,
        ...parseSpecialTextInsideInstruction(
          instruction,
          index,
          clonedIngredients,
          clonedCookware,
          specialText
        ),
      ];
    });

    let currentIngredientsChecker = new Array(recipe.Ingredients.length).fill(
      false
    );

    let currentCookwareChecker = new Array(recipe.Cookware.length).fill(false);
    console.log({ specialText });
    if (specialText.length > 0) {
      const ingredientAmounts = ingredientAmountsInInstructions(specialText, clonedIngredients);
      clonedIngredients.forEach((ingredient, ingredientIndex) => {
        if (Number.isNaN(ingredient.Quantity)) {
          clonedIngredients[ingredientIndex].Quantity = 0;
        }

        currentIngredientsChecker[ingredientIndex] =
          ingredientAmounts.get(
            structuredClone(ingredient.Name).toLowerCase()
          ) === ingredient.Quantity;
      });

      const cookwareAmounts = cookwareInInstructions(specialText);
      recipe.Cookware.forEach((cookware, cookwareIndex) => {
        currentCookwareChecker[cookwareIndex] =
          cookwareAmounts.get(structuredClone(cookware).toLowerCase()) !==
          undefined;
      });

      //calculate ingredient amounts and check for cookware
    }
    setSpecialTextWithinInstruction(specialText);
    setIngredientChecker(currentIngredientsChecker);
    setCookwareChecker(currentCookwareChecker);
    setUndetectedIngredients(false);
  }, [recipe.Ingredients, recipe.Instructions, recipe.Cookware]);

  const doesIngredientAlreadyExist = (ingredient: Ingredient): boolean => {
    const foundIngredient = recipe.Ingredients.find((currentIngredient) => {
      if (ingredient !== currentIngredient) {
        if (ingredient.Name === currentIngredient.Name) {
          return true;
        }
      }
    });

    return foundIngredient ? true : false;
  };
  // useEffect(() => {
  //   instructionsTimeoutHandler(() => {
  //     setFoundIngredients(
  //       parseIngredientsFromInstructions(
  //         [...recipe.Instructions],
  //         [...recipe.Ingredients]
  //       )
  //     );
  //   });
  // }, [recipe.Instructions, recipe.Ingredients]);

  return (
    <div
      className="w-full display-flex justify-around xl:w-[1280px] m-auto bg-gray1 select-none capitalize"
      onClick={() => {
        let isTextAreaFocused = false;
        instructionsTextInputEle.current.forEach((element) => {
          if (document.activeElement === element) {
            isTextAreaFocused = true;
          }
        });
        if (!isTextAreaFocused) {
          deselectAll();
        }
      }}
    >
      <div className="flex flex-nowrap justify-center items-center text-3xl py-[20px]">
        <div
          className="mx-[10px] cursor-pointer p-[5px] border-4 border-transparent"
          style={{
            borderColor: viewSelect === "screen" ? "#f3f4f6" : "#252526",
          }}
          onClick={() => {
            setViewSelect("screen");
          }}
        >
          <CgScreen />
        </div>
        <div
          className="mx-[10px] cursor-pointer p-[5px] border-4 border-transparent"
          style={{
            borderColor: viewSelect === "json" ? "#f3f4f6" : "#252526",
          }}
          onClick={() => {
            setViewSelect("json");
          }}
        >
          <TbJson />
        </div>
      </div>

      {viewSelect === "json" && (
        <div className="flex flex-wrap w-full justify-center text-black">
          <textarea
            className="p-[5px] w-full h-[600px] resize-none outline-none"
            placeholder={JSON.stringify(recipe, null, 2).toLowerCase()}
            onChange={(e) => {}}
            onDoubleClick={(e) => {
              let currentRecipe = parseJsonRecipe(e.currentTarget.value);
              if (currentRecipe) {
                setRecipe(currentRecipe);
                setViewSelect("screen");
              }
            }}
          />
        </div>
      )}

      {viewSelect === "screen" && (
        <div>
          <div className="w-full flex flex-wrap justify-around text-3xl sm:text-5xl text-center">
            <div
              className="p-[5px] sm:text-3xl text-lg"
              onClick={(e) => {
                e.stopPropagation();
                deselectAll();
                setTitleSelect(true);
              }}
            >
              {titleSelect && (
                <input
                  className="rounded-lg outline-none text-center text-black"
                  size={recipe.Title.length > 10 ? recipe.Title.length : 10}
                  value={recipe.Title}
                  placeholder="Title"
                  onChange={(e) => {
                    e.currentTarget.size =
                      e.target.value.length > 10 ? e.target.value.length : 10;
                    let currentRecipe = { ...recipe };
                    currentRecipe.Title = e.target.value;
                    setRecipe(currentRecipe);
                  }}
                />
              )}
              {!titleSelect && (
                <span className="cursor-pointer">
                  {recipe.Title ? recipe.Title : "Title: Click Me"}
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-nowrap justify-center mt-[10px] mx-[5px]">
            <input
              className="text-center rounded-md outline-none text-black"
              placeholder="Source"
              value={recipe.Source}
              onChange={(e) => {
                let currentRecipe = { ...recipe };
                currentRecipe.Source = e.target.value;
                const regularYTUrl = "https://www.youtube.com/watch?v=";
                if (currentRecipe.Source.indexOf(regularYTUrl) === 0) {
                  //convert to embed
                  const ytVideoID = currentRecipe.Source.substring(
                    regularYTUrl.length
                  );
                  const embedYTUrl = "https://www.youtube.com/embed/";
                  modifiedRecipeToast(
                    "Converted Youtube URL to Youtube Embed URL"
                  );
                  currentRecipe.Source = embedYTUrl + ytVideoID;
                }
                setRecipe(currentRecipe);
              }}
            />
          </div>

          {recipe.Source.indexOf("https://www.youtube.com/embed/") === 0 && (
            <div className="w-[300px] mt-[20px] p-[10px] border-8 rounded-3xl bg-black m-auto sm:w-[500px] sm:h-[375px]">
              <iframe
                className="w-full h-full"
                // height="315"
                src={recipe.Source}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}

          {recipe.Source.indexOf(".pdf", recipe.Source.length - 4) >= 0 && (
            <div className="flex flex-nowrap justify-around">
              <a href={recipe.Source} className="text-xs" target="_blank">
                {recipe.Source}
              </a>
            </div>
          )}

          <div className="flex flex-nowrap justify-center items-center mt-[10px]">
            <input
              className="text-center rounded-md outline-none text-black"
              placeholder="Cuisine (ex: American)"
              value={recipe.Cuisine}
              onChange={(e) => {
                let currentRecipe = { ...recipe };
                currentRecipe.Cuisine = e.target.value;
                setRecipe(currentRecipe);
              }}
            />
          </div>

          <div className="flex flex-nowrap justify-between items-center text-center mt-[20px] w-[220px] m-auto">
            <span className="text-xl sm:text-3xl">ingredients</span>
            <div
              className="rounded-3xl text-xl sm:text-3xl ml-[20px] cursor-pointer ring-1 ring-gray-200"
              onClick={(e) => {
                let currentRecipe = { ...recipe };
                let newIngredient: Ingredient = {
                  Name: "Ingredient, Description",
                  Classification: "",
                  Quantity: 0,
                  Unit: "none",
                  Comment: "",
                };
                let currentIngredients = [...currentRecipe.Ingredients];
                currentIngredients.push(newIngredient);
                currentRecipe.Ingredients = currentIngredients;
                setRecipe(currentRecipe);
              }}
            >
              <GoPlus color="#f3f4f6" />
            </div>
          </div>

          <div className="mx-auto w-[400px] sm:w-[500px] flex flex-wrap justify-around text-center">
            {recipe.Ingredients.map((ingredient, index) => {
              return (
                <div
                  className="my-[5px] p-[10px] w-full flex flex-nowrap rounded-md items-center"
                  key={index}
                  style={{
                    outline: ingredientsSelect[index]
                      ? "thick solid #0000FF"
                      : "thick solid transparent",
                    cursor: !ingredientsSelect[index] ? "pointer" : "default",
                    background: ingredientsSelect[index]
                      ? "white"
                      : "transparent",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();

                    const newIndexValue = !ingredientsSelect[index];
                    let currentIngredientsSelect = new Array(
                      ingredientsSelect.length
                    ).fill(false);

                    deselectAll();

                    currentIngredientsSelect[index] = newIndexValue;
                    setIngredientsSelect(currentIngredientsSelect);
                  }}
                >
                  <span className="items-center text-center absolute text-3xl ml-[-50px] w-[30px]">
                    {/* IoIosCheckmarkCircle
                      IoIosCheckmarkCircleOutline
                      MdOutlinePending */}
                    {ingredientChecker[index] ? (
                      <IoIosCheckmarkCircle color="green" />
                    ) : (
                      <IoIosCheckmarkCircleOutline color="gray" />
                    )}
                  </span>

                  {!ingredientsSelect[index] && (
                    <div className="flex flex-nowrap justify-between w-full truncate text-left items-center border-4 border-transparent">
                      <span
                        style={
                          doesIngredientAlreadyExist(ingredient)
                            ? {
                                textDecoration: "underline",
                                textDecorationColor: "red",
                                textDecorationThickness: "2px",
                              }
                            : {}
                        }
                      >
                        {ingredient.Name}
                      </span>
                      <span>
                        {" "}
                        <span className="text-red-800">
                          {!ingredient.Quantity && ingredient.Unit !== "none"
                            ? "# "
                            : ""}
                        </span>
                        {formatIngredientAmount(
                          ingredient.Unit,
                          ingredient.Quantity
                        )}
                      </span>
                    </div>
                  )}
                  {ingredientsSelect[index] && (
                    <div className="flex flex-nowrap justify-between w-full truncate text-left items-center text-black">
                      <input
                        className="flex-1 truncate border-4 border-transparent outline-none w-[0px]"
                        value={ingredient.Name}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onChange={(e) => {
                          if(e.target.value !== "ingredients") {
                            const currentIngredients = [...recipe.Ingredients];
                            currentIngredients[index].Name = e.target.value;
                            let currentRecipe = { ...recipe };
                            currentRecipe.Ingredients = currentIngredients;
                            setRecipe(currentRecipe);
                          }
                        }}
                      />
                      <div className="flex-1 inline-flex w-[0px]">
                        <div className="w-full inline-flex justify-center items-center hover:ring-2">
                          <span
                            className="cursor-pointer w-full px-[5px]"
                            onClick={(e) => {
                              e.stopPropagation();
                              setClassificationDropdown(
                                !classificationDropdown
                              );
                              setUnitDropdown(false);
                            }}
                          >
                            {ingredient.Classification.length > 0
                              ? ingredient.Classification
                              : "Location"}
                          </span>
                          <AiFillCaretDown />
                        </div>

                        {ingredientsSelect[index] && classificationDropdown && (
                          <div className="absolute mt-[28px] w-[121.33px] sm:w-[154.67px] flex flex-col justify-start bg-white border-4 border-black">
                            {" "}
                            {getIngredientClassificationRanking().map(
                              (value, rankingIndex) => {
                                return (
                                  value !== "recipe" && (
                                    <span
                                      style={{
                                        backgroundColor: getHexColor(value),
                                      }}
                                      className="px-[5px] cursor-pointer"
                                      key={rankingIndex}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        let currentIngredient = {
                                          ...ingredient,
                                        };
                                        currentIngredient.Classification =
                                          value;
                                        let currentIngredients = [
                                          ...recipe.Ingredients,
                                        ];
                                        currentIngredients[index] =
                                          currentIngredient;
                                        let currentRecipe = { ...recipe };
                                        currentRecipe.Ingredients =
                                          currentIngredients;
                                        setClassificationDropdown(false);
                                        setRecipe(currentRecipe);
                                      }}
                                    >
                                      {value}
                                    </span>
                                  )
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                      <div className="flex-1 text-right border-4 border-transparent flex flex-nowrap justify-around w-[0px]">
                        <input
                          className="hover:ring-2 outline-none flex-1 w-[0px] text-center text-black"
                          onClick={(e) => {
                            e.stopPropagation();
                            setClassificationDropdown(false);
                            // deselectAll();
                            // setClockSelect(true);
                          }}
                          // value={
                          //   ingredient.Quantity > 0 ? ingredient.Quantity : ""
                          // }
                          size={3}
                          placeholder={"" + ingredient.Quantity}
                          onChange={(e) => {
                            let newValue = getQuantityFromText(e.target.value);
                            if (newValue > 10080) {
                              newValue = 10080;
                            } else if (newValue === 0) {
                              newValue = 0;
                            }

                            let currentIngredient = {
                              ...ingredient,
                            };
                            currentIngredient.Quantity = newValue;
                            let currentIngredients = [...recipe.Ingredients];
                            currentIngredients[index] = currentIngredient;
                            let currentRecipe = { ...recipe };
                            currentRecipe.Ingredients = currentIngredients;

                            setRecipe(currentRecipe);
                          }}
                        />
                        <div
                          className="flex-1 w-[0px] flex flex-nowrap justify-between items-center hover:ring-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();
                            setClassificationDropdown(false);
                            setUnitDropdown(!unitDropdown);
                          }}
                        >
                          <span className="text-center ml-[5px] truncate">
                            {ingredient.Unit ? ingredient.Unit : "unit"}
                          </span>
                          <AiFillCaretDown />
                        </div>

                        {ingredientsSelect[index] && unitDropdown && (
                          <div className="absolute mt-[28px] w-[121.33px] sm:w-[154.67px] flex flex-col justify-start bg-white border-4 border-black">
                            {" "}
                            {getUSUnits().map((value) => {
                              return (
                                <span
                                  className="px-[5px] cursor-pointer"
                                  key={value.unit}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    let currentIngredient = {
                                      ...ingredient,
                                    };
                                    currentIngredient.Unit = value.unit;
                                    let currentIngredients = [
                                      ...recipe.Ingredients,
                                    ];
                                    currentIngredients[index] =
                                      currentIngredient;
                                    let currentRecipe = { ...recipe };
                                    currentRecipe.Ingredients =
                                      currentIngredients;
                                    setUnitDropdown(false);
                                    setRecipe(currentRecipe);
                                  }}
                                >
                                  {value.unit}
                                </span>
                              );
                            })}
                          </div>
                        )}
                      </div>

                      <div
                        className="absolute ml-[400px] sm:ml-[500px] text-2xl w-[25px] ml-[10px] cursor-pointer"
                        onClick={() => {
                          if (ingredientsSelect[index]) {
                            let currentRecipe = { ...recipe };
                            let currentIngredients = [
                              ...currentRecipe.Ingredients,
                            ];
                            currentIngredients.splice(index, 1);
                            currentRecipe.Ingredients = currentIngredients;
                            setRecipe(currentRecipe);
                          }
                        }}
                      >
                        <TiDeleteOutline
                          onMouseOver={(e) => {
                            e.currentTarget.style.color = "red";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.color = "black";
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {/* <TiDelete /> */}
                </div>
              );
            })}
          </div>

          <div className="flex flex-nowrap justify-between items-center text-center mt-[20px] w-[220px] m-auto">
            <span className="text-xl sm:text-3xl">cookware</span>
            <div
              className="rounded-3xl text-xl sm:text-3xl ml-[20px] bg-gray1 ring-1 ring-gray-200 cursor-pointer"
              onClick={() => {
                let currentRecipe = { ...recipe };
                let currentCookware = [...currentRecipe.Cookware];
                currentCookware.push("");
                currentRecipe.Cookware = currentCookware;
                setRecipe(currentRecipe);
              }}
            >
              <GoPlus color="#f3f4f6" />
            </div>
          </div>
          <div className="mx-auto w-[350px] flex flex-wrap flex-col justify-center text-center text-lg sm:text-xl">
            {recipe.Cookware.map((cookware, index) => {
              return (
                <div
                  className="flex flex-nowrap p-[5px] mt-[10px] justify-between rounded-md"
                  key={index}
                  style={{
                    outline: cookwareSelect[index]
                      ? "thick solid #0000FF"
                      : "thick solid transparent",
                    cursor: !cookwareSelect[index] ? "pointer" : "default",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newIndexValue = true;
                    let currentCookwareSelect = new Array(
                      cookwareSelect.length
                    ).fill(false);
                    currentCookwareSelect[index] = newIndexValue;

                    deselectAll();

                    setCookwareSelect(currentCookwareSelect);
                  }}
                >
                  <span className="items-center text-center absolute text-3xl ml-[-50px] w-[30px]">
                    {/* IoIosCheckmarkCircle
                      IoIosCheckmarkCircleOutline
                      MdOutlinePending */}
                    {cookwareChecker[index] ? (
                      <IoIosCheckmarkCircle color="green" />
                    ) : (
                      <IoIosCheckmarkCircleOutline color="gray" />
                    )}
                  </span>

                  <div className="flex flex-nowrap">
                    {cookwareSelect[index] && (
                      <input
                        className="pl-[5px] outline-none rounded-md text-black"
                        placeholder="Cookware"
                        value={cookware}
                        size={cookware.length > 10 ? cookware.length : 10}
                        onChange={(e) => {
                          const regexp = new RegExp("^[a-zA-Z ]*$");
                          if (regexp.test(e.target.value)) {
                            let currentCookwares = [...recipe.Cookware];
                            currentCookwares[index] = e.target.value;
                            let currentRecipe = { ...recipe };
                            currentRecipe.Cookware = currentCookwares;
                            setRecipe(currentRecipe);
                          }
                        }}
                      />
                    )}
                    {!cookwareSelect[index] && (
                      <span>{cookware.length > 0 ? cookware : "Click Me"}</span>
                    )}
                  </div>

                  {cookwareSelect[index] && (
                    <div
                      className="text-2xl items-center w-[30px] ml-[5px] mt-[2px] cursor-pointer"
                      onClick={() => {
                        if (cookwareSelect[index]) {
                          let currentRecipe = { ...recipe };
                          let currentCookware = [...currentRecipe.Cookware];
                          currentCookware.splice(index, 1);
                          currentRecipe.Cookware = currentCookware;
                          setRecipe(currentRecipe);
                        }
                      }}
                    >
                      <TiDeleteOutline />
                    </div>
                  )}
                  {/* <TiDelete /> */}
                </div>
              );
            })}
          </div>

          <div className="flex flex-nowrap justify-between items-center text-center mt-[20px] text-xl sm:text-3xl w-[220px] m-auto">
            <span>Instructions</span>
            <div className="rounded-3xl text-xl sm:text-3xl ml-[20px] bg-gray1 ring-1 ring-gray-200 cursor-pointer">
              <GoPlus
                color="#f3f4f6"
                onClick={() => {
                  let currentRecipe = { ...recipe };
                  let newInstruction: Instruction = {
                    Header: "",
                    Instruction: "",
                    Order: currentRecipe.Instructions.length,
                    ImgID: "",
                  };

                  let currentInstructions = [...currentRecipe.Instructions];
                  currentInstructions.push(newInstruction);
                  currentRecipe.Instructions = currentInstructions;
                  setRecipe(currentRecipe);
                }}
              />
            </div>
          </div>

          <div className="flex flex-wrap justify-evenly w-full mt-[10px]">
            {recipe.Instructions.map((instruction, index) => {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();

                    let newSelectValue = true;
                    let currentInstructionsSelect = new Array(
                      instructionsSelect.length
                    ).fill(false);
                    currentInstructionsSelect[index] = newSelectValue;

                    deselectAll();
                    setCursorIndex(0);
                    setInstructionsSelect(currentInstructionsSelect);
                  }}
                  className="w-[350px] p-[20px] rounded-lg"
                  key={index}
                  style={{
                    outline: instructionsSelect[index]
                      ? "thick solid #0000FF"
                      : "thick solid transparent",
                    cursor: !instructionsSelect[index] ? "pointer" : "default",
                  }}
                >
                  <div
                    className="flex flex-nowrap justify-around"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span className="ml-auto text-3xl h-[30px]">
                      {instructionsSelect[index] && (
                        <TiDeleteOutline
                          className="cursor-pointer"
                          onClick={(e) => {
                            let currentRecipe = { ...recipe };
                            let currentInstructions = [
                              ...currentRecipe.Instructions,
                            ];
                            currentInstructions.splice(index, 1);
                            currentRecipe.Instructions = currentInstructions;
                            setRecipe(currentRecipe);
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <div className="text-xl w-full flex flex-nowrap justify-between items-center mt-[10px]">
                    <div className="mx-[7px]">
                      {instructionsSelect[index] && (
                        <input
                          className="rounded-lg outline-none p-[5px] text-black"
                          type="text"
                          placeholder={"Header"}
                          value={instruction.Header}
                          onChange={(e) => {
                            let currentInstruction = { ...instruction };
                            currentInstruction.Header = e.target.value;
                            let currentInstructions = [...recipe.Instructions];
                            currentInstructions[index] = currentInstruction;
                            let currentRecipe = { ...recipe };
                            currentRecipe.Instructions = currentInstructions;
                            setRecipe(currentRecipe);
                          }}
                        />
                      )}
                      {!instructionsSelect[index] && (
                        <span>
                          {instruction.Header
                            ? instruction.Header
                            : "Header: Click Me"}
                        </span>
                      )}
                    </div>
                    <div className="mx-[5px] flex flex-nowrap items-center">
                      <span>
                        {index + 1}
                        {"/" + recipe.Instructions.length}
                        {/* {props.recipe.Instructions.length} */}
                      </span>
                    </div>
                  </div>
                  <div className="my-[10px] border-gray-200 rounded-b-md border-2 border-t-0">
                    <div className="m-[5px]">
                      {instructionsSelect[index] && (
                        <div>
                          {/* SHOWS BOX BELOW TYPING */}

                          {/* <div>
                          {isTypingIngredient(
                            cursorIndex,
                            instruction.Instruction
                          ) || (
                            <div className="w-[296px] absolute z-10 pointer-events-none mt-[5px]">
                              <span
                                className="px-[5px] block text-black normal-case break-words whitespace-pre-wrap"
                                style={
                                  {
                                  }
                                }
                              >
                                {instruction.Instruction}
                                
                                <div
                                  ref={typingInstructorEle}
                                  className="absolute inline-flex flex-col bg-gray-200"
                                  style={
                                    {
                                      
                                    }
                                  }
                                >
                                  {getUSUnits().map((value) => {
                                    return <span>{value.unit}</span>;
                                  })}
                                </div>
                              </span>
                            </div>
                          )}
                          </div> */}

                          <textarea
                            ref={(ref) => {
                              if (ref) {
                                instructionsTextInputEle.current[index] = ref;
                              }
                            }}
                            autoFocus={true}
                            className="w-full resize-none overflow-hidden outline-none rounded-lg bg-white p-[5px] text-black"
                            placeholder={"Instruction " + (index + 1)}
                            onFocus={(e) => {
                              e.currentTarget.style.height = "inherit";
                              e.currentTarget.style.height =
                                e.currentTarget.scrollHeight + "px";

                              e.currentTarget.selectionStart =
                                e.currentTarget.value.length;
                            }}
                            onChange={(e) => {
                              if (
                                e.target.selectionStart ===
                                e.target.selectionEnd
                              ) {
                                if (
                                  e.target.value[
                                    e.target.selectionStart - 1
                                  ] === " " &&
                                  e.target.value[
                                    e.target.selectionStart - 2
                                  ] === " "
                                ) {
                                  return;
                                }
                              }

                              let currentInstruction = { ...instruction };
                              currentInstruction.Instruction = e.target.value;

                              let currentInstructions = [
                                ...recipe.Instructions,
                              ];

                              currentInstructions[index] = currentInstruction;
                              let currentRecipe = { ...recipe };
                              currentRecipe.Instructions = currentInstructions;
                              //setCursorIndex(e.target.selectionStart);
                              setRecipe(currentRecipe);
                            }}
                            value={instruction.Instruction}
                          />
                        </div>
                      )}

                      {!instructionsSelect[index] && (
                        <div
                          className="whitespace-pre-wrap break-words normal-case block"
                          onClick={(e) => {
                            e.stopPropagation();
                            let currentInstructionsSelect = new Array(
                              instructionsSelect.length
                            ).fill(false);
                            currentInstructionsSelect[index] = true;

                            deselectAll();

                            setInstructionsSelect(currentInstructionsSelect);
                          }}
                        >
                          {formatInstructionFromSpecialText(
                            instruction.Instruction,
                            index,
                            specialTextWithinInstruction
                          ).map((value, index) => {
                            return (
                              <span
                                key={index}
                                style={{
                                  textDecoration: value.isIngredient
                                    ? "underline"
                                    : "none",
                                  fontWeight: value.isIngredient
                                    ? "bolder"
                                    : "",
                                  textDecorationThickness: "2px",
                                  textDecorationColor:
                                    value.classification !== ""
                                      ? getHexColor(value.classification)
                                      : "transparent",
                                }}
                              >
                                {value.text}
                              </span>
                            );
                          })}
                        </div>

                        // <span
                        //   className="whitespace-pre-wrap break-words normal-case block"
                        //   onClick={(e) => {
                        //     e.stopPropagation();
                        //     let currentInstructionsSelect = new Array(
                        //       instructionsSelect.length
                        //     ).fill(false);
                        //     currentInstructionsSelect[index] = true;

                        //     deselectAll();

                        //     setInstructionsSelect(currentInstructionsSelect);
                        //   }}
                        // >

                        //   {instruction.Instruction
                        //     ? instruction.Instruction
                        //     : "Some super awesome instruction..."}
                        // </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-nowrap text-center justify-center select-none mt-[30px] sm:text-xl">
            <div
              className="py-[10px] m-[10px] rounded-lg"
              style={{
                outline: clockSelect
                  ? "thick solid #0000FF"
                  : "thick solid transparent",
                cursor: !clockSelect ? "pointer" : "default",
                width: "180px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                deselectAll();
                setClockSelect(true);
              }}
            >
              <FiClock className="w-full" size="2em" />
              {/* <span>{formatTime(recipe.TimeMinutes)}</span> */}
              <div className="mt-[5px]">
                {clockSelect && (
                  <input
                    className="outline-none text-center rounded-lg text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      // deselectAll();
                      // setClockSelect(true);
                    }}
                    type="number"
                    min={undefined || 0}
                    max={10080}
                    value={recipe.TimeMinutes > 0 ? recipe.TimeMinutes : ""}
                    placeholder="Minutes"
                    onChange={(e) => {
                      if (parseInt(e.target.value)) {
                        let currentRecipe = { ...recipe };
                        currentRecipe.TimeMinutes = parseInt(e.target.value);
                        if (currentRecipe.TimeMinutes > 10080) {
                          currentRecipe.TimeMinutes = 10080;
                        }
                        setRecipe(currentRecipe);
                      } else {
                        let currentRecipe = { ...recipe };
                        currentRecipe.TimeMinutes = NaN;
                        setRecipe(currentRecipe);
                      }
                    }}
                  />
                )}
                {!clockSelect && (
                  <span>
                    {recipe.TimeMinutes
                      ? formatTime(recipe.TimeMinutes)
                      : "Time: Click Me"}
                  </span>
                )}
              </div>
            </div>
            <div
              className="py-[10px] m-[10px] rounded-lg"
              style={{
                outline: servingSelect
                  ? "thick solid #0000FF"
                  : "thick solid transparent",
                cursor: !servingSelect ? "pointer" : "default",
                width: "180px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                deselectAll();
                setServingSelect(true);
              }}
            >
              <MdPerson className="w-full" size="2em" />
              {/* <span>{formatTime(recipe.TimeMinutes)}</span> */}
              <div className="mt-[5px]">
                {servingSelect && (
                  <input
                    className="outline-none text-center rounded-lg w-[115px] text-black"
                    // style={{width: "200px"}}
                    onClick={(e) => {
                      e.stopPropagation();
                      // deselectAll();
                      // setClockSelect(true);
                    }}
                    type="number"
                    min={undefined || 0}
                    max={99}
                    value={recipe.Servings > 0 ? recipe.Servings : ""}
                    placeholder="Servings"
                    onChange={(e) => {
                      if (parseInt(e.target.value)) {
                        let currentRecipe = { ...recipe };
                        currentRecipe.Servings = parseInt(e.target.value);
                        if (currentRecipe.Servings > 99) {
                          currentRecipe.Servings = 99;
                        }
                        setRecipe(currentRecipe);
                      } else {
                        let currentRecipe = { ...recipe };
                        currentRecipe.Servings = NaN;
                        setRecipe(currentRecipe);
                      }
                    }}
                  />
                )}
                {!servingSelect && (
                  <span>
                    {recipe.Servings ? recipe.Servings : "Servings: Click Me"}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-nowrap justify-around">
            <div>
              <span>
                Searchable:{" "}
                <input
                  type="checkbox"
                  className="cursor-pointer text-black"
                  defaultChecked={recipe.IsSearchable}
                  onClick={() => {
                    let currentRecipe = { ...recipe };
                    currentRecipe.IsSearchable = !currentRecipe.IsSearchable;
                    setRecipe(currentRecipe);
                  }}
                />
              </span>
            </div>
            <div>
              <span>
                Show User ID:{" "}
                <input
                  type="checkbox"
                  className="cursor-pointer text-black"
                  defaultChecked={recipe.IsUserIDVisible}
                  onClick={() => {
                    let currentRecipe = { ...recipe };
                    currentRecipe.IsUserIDVisible =
                      !currentRecipe.IsUserIDVisible;
                    setRecipe(currentRecipe);
                  }}
                />
              </span>
            </div>
          </div>
          <div className="flex flex-nowrap justify-around mt-[30px] text-3xl py-[15px] cursor-pointer">
            {!undetectedIngredients && (
              <span
                className="border-4 border-gray4 py-[5px] px-[10px] hover:border-gray-200"
                onClick={() => {
                  createOrUpdateRecipe();
                }}
              >
                {props.recipe ? "update" : "create"}
              </span>
            )}
            {undetectedIngredients && (
              <div className="text-lg">
                <span className="normal-case">
                  Are you sure you would like to{" "}
                  {props.recipe ? "update" : "create"} recipe with missing
                  ingredients?
                </span>
                <span
                  className="ml-[10px] px-[5px] cursor-pointer hover:ring-2 hover:ring-white rounded-lg"
                  onClick={() => createOrUpdateRecipe(true)}
                >
                  Yes
                </span>
              </div>
            )}
          </div>
        </div>
      )}
      {/* <Toaster position="bottom-center" reverseOrder={false} /> */}
    </div>
  );
};

export default CreateOrEditRecipe;

//call trim on instructions/ingredients
