import Ingredient from "../../interfaces/Ingredient";
import Instruction from "../../interfaces/Instruction";
import SpecialTextWithinInstruction from "../../interfaces/SpecialTextWithinInstruction";
import { getQuantityFromText, getUSUnits } from "./OfflineAPI";

export const parseSpecialTextInsideInstruction = (
  instruction: Instruction,
  instructionIndex: number,
  ingredients: Ingredient[],
  cookware: string[],
  previouslyFoundIngredients: SpecialTextWithinInstruction[]
): SpecialTextWithinInstruction[] => {
  //" aaa aaa" lets us not have to check the last current word and spacing
  instruction.Instruction += " aaa aaa";
  const lowercaseIngredientNames = [...ingredients];
  lowercaseIngredientNames.forEach((ingredient) => {
    ingredient.Name = ingredient.Name.slice().toLowerCase();
  });

  const lowercaseCookwareNames = [...cookware];
  lowercaseCookwareNames.forEach((value, index) => {
    lowercaseCookwareNames[index] = value.slice().toLowerCase();
  });

  let fillerWords: string[] = ["of", "the", "a"];

  let importantWords: string[] = [
    "remaining",
    "all",
    "pinch",
    // "pinch",
    // "drizzle",
  ];

  let currentWord = "";

  let startIndex = 0;
  let unit = "";
  let importantWord = "";
  let ingredientBuilder = "";
  //let cookwareBuilder = "";
  let quantity = 0;
  let usedFillerWords: string[] = [];
  let previousWordIsAnd = false;
  let chainedIngredient = false;

  let specialText: SpecialTextWithinInstruction[] = [];

  for (let i = 0; i < instruction.Instruction.length; i++) {
    if (instruction.Instruction.charAt(i) !== " ") {
      currentWord += instruction.Instruction.charAt(i).toLowerCase();
    } else if (currentWord.length > 0) {
      let reset = true;
      let wordWithOnlyLetters = "";
      for (const c of currentWord) {
        if (c.toUpperCase() !== c.toLowerCase()) {
          wordWithOnlyLetters += c;
        }
      }

      let wordWithOnlyLettersAndNoEndingS = wordWithOnlyLetters;
      const currentWordWithNoEndingPunctuation =
        currentWord[currentWord.length - 1].toUpperCase() ===
          currentWord[currentWord.length - 1].toLowerCase() &&
        isNaN(parseInt(currentWord[currentWord.length - 1]))
          ? currentWord.substring(0, currentWord.length - 1)
          : currentWord;

      if (
        wordWithOnlyLettersAndNoEndingS.charAt(
          wordWithOnlyLettersAndNoEndingS.length - 1
        ) === "s"
      ) {
        wordWithOnlyLettersAndNoEndingS =
          wordWithOnlyLettersAndNoEndingS.substring(
            0,
            wordWithOnlyLettersAndNoEndingS.length - 1
          );
      }

      let biggerIngredient: Ingredient | undefined;
      if (ingredientBuilder !== "") {
        biggerIngredient = ingredients.find((ingredient) => {
          return (
            ingredient.Name.indexOf(
              ingredientBuilder + " " + currentWordWithNoEndingPunctuation
            ) === 0
          );
        });
      }

      if (biggerIngredient) {
        ingredientBuilder += " " + currentWordWithNoEndingPunctuation;
        reset = false;
      }

      let newIngredient: Ingredient | undefined;
      if (!biggerIngredient) {
        newIngredient = ingredients.find((ingredient) => {
          const firstIndexOfSpace = ingredient.Name.indexOf(" ");
          const firstIngredientWord =
            firstIndexOfSpace > 0
              ? ingredient.Name.substring(0, ingredient.Name.indexOf(" "))
              : ingredient.Name;
          return (
            firstIngredientWord.indexOf(currentWordWithNoEndingPunctuation) ===
              0 &&
            firstIngredientWord.length -
              currentWordWithNoEndingPunctuation.length <=
              1
          );
          //return firstIngredientWord === currentWordWithNoEndingPunctuation || firstIngredientWord === wordWithOnlyLettersAndNoEndingS;
        });
      }

      if (!biggerIngredient && ingredientBuilder) {
        const foundIngredient = ingredients.find((ingredient) => {
          return (
            ingredient.Name.indexOf(ingredientBuilder) === 0 &&
            ingredient.Name.length - ingredientBuilder.length <= 1
          );

          //return ingredient.Name === ingredientBuilder
        });

        if (foundIngredient) {
          //handles scenario where ingredient name is 4.5oz steak
          let finalUnit =
            foundIngredient.Unit === "none" || unit === "" ? "none" : unit;

          let finalQuantity: number = 0;
          if (importantWord && quantity === 0) {
            if (importantWord === "all") {
              finalQuantity = foundIngredient.Quantity;
              finalUnit = foundIngredient.Unit;
            } else if (importantWord === "remaining") {
              finalQuantity = foundIngredient.Quantity;
              finalUnit = foundIngredient.Unit;
              specialText.forEach((previouslyFoundIngredient) => {
                if (
                  previouslyFoundIngredient.searchText === ingredientBuilder
                ) {
                  finalQuantity -= previouslyFoundIngredient.quantity;
                }
              });
              previouslyFoundIngredients.forEach(
                (previouslyFoundIngredient) => {
                  if (
                    previouslyFoundIngredient.searchText === ingredientBuilder
                  ) {
                    finalQuantity -= previouslyFoundIngredient.quantity;
                  }
                }
              );
            }
          } else {
            if (finalUnit === "none" && finalQuantity < 1) {
              console.log("a");
              //why was it this line below??
              //finalQuantity = foundIngredient.Quantity * quantity;
              if (importantWord === "pinch") {
                finalQuantity = 0;
              } else {
                finalQuantity = quantity;
              }
              finalUnit = foundIngredient.Unit;
            } else {
              finalQuantity = quantity;
            }
          }

          const newSpecialText: SpecialTextWithinInstruction = {
            currentIngredient: foundIngredient,
            //was ingredient builder, but didn't handle (s)
            //searchText: foundIngredient.Name,
            searchText: ingredientBuilder,
            importantWord: importantWord,
            fillerWords: usedFillerWords,
            instructionIndex: instructionIndex,
            unit: finalUnit,
            quantity: finalQuantity,
            textStart: startIndex,
            textStop: i - currentWord.length,
            chainedIngredient: chainedIngredient,
          };
          specialText.push(newSpecialText);

          if (currentWordWithNoEndingPunctuation !== "and") {
            quantity = 0;
            unit = "";
          }

          chainedIngredient = true;
          startIndex = i - currentWord.length;
          ingredientBuilder = "";
          usedFillerWords = [];
        }
      }

      if(currentWordWithNoEndingPunctuation === "ingredients" && (importantWord === "all" ||  importantWord === "remaining")) {
        const newSpecialText: SpecialTextWithinInstruction = {
          currentIngredient: undefined,
          //was ingredient builder, but didn't handle (s)
          //searchText: foundIngredient.Name,
          searchText: "ingredients",
          importantWord: importantWord,
          fillerWords: usedFillerWords,
          instructionIndex: instructionIndex,
          unit: "none",
          quantity: 0,
          textStart: startIndex,
          textStop: i,
          chainedIngredient: chainedIngredient,
        };
        specialText.push(newSpecialText);
      }


      if (newIngredient) {
        ingredientBuilder = currentWordWithNoEndingPunctuation;
        reset = false;
      }

      // let cookwareSearch = (cookwareBuilder != "") ? cookwareBuilder + " " + currentWordWithNoEndingPunctuation : currentWordWithNoEndingPunctuation
      // //let indexOfSearch = lowercaseCookwareNames.indexOf(cookwareSearch)
      // let indexOfSearch = lowercaseCookwareNames.findIndex(cookware => {
      //  //console.log(cookware + " looking for " + cookwareSearch)
      //   //console.log(cookware.indexOf(cookwareSearch))
      //   return cookware.indexOf(cookwareSearch) === 0
      // })

      // //indexOfSearch = newIndexOfSearch
      // if(cookwareBuilder.length > 0){

      //   //console.log("builder " + cookwareBuilder)
      // }

      // // console.log(cookwareSearch)
      // // console.log(indexOfSearch)
      // if(indexOfSearch === 0){
      //   //console.log(cookwareSearch)
      //   cookwareBuilder = cookwareSearch
      //   reset = false
      // } else if(cookwareBuilder !== "") {
      //   //console.log("ima find ya" + cookwareBuilder)
      //   //try and add cookware
      //   //clear quantity and try to add cookware builder if exists
      //   let cookwareMatch = lowercaseCookwareNames.find(value => {
      //     return value === cookwareBuilder
      //   })

      //   if(cookwareMatch){
      //     const newSpecialText: SpecialTextWithinInstruction = {
      //       searchText: cookwareMatch,
      //       importantWord: importantWord,
      //       fillerWords: usedFillerWords,
      //       instructionIndex: instructionIndex,
      //       unit: unit,
      //       quantity: quantity,
      //       textStart: startIndex,
      //       textStop: i - currentWord.length,
      //       chainedIngredient: chainedIngredient
      //     }

      //     specialText.push(newSpecialText)
      //     startIndex = (i - currentWord.length)
      //   }

      //   quantity = 0
      //   cookwareBuilder = ""

      // } else {
      //   cookwareBuilder = ""
      // }

      const currentQuantity = getQuantityFromText(currentWord);
      if (currentQuantity > 0) {
        if (previousWordIsAnd) {
          quantity = 0;
          importantWord = "";
        }
        quantity += currentQuantity;
        reset = false;
      }

      let currentUnit = getUSUnits().find((unit) => {
        return (
          unit.unit === wordWithOnlyLettersAndNoEndingS ||
          unit.abbreviation === wordWithOnlyLettersAndNoEndingS
        );
      });

      if (currentUnit) {
        unit = currentUnit.unit;
        reset = false;
      }

      if (
        currentQuantity > 0 &&
        !currentUnit &&
        currentWord.charAt(currentWord.length - 1).toUpperCase() !==
          currentWord.charAt(currentWord.length - 1).toLowerCase()
      ) {
        //special case, 400g, already set reset to false
        currentUnit = getUSUnits().find((unit) => {
          return (
            wordWithOnlyLettersAndNoEndingS.indexOf(unit.unit) > 0 ||
            wordWithOnlyLettersAndNoEndingS.indexOf(unit.abbreviation) > 0
          );
        });
      }

      const currentImportantWordIndex = importantWords.indexOf(currentWord);
      if (currentImportantWordIndex >= 0) {
        importantWord = importantWords[currentImportantWordIndex];
        reset = false;
      }

      if (
        fillerWords.indexOf(wordWithOnlyLetters) >= 0 &&
        usedFillerWords.indexOf(wordWithOnlyLetters) === -1
      ) {
        usedFillerWords.push(wordWithOnlyLetters);
        reset = false;
      }

      if (reset && currentWord !== "and") {
        startIndex = i + 1;
        unit = "";
        importantWord = "";
        ingredientBuilder = "";
        quantity = 0;
        usedFillerWords = [];
        chainedIngredient = false;
      }

      previousWordIsAnd = currentWord === "and";
      currentWord = "";
    }
  }

  //removing " aaa aaa"
  instruction.Instruction = instruction.Instruction.substring(
    0,
    instruction.Instruction.length - 8
  );

  return specialText;
};
