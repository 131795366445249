import React, { useState } from "react";
import Recipe from "../interfaces/RecipeInterface";
import { GoPlus, GoDash } from "react-icons/go";
import { FiClock } from "react-icons/fi";
import { MdPerson, MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { TiDeleteOutline, TiDelete } from "react-icons/ti";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getRecipesFromCart,
  addRecipeToCart,
  deleteRecipeFromFavorites,
  deleteRecipeFromCart,
  addRecipeToFavorites,
  getRecipesFromFavorites,
  updateRecipeInFavorites,
  updateRecipeInCart,
} from "./API/API";
import { useLocation } from "wouter";
import { formatTime } from "./API/OfflineAPI";
import toast, { Toaster } from "react-hot-toast";

interface RecipeThumbnailProps {
  recipe: Recipe;
  panel: string;
}

const indexOfRecipeInSelection = (id: string, recipes: Recipe[]): number => {
  const index = recipes.map((recipe: Recipe) => recipe.ID).indexOf(id);
  return index;
};

const isRecipeInPanel = (id: string, cart: Recipe[]): Boolean => {
  return (
    cart
      .map((currentRecipe: Recipe) => {
        return currentRecipe.ID;
      })
      .indexOf(id) >= 0
  );
};
var timeouts: { [id: string]: NodeJS.Timeout } = {};
let updateServing = (recipeID: string, onFinish: Function) => {
  if (timeouts[recipeID]) {
    clearTimeout(timeouts[recipeID]);
  }

  timeouts[recipeID] = setTimeout(() => {
    //do stuff
    onFinish();
  }, 2000);
};

let updateServingTimeout: NodeJS.Timeout;
let updateServingOld = (onFinish: Function) => {
  if (updateServingTimeout) {
    clearTimeout(updateServingTimeout);
  }

  updateServingTimeout = setTimeout(() => {
    //do stuff
    onFinish();
  }, 2000);
};

const RecipeThumbnail = (props: RecipeThumbnailProps) => {
  const queryClient = useQueryClient();

  const { data: cartRecipes = [] as Recipe[] } = useQuery(
    ["cart.me"],
    getRecipesFromCart
  );
  const { data: favoriteRecipes = [] as Recipe[] } = useQuery(
    ["favorites.me"],
    getRecipesFromFavorites
  );

  const [location, setLocation] = useLocation();

  // useEffect(() => {
  //   if (props.panel === "browse" || props.panel === "favorites") {
  //     setIsRecipeInPanel(isRecipeInSelection(props.recipe.ID, cartRecipes))
  //   }
  // }, [cartRecipes]);

  // useEffect(() => {
  //   if (props.panel === "cart") {
  //     setIsRecipeInPanel(isRecipeInSelection(props.recipe.ID, favoriteRecipes))
  //   }
  // }, [favoriteRecipes]);

  const [removeRecipeFromCartHover, setRemoveRecipeFromCartHover] =
    useState<Boolean>(false);
  const [removeRecipeFromFavoritesHover, setRemoveRecipeFromFavoritesHover] =
    useState<Boolean>(false);
  const [addRecipeToFavoritesHover, setAddRecipeToFavoritesHover] =
    useState<Boolean>(false);

  // const [isRecipeInPanel, setIsRecipeInPanel] = useState<Boolean>(
  //   (): Boolean => {
  //     console.log("CONSTRUCTION ISRECIPEINPANEL")
  //     if (props.panel === "browse" || props.panel === "favorites") {
  //       return isRecipeInSelection(props.recipe.ID, cartRecipes);
  //     } else if (props.panel === "cart") {
  //       return isRecipeInSelection(props.recipe.ID, favoriteRecipes);
  //     }
  //     console.log("I should never be here")
  //     return false;
  //   }
  // );

  // const [customServing, setCustomServing] = useState<number>(props.recipe.CustomServings);

  const addRecipeToFavoritesMutation = useMutation(
    (recipe: Recipe) => addRecipeToFavorites(recipe),
    {
      onSuccess: () => {
        toast("Added Recipe to Favorites", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["favorites.me"]);
      },
    }
  );

  const editFavoriteRecipeServingsMutation = useMutation(
    (recipe: Recipe) => updateRecipeInFavorites(recipe),
    {
      onSettled: (variables) => {
        queryClient.invalidateQueries(["favorites.me"]);
      },
    }
  );

  const removeRecipeFromFavoritesMutation = useMutation(
    (recipe: Recipe) => deleteRecipeFromFavorites(recipe),
    {
      // onMutate: async (variables) => {
      //   queryClient.setQueryData<Recipe[]>(["favorites.me"], (oldData) => {
      //     if(oldData) {
      //       return oldData.splice(oldData.indexOf(variables))
      //     }

      //     return []
      //   })
      // },
      onSuccess: () => {
        toast("Removed Recipe from Favorites", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["favorites.me"]);
      },
    }
  );

  const addRecipeToCartMutation = useMutation(
    (recipe: Recipe) => addRecipeToCart(recipe),
    {
      // onSettled: async (variables) => {
      //   await queryClient.invalidateQueries(["cart.me"]);
      // },
      onSuccess: () => {
        toast("Added Recipe to Cart", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: (variables) => {
        queryClient.invalidateQueries(["cart.me"]);
      },
    }
  );

  const editCartRecipeServingsMutation = useMutation(
    (recipe: Recipe) => updateRecipeInCart(recipe),
    {
      onSettled: (variables) => {
        queryClient.invalidateQueries(["cart.me"]);
      },
    }
  );

  const removeRecipeFromCartMutation = useMutation(
    (recipe: Recipe) => deleteRecipeFromCart(recipe),
    {
      // onSettled: async (variables) => {
      //   await queryClient.invalidateQueries(["cart.me"]);
      // },
      onSuccess: () => {
        toast("Removed Recipe from Cart", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: (variables) => {
        queryClient.invalidateQueries(["cart.me"]);
      },
    }
  );

  const convertStringToServing = (servingString: string): number | null => {
    let serving: number = parseInt(servingString);

    if (serving === NaN) {
      return null;
    }

    if (serving < 1) {
      serving = 1;
    }

    if (serving > 99) {
      serving = 99;
    }

    return serving;
  };

  // const [recipeID, setRecipeID] = useState(props.recipe.ID);
  // const [customServing, setCustomServing] = useState(props.recipe.CustomServings);

  // if(recipeID !== props.recipe.ID) {
  //   setRecipeID(props.recipe.ID)
  //   setCustomServing(props.recipe.CustomServings)
  // }
  // let currentRecipe = {...props.recipe}

  //const [recipe, setRecipe] = useState(props.recipe);
  // if (props.recipe.ID !== recipe.ID) {
  //   setRecipe(props.recipe);
  // }

  const [customServing, setCustomServing] = useState(
    props.recipe.CustomServings
  );

  let youtubeID;
  if (props.recipe.Source.indexOf("https://www.youtube.com/embed/") === 0) {
    youtubeID = props.recipe.Source.substring(
      props.recipe.Source.lastIndexOf("/") + 1
    );
    if (youtubeID.indexOf("?") > 0) {
      youtubeID = youtubeID.substring(0, youtubeID.indexOf("?"));
    }
  }

  let pdf = false;
  pdf = props.recipe.Source.indexOf(".pdf", props.recipe.Source.length - 4) > 0;

  return (
    <div className="w-[210px] h-[245px] sm:w-[300px] sm:h-[350px] bg-gray1 rounded-[20px] m-[10px] flex flex-col select-none ring-2 ring-gray-100 p-[3px]">
      <div className="absolute self-end sm:mt-[5px] sm:mr-[5px] cursor-pointer">
        {props.panel === "cart" && (
          <div
            onMouseOver={() => {
              setRemoveRecipeFromCartHover(true);
              // componentScrollYOffset = window.pageYOffset;i
            }}
            onMouseLeave={() => {
              setRemoveRecipeFromCartHover(false);
            }}
            onClick={() => {
              removeRecipeFromCartMutation.mutate(props.recipe);
            }}
          >
            {!removeRecipeFromCartHover && <TiDeleteOutline size="1.5em" />}
            {removeRecipeFromCartHover && <TiDelete size="1.5em" color="red" />}
          </div>
        )}

        {props.panel === "favorites" && (
          <div
            onMouseOver={() => {
              setRemoveRecipeFromFavoritesHover(true);
              // componentScrollYOffset = window.pageYOffset;i
            }}
            onMouseLeave={() => {
              setRemoveRecipeFromFavoritesHover(false);
            }}
            onClick={() => {
              removeRecipeFromFavoritesMutation.mutate(props.recipe);
            }}
          >
            {removeRecipeFromFavoritesHover && (
              <MdFavoriteBorder size="1.5em" />
            )}
            {!removeRecipeFromFavoritesHover && (
              <MdFavorite size="1.5em" color="red" />
            )}
          </div>
        )}
      </div>

      <div className="h-1/5 w-full text-center flex justify-center items-center">

        <label
          className="w-[75%] sm:text-3xl overflow-clip capitalize sm:mx-[5px] text-teal1 cursor-pointer inline-block overflow-ellipsis"
          onClick={() => {
            setLocation(location + "/" + props.recipe.ID);
          }}
        >
          {props.recipe.Title}
        </label>

       
      </div>

      <div className="h-3/5 flex flex-col">
        {youtubeID && (
          <img
            className="w-4/5 rounded-xl border-4 cursor-pointer mx-auto"
            src={"https://img.youtube.com/vi/" + youtubeID + "/hqdefault.jpg"}
            onClick={() => {
              setLocation(location + "/" + props.recipe.ID);
            }}
          />
        )}

        {!youtubeID && (
          <img
            className="m-auto cursor-pointer h-full w-full object-contain"
            src={process.env.PUBLIC_URL + "logo.png"}
            onClick={() => {
              setLocation(location + "/" + props.recipe.ID);
            }}
          />
        )}
      </div>

      <div className="h-1/5 flex flex-nowrap text-center justify-around text-lg">
        <div className="w-[20%] h-full flex flex-col flex-nowrap space-around">
          <div className="h-[50%]">
            <FiClock className="w-full" size="100%" />
          </div>
          <div className="h-[50%]">
            <span>{formatTime(props.recipe.TimeMinutes)}</span>
          </div>
        </div>

        {(props.panel === "cart" || props.panel === "favorites") && (
          <div className="w-[25%]">
            <MdPerson className="w-full h-[50%]" size="100%" />
            <div className="flex flex-nowrap justify-around h-[50%]">
              <div className="self-center border-opacity-0 border-[2px] rounded-lg hover:border-opacity-80 cursor-pointer border-blue-400 hover:border-gray-100 mr-[2px]">
                <GoDash
                  onClick={() => {
                    //change servings

                    if (customServing > 1) {
                      //setCustomServing(currentRecipe.CustomServings)
                      let currentRecipe = { ...props.recipe };
                      currentRecipe.CustomServings = customServing - 1;

                      if (props.panel === "cart") {
                        updateServing(currentRecipe.ID, () => {
                          editCartRecipeServingsMutation.mutate(currentRecipe);
                        });
                      } else if (props.panel === "favorites") {
                        updateServing(currentRecipe.ID, () => {
                          editFavoriteRecipeServingsMutation.mutate(
                            currentRecipe
                          );
                        });
                      }

                      setCustomServing(customServing - 1);
                    }
                  }}
                />
              </div>
              <input
                className="text-center w-[30px] rounded bg-gray1 text-gray-100 ring-1 ring-gray-100"
                type="tel"
                value={customServing}
                onClick={(e) => {
                  e.currentTarget.setSelectionRange(
                    0,
                    e.currentTarget.value.length
                  );
                }}
                onChange={(e) => {
                  //change servings
                  const newServing = convertStringToServing(e.target.value);

                  if (newServing) {
                    let currentRecipe = { ...props.recipe };

                    currentRecipe.CustomServings = newServing;
                    //setCustomServing(currentRecipe.CustomServings)
                    if (props.panel === "cart") {
                      updateServing(currentRecipe.ID, () => {
                        editCartRecipeServingsMutation.mutate(currentRecipe);
                      });
                    } else if (props.panel === "favorites") {
                      updateServing(currentRecipe.ID, () => {
                        editFavoriteRecipeServingsMutation.mutate(
                          currentRecipe
                        );
                      });
                    }
                    setCustomServing(newServing);
                  }
                }}
              />
              <div className="self-center border-opacity-0 border-[2px] rounded-lg hover:border-opacity-80 cursor-pointer border-blue-400 hover:border-gray-100 ml-[2px]">
                <GoPlus
                  onClick={() => {
                    //change servings

                    if (customServing < 99) {
                      let currentRecipe = { ...props.recipe };
                      currentRecipe.CustomServings = customServing + 1;
                      if (props.panel === "cart") {
                        updateServing(currentRecipe.ID, () => {
                          editCartRecipeServingsMutation.mutate(currentRecipe);
                        });
                      } else if (props.panel === "favorites") {
                        updateServing(currentRecipe.ID, () => {
                          editFavoriteRecipeServingsMutation.mutate(
                            currentRecipe
                          );
                        });
                      }
                      setCustomServing(customServing + 1);
                      //setCustomServing(customServing + 1)
                    }
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {(props.panel === "browse" || props.panel === "owned") && (
          <div className="w-[25%]">
            <MdPerson className="w-full h-[50%]" size="1em" />
            <span className="pr-[2px] h-[50%]">{props.recipe.Servings}</span>
          </div>
        )}

        {(props.panel === "browse" ||
          props.panel === "favorites" ||
          props.panel === "owned") &&
          !isRecipeInPanel(props.recipe.ID, cartRecipes) && (
            <div className="w-[20%] flex flex-nowrap justify-around">
              <GoPlus
                className="border-opacity-0 border-[2px] rounded-lg hover:border-opacity-80 cursor-pointer border-blue-400 hover:border-gray-100"
                size="2em"
                onClick={() => {
                  addRecipeToCartMutation.mutate(props.recipe);
                  //setIsRecipeInPanel(!isRecipeInPanel);
                }}
              />
            </div>
          )}
        {props.panel === "browse" &&
          isRecipeInPanel(props.recipe.ID, cartRecipes) && (
            <div className="w-[20%] flex flex-nowrap justify-around">
              <GoDash
                className="border-opacity-0 border-[2px] rounded-lg hover:border-opacity-80 cursor-pointer border-blue-400 hover:border-gray-100"
                size="2em"
                onClick={() => {
                  removeRecipeFromCartMutation.mutate(props.recipe);
                  //setIsRecipeInPanel(!isRecipeInPanel);
                }}
              />
            </div>
          )}
        {props.panel === "cart" && (
          <div className="w-[20%]">
            {isRecipeInPanel(props.recipe.ID, favoriteRecipes) && (
              <MdFavorite
                className="w-full cursor-pointer"
                size="2em"
                color="red"
                onClick={() => {
                  //componentScrollYOffset = window.pageYOffset;

                  removeRecipeFromFavoritesMutation.mutate(props.recipe);
                  //setIsRecipeInPanel(!isRecipeInPanel);
                }}
              />
            )}
            {!isRecipeInPanel(props.recipe.ID, favoriteRecipes) && (
              <MdFavorite
                className="w-full cursor-pointer"
                size="2em"
                color={addRecipeToFavoritesHover ? "red" : "#f3f4f6"}
                onMouseOver={() => {
                  setAddRecipeToFavoritesHover(true);
                }}
                onMouseLeave={() => {
                  setAddRecipeToFavoritesHover(false);
                }}
                onClick={() => {
                  //componentScrollYOffset = window.pageYOffset;
                  addRecipeToFavoritesMutation.mutate(props.recipe);
                  //setIsRecipeInPanel(!isRecipeInPanel);
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipeThumbnail;
