import React, { Profiler, useEffect } from "react";
import "./App.css";
import Dashboard from "./components/Dashboard";
import Browse from "./components/Browse";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route } from "wouter";
import Favorites from "./components/Favorites";
import YourRecipes from "./components/YourRecipes";
import YourIngredients from "./components/YourIngredients";
import CurrentUser from "./components/Profile/CurrentUser";
import PrivacyPolicy from "./components/PrivacyPolicy";


const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="bg-gray4 h-full min-h-screen text-gray-100 font-Dosis">
        <Dashboard />

        <Route path="/privacy">
          <PrivacyPolicy />
        </Route>
        
        <div className="pt-[75px]">
          <Route path="/browse">
            <Browse searchValue={""} />
          </Route>

          <Route path="/browse/:recipeid">
            <Browse searchValue={""} />
          </Route>

          <Route path="/cart">
            <YourRecipes />
          </Route>

          <Route path="/cart/:recipeid">
            <YourRecipes />
          </Route>

          <Route path="/ingredients">
            <YourIngredients />
          </Route>

          <Route path="/favorites" component={Favorites} />
          <Route path="/favorites/:recipeid" component={Favorites} />

          <Route path="/profile">
            {/* dashboard takes over */}
            <div></div>
          </Route>

          <Route path="/login">
            <CurrentUser />
          </Route>

          <Route path="/recipe/:id"></Route>

          <Route path="/">
            <div></div>
            {/* <Redirect to={"/browse"}/> */}
          </Route>
        </div>
      </div>

      {/* <Dashboard /> */}
    </QueryClientProvider>
  );
};

export default App;
