import React, { useState, useLayoutEffect, useRef } from "react";
import Recipe from "../interfaces/RecipeInterface";
import Ingredient from "../interfaces/Ingredient";
import { TiArrowBackOutline } from "react-icons/ti";
import { FiClock } from "react-icons/fi";
import { MdPerson } from "react-icons/md";
import { GoPlus, GoDash } from "react-icons/go";
import { MdFavorite } from "react-icons/md";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getRecipesFromCart,
  getRecipesFromFavorites,
  addRecipeToFavorites,
  deleteRecipeFromFavorites,
  addRecipeToCart,
  deleteRecipeFromCart,
} from "./API/API";
import { useLocation } from "wouter";
import {
  getClassificationRanking,
  getRandomHexColor,
  formatTime,
  getHexColor,
  getMixedNumber,
  formatIngredientAmount,
} from "./API/OfflineAPI";
import { parseSpecialTextInsideInstruction } from "./API/RecipeParser";
import SpecialTextWithinInstruction from "../interfaces/SpecialTextWithinInstruction";
import Instruction from "../interfaces/Instruction";
import toast from "react-hot-toast";
const axios = require("axios");

interface FocusRecipeProps {
  recipe: Recipe;
  panel: string;
}

const isRecipeInSelection = (id: string, cart: Recipe[]): Boolean => {
  return (
    cart
      .map((currentRecipe: Recipe) => {
        return currentRecipe.ID;
      })
      .indexOf(id) >= 0
  );
};

const scrollIntoView = (element: HTMLDivElement | null) => {
  if (element) {
    //150 comes from dashboard + scroll to header
    window.scrollTo({
      top: element.offsetTop - 125,
      left: 0,
      behavior: "smooth",
    });
    //window.scrollTo(0, element.scrollTop)
  }

  //element?.scrollIntoView()
};

const FocusRecipe = (props: FocusRecipeProps) => {
  const formatSpecialFragment = (specialFragmentString: string) => {
    if (!specialFragmentString) {
      return null;
    }

    interface SpecialFragment {
      ingredient: string;
      quantity: number;
      unit: string;
      number: number;
      classification: string;
    }

    const specialFragment: SpecialFragment = JSON.parse(specialFragmentString);

    if (specialFragment.number) {
      return (
        <span>
          {Math.round(
            ((specialFragment.number * props.recipe.CustomServings) /
              props.recipe.Servings) *
              100
          ) / 100}
        </span>
      );
    }

    if (specialFragment.quantity) {
      specialFragment.quantity =
        Math.round(
          ((specialFragment.quantity * props.recipe.CustomServings) /
            props.recipe.Servings) *
            100
        ) / 100;
    }

    if (specialFragment.quantity > 1 && specialFragment.unit) {
      specialFragment.unit += "'s";
    }

    return (
      <span
        className="font-bold underline decoration-2"
        style={{
          textDecorationColor: specialFragment.classification
            ? getHexColor(specialFragment.classification)
            : getRandomHexColor(),
        }}
      >
        {" "}
        {specialFragment.quantity} {specialFragment.unit}{" "}
        {specialFragment.ingredient}
      </span>
    );
  };

  const formatInstruction = (
    instruction: Instruction,
    index: number,
    ingredients: Ingredient[],
    cookware: string[]
  ) => {
    let clonedIngredients: Ingredient[] = structuredClone(ingredients);
    clonedIngredients.forEach((ingredient) => {
      const indexOfComma = ingredient.Name.indexOf(",");
      if (indexOfComma >= 0) {
        ingredient.Name = ingredient.Name.substring(0, indexOfComma);
      }
    });

    const specialTextInInstruction = parseSpecialTextInsideInstruction(
      instruction,
      index,
      clonedIngredients,
      cookware,
      []
    );

    let processedText: {
      phrase: string;
      isIngredientOrCookware: boolean;
      classification: string;
    }[] = [];

    const formatUnit = (unit: string, quantity: number): string => {
      if (quantity > 1) {
        return unit + "s";
      }

      return unit;
    };

    //todo
    //format quantity

    const getSpecialTextPhrase = (
      specialText: SpecialTextWithinInstruction
    ): string => {
      console.log(specialText);
      if (specialText.searchText === "bananas") {
        console.log(props.recipe.Instructions[specialText.instructionIndex]);
      }

      let formattedTextOne = "";
      if (specialText.quantity > 0) {
        const convertedQuantity =
          (specialText.quantity * props.recipe.CustomServings) /
          props.recipe.Servings;
        const mixedNumber = getMixedNumber(convertedQuantity);
        const formattedQuantity =
          (mixedNumber.whole > 0 ? mixedNumber.whole : "") +
          (mixedNumber.numerator > 0
            ? " " + mixedNumber.numerator + "/" + mixedNumber.denominator
            : "");
        if (specialText.unit !== "none") {
          formattedTextOne =
            "" +
            formattedQuantity +
            " " +
            formatUnit(specialText.unit, convertedQuantity) +
            " of " +
            specialText.searchText;
        } else if (convertedQuantity <= 1) {
          formattedTextOne =
            "" + formattedQuantity + " of the " + specialText.searchText;
        } else if (convertedQuantity > 1) {
          formattedTextOne =
            "" + formattedQuantity + " " + specialText.searchText;
        }
      } else {
        //fix here
        specialText.fillerWords.forEach((word) => {
          formattedTextOne += word + " ";
        });
        formattedTextOne += specialText.searchText;
      }

      let formattedTextTwo = "";
      if (specialText.importantWord) {
        if (!specialText.chainedIngredient) {
          if (specialText.importantWord === "all") {
            formattedTextTwo = "all the " + specialText.searchText;
          } else if (specialText.importantWord === "pinch") {
            formattedTextTwo = "a pinch of " + specialText.searchText;
          } else if (specialText.importantWord === "remaining") {
            formattedTextTwo = "the remaining " + specialText.searchText;
          }
        } else {
          formattedTextTwo = specialText.searchText;
        }
      }

      const isSpecialCharacterCaseOne =
        instruction.Instruction.charAt(
          specialText.textStop - 2
        ).toUpperCase() ===
        instruction.Instruction.charAt(specialText.textStop - 2).toLowerCase();
      const specialCharacterCaseOne = instruction.Instruction.charAt(
        specialText.textStop - 2
      );

      const isLeadingAnd =
        instruction.Instruction.substring(
          specialText.textStart,
          specialText.textStart + "and".length
        ).indexOf("and") === 0;

      // (isSpecialCharacter ? instruction.Instruction[specialText.textStop - 1] : "")
      return (
        (isLeadingAnd ? "and " : "") +
        (formattedTextTwo ? formattedTextTwo : formattedTextOne) +
        (isSpecialCharacterCaseOne ? specialCharacterCaseOne : "") +
        " "
      );
    };

    if (specialTextInInstruction.length === 0) {
      return <span>{instruction.Instruction}</span>;
    }

    let currentInstructionIndex = 0;
    let currentSpecialTextIndex = 0;

    while (currentInstructionIndex < instruction.Instruction.length) {
      if (specialTextInInstruction[currentSpecialTextIndex]) {
        const instructionFragment = instruction.Instruction.substring(
          currentInstructionIndex,
          specialTextInInstruction[currentSpecialTextIndex].textStart
        );
        processedText.push({
          phrase: instructionFragment,
          isIngredientOrCookware: false,
          classification: "",
        });

        const currentClassification =
          specialTextInInstruction[currentSpecialTextIndex].currentIngredient
            ?.Classification;
        processedText.push({
          phrase: getSpecialTextPhrase(
            specialTextInInstruction[currentSpecialTextIndex]
          ),
          isIngredientOrCookware: true,
          classification: currentClassification ? currentClassification : "",
        });
        currentInstructionIndex =
          specialTextInInstruction[currentSpecialTextIndex].textStop;
        currentSpecialTextIndex++;
      } else {
        processedText.push({
          phrase: instruction.Instruction.substring(currentInstructionIndex),
          isIngredientOrCookware: false,
          classification: "",
        });
        currentInstructionIndex = instruction.Instruction.length;
      }
    }

    return (
      <div>
        {processedText.map((text, index) => {
          if (text.isIngredientOrCookware) {
            return (
              <span
                key={index}
                style={{
                  textDecoration: "underline",
                  textDecorationThickness: "2px",
                  textDecorationColor: getHexColor(text.classification),
                }}
                className="font-bold"
              >
                {text.phrase + (false ? ", " : "")}
              </span>
            );
          } else {
            return <span key={index}>{text.phrase}</span>;
          }
        })}
      </div>
    );
  };

  const { data: cartRecipes = [] as Recipe[] } = useQuery(
    ["cart.me"],
    getRecipesFromCart
  );
  const { data: favoriteRecipes = [] as Recipe[] } = useQuery(
    ["favorites.me"],
    getRecipesFromFavorites
  );

  const [location, setLocation] = useLocation();

  const topEle = useRef<HTMLDivElement>(null);
  const ingredientsEle = useRef<HTMLDivElement>(null);
  const instructionsEle = useRef<HTMLDivElement>(null);
  const bottomEle = useRef<HTMLDivElement>(null);
  const indiviualInstructionEle = useRef<HTMLDivElement[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [scrollHeight, setScrollHeight] = useState<number>(0);
  const [addRecipeToFavoritesHover, setAddRecipeToFavoritesHover] =
    useState<boolean>(false);
  const [ingredients] = useState<Ingredient[]>(() => {
    props.recipe.Ingredients.sort((a, b) => {
      const aClassificationValue = getClassificationRanking(
        a.Classification.toLowerCase()
      );
      const bClassificationValue = getClassificationRanking(
        b.Classification.toLowerCase()
      );

      if (aClassificationValue === -1 && bClassificationValue === -1) {
        return 0;
      } else if (aClassificationValue === -1) {
        return 1;
      } else if (bClassificationValue === -1) {
        return -1;
      }

      if (aClassificationValue < bClassificationValue) {
        return -1;
      } else if (aClassificationValue === bClassificationValue) {
        return 0;
      }

      return 1;
    });
    props.recipe.Ingredients = [...props.recipe.Ingredients.sort()];
    return props.recipe.Ingredients;
  });

  useLayoutEffect(() => {
    let currentScroll = () => {
      setScrollHeight(window.pageYOffset);
    };

    window.addEventListener("scroll", currentScroll);
    return () => window.removeEventListener("scroll", currentScroll);
  }, []);

  useLayoutEffect(() => {
    let setSize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", setSize);

    return () => window.removeEventListener("resize", setSize);
  }, []);

  const queryClient = useQueryClient();

  const addRecipeToFavoritesMutation = useMutation(
    (recipe: Recipe) => addRecipeToFavorites(recipe),
    {
      onSuccess: () => {
        toast("Added Recipe to Favorites", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["favorites.me"]);
      },
    }
  );

  const removeRecipeFromFavoritesMutation = useMutation(
    (recipe: Recipe) => deleteRecipeFromFavorites(recipe),
    {
      // onMutate: async (variables) => {
      //   queryClient.setQueryData<Recipe[]>(["favorites.me"], (oldData) => {
      //     if(oldData) {
      //       return oldData.splice(oldData.indexOf(variables))
      //     }

      //     return []
      //   })
      // },
      onSuccess: () => {
        toast("Removed Recipe from Favorites", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(["favorites.me"]);
      },
    }
  );

  const addRecipeToCartMutation = useMutation(
    (recipe: Recipe) => addRecipeToCart(recipe),
    {
      // onSettled: async (variables) => {
      //   await queryClient.invalidateQueries(["cart.me"]);
      // },
      onSuccess: () => {
        toast("Added Recipe to Cart", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: (variables) => {
        queryClient.invalidateQueries(["cart.me"]);
      },
    }
  );

  const removeRecipeFromCartMutation = useMutation(
    (recipe: Recipe) => deleteRecipeFromCart(recipe),
    {
      // onSettled: async (variables) => {
      //   await queryClient.invalidateQueries(["cart.me"]);
      // },
      onSuccess: () => {
        toast("Removed Recipe from Cart", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
      },
      onSettled: (variables) => {
        queryClient.invalidateQueries(["cart.me"]);
      },
    }
  );

  return (
    <div className="w-full display-flex justify-around">
      {windowWidth < 640 && windowWidth > 300 && (
        <div className="fixed w-full flex flex-nowrap justify-around text-lg px-[10px] bg-opacity-30 mt-[5px] select-none">
          <span
            className="rounded-lg border-2 border-gray1 px-[5px] bg-gray4 cursor-pointer"
            onClick={() => {
              scrollIntoView(topEle.current);
            }}
          >
            Top
          </span>
          <span
            className="rounded-lg border-2 border-gray1 px-[5px] bg-gray4 cursor-pointer"
            onClick={() => {
              scrollIntoView(ingredientsEle.current);
            }}
          >
            Ingredients
          </span>
          <span
            className="rounded-lg border-2 border-gray1 px-[5px] bg-gray4 cursor-pointer"
            onClick={() => {
              scrollIntoView(instructionsEle.current);
            }}
          >
            Instructions
          </span>
          <span
            className="rounded-lg border-2 border-gray1 px-[5px] bg-gray4 cursor-pointer"
            onClick={() => {
              scrollIntoView(bottomEle.current);
            }}
          >
            Bottom
          </span>
        </div>
      )}
      {windowWidth < 717 &&
        windowWidth > 410 &&
        instructionsEle.current &&
        scrollHeight > instructionsEle.current.offsetTop - 130 && (
          <div className="fixed flex flex-col justify-start w-full mt-[28px]">
            {indiviualInstructionEle.current.map((currentEle, index) => {
              return (
                <div
                  className="ml-auto mt-[25px] p-[5px] select-none cursor-pointer"
                  onClick={() => scrollIntoView(currentEle)}
                >
                  <span>Step</span>
                  <span>{index + 1}</span>
                </div>
              );
            })}
          </div>
        )}
      <div className="w-full">
        <div ref={topEle} className="flex flex-nowrap justify-around">
          <span className="text-3xl capitalize pb-[20px] mt-[45px] sm:text-5xl">
            <TiArrowBackOutline
              className="mx-auto cursor-pointer hover:bg-gray-200 hover:text-gray-900 rounded-lg"
              onClick={() =>
                setLocation(location.substring(0, location.lastIndexOf("/")))
              }
            />
            {props.recipe.Title}
          </span>
        </div>

        {props.recipe.Source.indexOf("https://www.youtube.com/embed/") >= 0 && (
          <div className="w-[300px] p-[10px] border-8 rounded-3xl bg-black m-auto sm:w-[500px] sm:h-[375px]">
            <iframe
              className="w-full h-full"
              // height="315"
              src={props.recipe.Source}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {props.recipe.Source.indexOf(".pdf", props.recipe.Source.length - 4) >=
          0 && (
          <div className="flex flex-nowrap justify-around">
            <a href={props.recipe.Source} className="text-xs" target="_blank">
              {props.recipe.Source}
            </a>
          </div>
        )}

        <div ref={ingredientsEle} className="flex flex-nowrap justify-around">
          <span className="pt-[10px] text-xl sm:text-3xl">Ingredients</span>
        </div>

        <div className="w-full sm:w-[400px] m-auto">
          {ingredients.map((ingredient, index) => {
            return (
              <div
                className="pl-[20px] pr-[20px] pt-[5px] capitalize"
                key={index}
              >
                <div
                  className="flex flex-nowrap justify-between"
                  style={{
                    textDecoration: "underline",
                    textDecorationThickness: "2px",
                    textDecorationColor: getHexColor(ingredient.Classification),
                  }}
                >
                  <span>{ingredient.Name}</span>
                  <span>
                    {formatIngredientAmount(
                      ingredient.Unit,
                      ingredient.Quantity *
                        (props.recipe.CustomServings / props.recipe.Servings)
                    )}
                    {/* {formatIngredientAmount(
                      ingredient,
                      props.recipe.Servings,
                      props.recipe.CustomServings
                    )} */}
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        {props.recipe.Cookware.length > 0 && (
          <div
            ref={instructionsEle}
            className="flex flex-nowrap justify-around"
          >
            <span className="pt-[10px] text-xl sm:text-3xl">Cookware</span>
          </div>
        )}

        <div className="w-full sm:w-[400px] m-auto">
          {props.recipe.Cookware.map((cookware, index) => {
            return (
              <div
                className="pl-[20px] pr-[20px] pt-[5px] capitalize"
                key={index}
              >
                <span>{cookware}</span>
              </div>
            );
          })}
        </div>

        <div ref={instructionsEle} className="flex flex-nowrap justify-around">
          <span className="pt-[10px] text-xl sm:text-3xl">Instructions</span>
        </div>

        <div className="flex flex-wrap justify-evenly xl:mx-[200px]">
          {props.recipe.Instructions.map((instruction, index) => {
            return (
              <div
                ref={(ref) => {
                  if (ref) {
                    indiviualInstructionEle.current[index] = ref;
                  }
                }}
                className="pt-[20px] pl-[20px] pr-[20px] w-[350px]"
                key={index}
              >
                <div className="text-xl w-full flex flex-nowrap justify-between capitalize">
                  <div className="mx-[10px]">
                    <span>{instruction.Header}</span>
                  </div>
                  <div>
                    <span className="mx-[10px]">
                      {index + 1}
                      {"/"}
                      {props.recipe.Instructions.length}
                    </span>
                  </div>
                </div>
                <div className="mt-[10px] border-gray-600 rounded-b-md border-2 border-t-0 border-opacity-50">
                  <div className="p-[5px]">
                    <span className="leading-relaxed">
                      {formatInstruction(
                        instruction,
                        index,
                        ingredients,
                        props.recipe.Cookware
                      )}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div
          ref={bottomEle}
          className="h-[80px] flex flex-nowrap pt-[5px] text-center justify-around select-none mt-[30px] sm:text-2xl"
        >
          <div className="w-[20%]">
            <FiClock className="w-full" size="2em" />
            <span>{formatTime(props.recipe.TimeMinutes)}</span>
          </div>
          <div className="w-[25%]">
            <MdPerson className="w-full" size="2em" />
            <span className="pr-[2px]">
              {props.panel === "browse" || props.panel === "owned"
                ? props.recipe.Servings
                : props.recipe.CustomServings}
            </span>
          </div>

          {(props.panel === "browse" ||
            props.panel === "favorites" ||
            props.panel === "owned") &&
            !isRecipeInSelection(props.recipe.ID, cartRecipes) && (
              <div className="w-[20%] flex flex-nowrap justify-around">
                <GoPlus
                  className="border-opacity-0 border-[2px] rounded-lg hover:border-opacity-80 cursor-pointer border-blue-400 hover:border-gray-100"
                  size="2em"
                  onClick={() => {
                    addRecipeToCartMutation.mutate(props.recipe);
                  }}
                />
              </div>
            )}
          {(props.panel === "browse" || props.panel === "favorites") &&
            isRecipeInSelection(props.recipe.ID, cartRecipes) && (
              <div className="w-[20%] flex flex-nowrap justify-around">
                <GoDash
                  className="border-opacity-0 border-[2px] rounded-lg hover:border-opacity-80 cursor-pointer border-blue-400 hover:border-gray-100"
                  size="2em"
                  onClick={() => {
                    removeRecipeFromCartMutation.mutate(props.recipe);
                  }}
                />
              </div>
            )}
          {props.panel === "cart" && (
            <div className="w-[20%]">
              {isRecipeInSelection(props.recipe.ID, favoriteRecipes) && (
                <MdFavorite
                  className="w-full cursor-pointer"
                  size="2em"
                  color="red"
                  onClick={() => {
                    removeRecipeFromFavoritesMutation.mutate(props.recipe);
                  }}
                />
              )}
              {!isRecipeInSelection(props.recipe.ID, favoriteRecipes) && (
                <MdFavorite
                  className="w-full cursor-pointer"
                  size="2em"
                  color={addRecipeToFavoritesHover ? "red" : "#f3f4f6"}
                  onMouseOver={() => {
                    setAddRecipeToFavoritesHover(true);
                  }}
                  onMouseLeave={() => {
                    setAddRecipeToFavoritesHover(false);
                  }}
                  onClick={() => {
                    addRecipeToFavoritesMutation.mutate(props.recipe);
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="flex flex-nowrap justify-around">
          <span className="text-3xl capitalize pb-[20px] mt-[45px] sm:text-5xl">
            <TiArrowBackOutline
              className="cursor-pointer hover:bg-gray-200 hover:text-gray-900 rounded-lg"
              onClick={() =>
                setLocation(location.substring(0, location.lastIndexOf("/")))
              }
            />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FocusRecipe;
