import React, { useState, useLayoutEffect, useEffect } from "react";
import Recipe from "../interfaces/RecipeInterface";
import Ingredient from "../interfaces/Ingredient";
import { getRecipesFromCart } from "./API/API";
import { useQuery } from "react-query";
import {
  getClassificationRanking,
  getHexColor,
  setIngredientsClone,
  getIngredientsClone,
  setIngredientViewClone,
  getIngredientViewClone,
} from "./API/OfflineAPI";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { BiWindow } from "react-icons/bi";
import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { formatIngredientAmount } from "./API/OfflineAPI";

const getIngredientList = (recipes: Recipe[]): Ingredient[] => {
  let ingredientsMap = new Map<String, Ingredient>();

  recipes.forEach((recipe: Recipe) => {
    recipe.Ingredients.forEach((ingredient) => {
      const key = ingredient.Name + ingredient.Unit;
      let currentIngredient = ingredientsMap.get(key);

      //convert units ex: ounce to oz
      if (currentIngredient) {
        currentIngredient.Quantity =
          (recipe.CustomServings / recipe.Servings) * ingredient.Quantity +
          currentIngredient.Quantity;
      } else {
        let newIngredient = { ...ingredient };
        newIngredient.Acquired = false;
        newIngredient.Quantity =
          (recipe.CustomServings / recipe.Servings) * ingredient.Quantity;
        ingredientsMap.set(key, newIngredient);
      }
    });
  });
  return Array.from(ingredientsMap.values());
};

const getClassificationIngredientList = (
  ingredients: Ingredient[]
): Ingredient[][] => {
  let result: Ingredient[][] = [];

  let currentIngredients: Ingredient[] = [];

  ingredients.forEach((ingredient, index) => {
    if (index === 0) {
      currentIngredients.push(ingredient);
    } else if (
      currentIngredients[0].Classification === ingredient.Classification
    ) {
      currentIngredients.push(ingredient);
    } else {
      if (getClassificationRanking(currentIngredients[0].Classification) >= 0) {
        result.push([...currentIngredients]);
        currentIngredients = [];
      }

      currentIngredients.push(ingredient);
    }
  });

  if (currentIngredients.length > 0) {
    result.push(currentIngredients);
  }

  return result;
};

const sameIngredientsInCart = (ingredientListOne: Ingredient[], ingredientListTwo: Ingredient[]):boolean => {
  if(ingredientListOne.length === 0 || ingredientListTwo.length === 0){
    return false
  }


  for(let i = 0; i<ingredientListOne.length; i++){
    const foundIngredient = ingredientListTwo.findIndex((ingredientTwo) => {
      return ingredientListOne[i].Name === ingredientTwo.Name && ingredientListOne[i].Quantity === ingredientTwo.Quantity
    })
    console.log(foundIngredient)
    if(foundIngredient < 0){
      console.log("returning false")
      return false
    }
  }

  return true
}


const YourIngredients = () => {
  const { data: cartRecipes = [] as Recipe[] } = useQuery(
    ["cart.me"],
    getRecipesFromCart,
    {
      onSuccess: (data) => {
        if (data) {
          const ingredientsList = getIngredientList(data)
          const sameIngredients = sameIngredientsInCart(ingredientsList, getIngredientsClone())



          if(getIngredientsClone().length === 0 || !sameIngredients){
            console.log("setting resh maybe")
            setIngredients(ingredientsList);
          } else {
            setIngredients(getIngredientsClone())
          }
        }
      },
    }
  );

  // const [acquiredIngredients, setAcquiredIngredients] = useState<string[]>([]);
  const [ingredients, setIngredients] = useState<Ingredient[]>([])


  const [ingredientView, setIngredientView] = useState(() => {
    if(getIngredientViewClone().length === 0){
      return "icon"
    } else {
      return getIngredientViewClone()
    }

  });

  useEffect(() => {
    if(ingredients.length > 0) {
      setIngredientsClone(ingredients)
    }
  }, [ingredients]);

  useEffect(() => {
    setIngredientViewClone(ingredientView)

  }, [ingredientView])

  // let currentIngredients: Ingredient[] = [];
  // const totalIngredientsMap: { [ingredientName: string]: Ingredient } = {};

  // cartRecipes.forEach((recipe: Recipe) => {
  //   recipe.Ingredients.forEach(ingredient => {
  //     ingredient.Acquired = false
  //     currentIngredients.push(ingredient)
  //     // let ingredientAlreadyAcquired = false;
  //     // acquiredIngredients.forEach(acquiredIngredient => {
  //     //   if (ingredient.Name === acquiredIngredient.Name) {
  //     //     ingredientAlreadyAcquired = true;
  //     //   }
  //     // });

  //     // if (!ingredientAlreadyAcquired) {
  //     //   if (totalIngredientsMap[ingredient.Name]) {
  //     //     totalIngredientsMap[ingredient.Name].Quantity +=
  //     //       (recipe.CustomServings / recipe.Servings) * ingredient.Quantity;
  //     //   } else {
  //     //     totalIngredientsMap[ingredient.Name] = {
  //     //       Name: ingredient.Name,
  //     //       Quantity:
  //     //         (recipe.CustomServings / recipe.Servings) *
  //     //         ingredient.Quantity,
  //     //       Classification: ingredient.Classification,
  //     //       Unit: ingredient.Unit
  //     //     };
  //     //   }
  //     // }
  //   });
  // });

  // Object.keys(totalIngredientsMap).forEach(ingredient => {

  //   totalIngredients.push({
  //     Name: totalIngredientsMap[ingredient].Name,
  //     Quantity: totalIngredientsMap[ingredient].Quantity,
  //     Classification: totalIngredientsMap[ingredient].Classification,
  //     Unit: totalIngredientsMap[ingredient].Unit
  //   });
  // });

  // return totalIngredients;
  //   }
  // );

  useLayoutEffect(() => {
    window.scroll(0, 0);
  }, []);

  let addAcquiredIngredient = (ingredient: Ingredient) => {
    // let currentAcquiredIngredients = [...acquiredIngredients];
    //currentAcquiredIngredients.push(ingredient);
    //props.setAcquiredIngredients(currentAcquiredIngredients);
    //setAcquiredIngredients(currentAcquiredIngredients);
  };

  let removeAcquiredIngredient = (ingredient: Ingredient) => {
    //let currentAcquiredIngredients = [...acquiredIngredients];
    // currentAcquiredIngredients.splice(
    //   currentAcquiredIngredients.indexOf(ingredient),
    //   1
    // );
    //props.setAcquiredIngredients(currentAcquiredIngredients);
    //setAcquiredIngredients(currentAcquiredIngredients);

    addIngredient(ingredient);
  };

  let addIngredient = (ingredient: Ingredient) => {
    //let currentIngredients = [...ingredients];
    //currentIngredients.push(ingredient);
    //setIngredients(currentIngredients);
  };

  let removeIngredient = (ingredient: Ingredient) => {
    //let currentIngredients = [...ingredients];
    //currentIngredients.splice(currentIngredients.indexOf(ingredient), 1);
    //setIngredients(currentIngredients);

    addAcquiredIngredient(ingredient);
  };

  // let test = [ingredients.keys()]
  // test.map((key, index, value) => {
  //   console.log(key)
  //   console.log(ingredients)
  //   console.log(index)
  //   console.log(value)
  // })

  ingredients.sort((a, b) => {
    const aClassificationValue = getClassificationRanking(
      a.Classification.toLowerCase()
    );
    const bClassificationValue = getClassificationRanking(
      b.Classification.toLowerCase()
    );

    if (aClassificationValue === -1 && bClassificationValue === -1) {
      return 0;
    } else if (aClassificationValue === -1) {
      return 1;
    } else if (bClassificationValue === -1) {
      return -1;
    }

    if (aClassificationValue < bClassificationValue) {
      return -1;
    } else if (aClassificationValue === bClassificationValue) {
      return 0;
    }

    return 1;
  });

  if (ingredients.length === 0) {
    return (
      <div className="w-full flex flex-nowrap justify-center text-3xl">
        <span>Try adding a recipe!</span>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-nowrap justify-center items-center text-3xl py-[20px]">
        <span
          className="px-[10px] mx-[5px] border-4 cursor-pointer p-[3px] bg-gray1"
          onClick={() => {
            if (ingredientView != "icon") {
              setIngredientView("icon");
            }
          }}
          style={{
            borderColor: ingredientView === "icon" ? "#f3f4f6" : "#252526",
          }}
        >
          <BiWindow />
        </span>
        <span
          className="px-[10px] mx-[5px] border-4 cursor-pointer p-[3px] bg-gray1"
          onClick={() => {
            if (ingredientView != "list") {
              setIngredientView("list");
            }
          }}
          style={{
            borderColor: ingredientView === "list" ? "#f3f4f6" : "#252526",
          }}
        >
          <AiOutlineUnorderedList />
        </span>
      </div>
      <div className="flex flex-wrap w-full justify-center m-auto sm:w-[640px]">
        {ingredientView === "icon" &&
          ingredients.map((ingredient, index) => {
            if (!ingredient.Acquired) {
              return (
                <div
                  key={index + "false"}
                  className="m-[5px] sm:m-[10px] h-[115px] w-[115px] text-sm sm:h-[150px] sm:w-[150px] sm:text-lg flex flex-col justify-center bg-gray1 select-none border-4 rounded-xl capitalize text-lg text-center sm:font-semibold cursor-pointer overflow-hidden"
                  style={{
                    borderColor: getHexColor(ingredient.Classification),
                  }}
                  onClick={() => {
                    let currentIngredients = [...ingredients];
                    currentIngredients[index].Acquired =
                      !currentIngredients[index].Acquired;
                    setIngredients(currentIngredients);
                  }}
                >
                  <span className="mx-[5px]">{ingredient.Name}</span>
                  {ingredient.Quantity > 0 && (
                    <span className="mx-auto">
                      {formatIngredientAmount(
                        ingredient.Unit,
                        ingredient.Quantity,
                        true
                      )}
                    </span>
                  )}
                </div>
              );
            }
          })}
        {ingredientView === "icon" &&
          ingredients.map((ingredient, index) => {
            if (ingredient.Acquired) {
              return (
                <div
                  key={index + "true"}
                  className="m-[5px] sm:m-[10px] h-[115px] w-[115px] text-sm sm:h-[150px] sm:w-[150px] sm:text-lg flex flex-col justify-center bg-gray1 select-none border-4 rounded-xl capitalize text-lg text-center sm:font-semibold cursor-pointer overflow-hidden"
                  onClick={() => {
                    let currentIngredients = [...ingredients];
                    currentIngredients[index].Acquired =
                      !currentIngredients[index].Acquired;
                    setIngredients(currentIngredients);
                  }}
                >
                  <span>{ingredient.Name}</span>
                  <span>
                    {formatIngredientAmount(
                      ingredient.Unit,
                      ingredient.Quantity
                    )}
                  </span>
                </div>
              );
            }
          })}

        {ingredientView === "list" && (
          <div className="m-[10px] flex select-none flex-wrap overflow-clip text-2xl w-[400px] text-base sm:text-xl capitalize">
            {getClassificationIngredientList(ingredients).map(
              (organizedIngredients, indexOne) => {
                return (
                  <div
                    key={indexOne}
                    className="border-4 rounded-lg py-[10px] my-[10px] w-full flex flex-wrap"
                    style={{
                      borderColor: getHexColor(
                        organizedIngredients[0].Classification
                      ),
                    }}
                  >
                    <div className="flex flex-nowrap justify-around w-full font-semibold">
                      <span className="m-auto">
                        {getClassificationRanking(
                          organizedIngredients[0].Classification
                        ) >= 0
                          ? organizedIngredients[0].Classification
                          : "other"}
                      </span>
                    </div>

                    {organizedIngredients.map((ingredient, indexTwo) => {
                      return (
                        <div
                          key={indexOne + "" + indexTwo}
                          className="flex flex-nowrap p-[5px] items-center w-full"
                        >
                          <div className="w-full flex flex-nowrap justify-between">
                            <div>
                              <span>{ingredient.Name}</span>
                            </div>
                            <div>
                              <span>
                                {formatIngredientAmount(
                                  ingredient.Unit,
                                  ingredient.Quantity
                                )}
                              </span>
                            </div>
                          </div>
                          <div
                            className="ml-[10px] cursor-pointer"
                            onClick={() => {
                              let currentIngredients = [...ingredients];
                              const currentIndex =
                                currentIngredients.indexOf(ingredient);
                              currentIngredients[currentIndex].Acquired =
                                !currentIngredients[currentIndex].Acquired;
                              setIngredients(currentIngredients);
                            }}
                          >
                            {ingredient.Acquired ? (
                              <ImCheckboxChecked />
                            ) : (
                              <ImCheckboxUnchecked />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              }
            )}
          </div>
        )}

        {/* {ingredientView === "list" && (
          <div className="my-[10px] flex select-none flex-wrap overflow-clip text-2xl w-[400px] capitalize">
            {ingredientView === "list" &&
              ingredients.map((ingredient, index) => {
                return (
                  <div className="flex flex-nowrap  w-[400px] my-[20px] p-[5px] items-center">
                    <div
                      className="w-full border-4 flex flex-nowrap justify-between"
                      style={{
                        borderColor: getHexColor(ingredient.Classification),
                      }}
                    >
                      <div>
                        <span>{ingredient.Name}</span>
                      </div>
                      <div>
                        <span>
                          {ingredient.Quantity > 0 ? ingredient.Quantity : ""}
                        </span>
                        <span>{ingredient.Unit}</span>
                      </div>
                    </div>
                    <div
                      className="ml-[10px]"
                      onClick={() => {
                        let currentIngredients = [...ingredients];
                        currentIngredients[index].Acquired =
                          !currentIngredients[index].Acquired;
                        setIngredients(currentIngredients);
                      }}
                    >
                      {ingredient.Acquired ? (
                        <ImCheckboxChecked />
                      ) : (
                        <ImCheckboxUnchecked />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default YourIngredients;
