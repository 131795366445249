import React, { useState, useEffect } from "react";
import OAuth from "../../interfaces/OAuth";
import {externalIP} from "../API/API"

const CurrentUser = () => {
  const [userView, setUserView] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const [newUser, setNewUser] = useState(false);
  const [email, setEmail] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [password, setPassword] = useState("");
  const [hideFavorites, setHideFavorites] = useState(false);

  let isNewUserValid = (): boolean => {
    // if(email.length > 0 && displayName.length > 0 && password.length > 0) {
    //   return true
    // }
    // return false;

    return email.length > 0 && displayName.length > 0 && password.length > 0;
  };

  let isLoginValid = (): boolean => {
    // if(email.length > 0 && displayName.length > 0 && password.length > 0) {
    //   return true
    // }
    // return false;

    return email.length > 0 && password.length > 0;
  };

  let makeId = (length: number): string => {
    let result = "";
    let entries =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < length; i++) {
      result += entries.charAt(Math.floor(Math.random() * entries.length));
    }

    return result;
  };

  async function generateCodeChallenge() {
    console.log(sha256(makeId(128)));
  }

  async function sha256(hash: string) {
    // encode as UTF-8
    const msgBuffer = new TextEncoder().encode(hash);

    // hash the message
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

    // convert ArrayBuffer to Array
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    // convert bytes to hex string
    const hashHex = hashArray
      .map((b) => ("00" + b.toString(16)).slice(-2))
      .join("");

    return hashHex;
  }

  async function login() {
    //loginGoogleOAuth()
    // if (isLoginValid()) {
    //   let hashedPassword = await sha256(password);
    //   let currentUser: UserInterface = {
    //     Email: email,
    //     DisplayName: displayName,
    //     Password: hashedPassword,
    //     HideFavorites: hideFavorites,
    //     Token: "",
    //     ID: 0,
    //   };
    //   Axios.post("http://127.0.0.1:8080/user/login", currentUser)
    //     .then((res) => {
    //       console.log(res);
    //       currentUser.DisplayName = res.data.DisplayName;
    //       currentUser.ID = res.data.ID;
    //       currentUser.Token = res.data.Token;
    //       console.log(res.data.DisplayName);
    //       if (res.status === 200) {
    //         props.setUser(currentUser);
    //         props.SetActiveComponentToDefault();
    //       }
    //     })
    //     .catch((error) => {
    //       console.log();
    //     });
    // }
  }

  let redirectPath = ""
  //@ts-ignore
  if(externalIP === "https://127.0.0.1:8080") {
    redirectPath = "https://127.0.0.1:3000"
  } else {
    redirectPath = "https://www.calpie.com"
  }


  async function loginGoogle() {
    let oAuth: OAuth = {
      client_id:
        "1010689498031-hh94elov53esp63jv8ma18brrgdpsm9a.apps.googleusercontent.com",
      redirect_uri: redirectPath,
      response_type: "token",
      scope: "https://www.googleapis.com/auth/userinfo.email",
      state: "google",
    };

    let queryString: string = new URLSearchParams({ ...oAuth }).toString();
    let url: string = "https://accounts.google.com/o/oauth2/v2/auth?";
    console.log("https://accounts.google.com/o/oauth2/v2/auth?" + queryString);
    window.location.href = url + queryString;
  }

  async function loginFaceBook() {
    let oAuth = {
      client_id: "908127806812050",
      redirect_uri: redirectPath,
      response_type: "token",
      scope: "email",
      state: "facebook",
    };

    let queryString: string = new URLSearchParams({ ...oAuth }).toString();
    let url: string = "https://www.facebook.com/v14.0/dialog/oauth?";
    console.log(url + queryString);
    window.location.href = url + queryString;
  }

  // async function loginTwitter() {
  //   let oAuth = {
  //     client_id: "WXdZem9iX1BQZkU2UUFFZVdORkI6MTpjaQ",
  //     redirect_uri: "https://127.0.0.1:3000",
  //     response_type: "code",
  //     scope: "users.read",
  //     code_challenge: "iamastringthatslngerthan48characetersandlessthan128withnoaddedsecurity",
  //     code_challenge_method: "plain",
  //     state: "twitter",
  //   };

  //   let queryString: string = new URLSearchParams({ ...oAuth }).toString();
  //   let url: string = "https://twitter.com/i/oauth2/authorize?";
  //   console.log(url + queryString);
  //   window.location.href = url + queryString;
  // }

  async function loginDiscord() {
    let oAuth = {
      client_id: "1000120138437242980",
      redirect_uri: redirectPath,
      response_type: "token",
      scope: "email identify",
      state: "discord",
    };

    let queryString: string = new URLSearchParams({ ...oAuth }).toString();
    let url: string = "https://discord.com/api/oauth2/authorize?";
    window.location.href = url + queryString;
  }

  async function loginTwitch() {
    let oAuth = {
      client_id: "8l814f4viiicoonh7apib7x70bssme",
      redirect_uri: redirectPath,
      response_type: "token",
      scope: "user:read:email",
      state: "twitch",
      force_verify: "true",
    };

    let queryString: string = new URLSearchParams({ ...oAuth }).toString();
    let url: string = "https://id.twitch.tv/oauth2/authorize?";
    window.location.href = url + queryString;
  }

  async function createUser() {
    // if (isNewUserValid()) {
    //   //let hashedPassword = await sha256(password);
    //   let newUser: UserInterface = {
    //     Email: email.toLowerCase(),
    //     DisplayName: displayName,
    //     Password: password,
    //     HideFavorites: hideFavorites,
    //     Token: "",
    //     ID: 0,
    //   };
    //   Axios.post("http://127.0.0.1:8080/user/create", newUser)
    //     .then((res) => {
    //       console.log(res);
    //       console.log("are we herE?");
    //       if (res.status === 200) {
    //         props.setUser(newUser);
    //         props.SetActiveComponentToDefault();
    //       }
    //     })
    //     .catch((error) => {});
    // }
  }

  return (
    <div className="mt-[30px] text-black">
      <div
        className="mx-auto bg-blue-200 flex flex-nowrap justify-center items-center w-[180px] mt-[10px] h-[55px] hover:ring-2 hover:ring-blue-200 rounded-md"
        onClick={() => {
          loginGoogle();
        }}
      >
<div className="flex flex-nowrap px-[3px] items-center text-sm w-full font-semibold cursor-pointer ">
          <img
            height="40"
            width="40"
            src={process.env.PUBLIC_URL + "GoogleLogo.svg"}
          />
          <span className="capitalize ml-[10px]">login with google</span>
        </div>
      </div>

      <div
        className="mx-auto bg-blue-200 flex flex-nowrap justify-center items-center w-[180px] mt-[10px] h-[55px] hover:ring-2 hover:ring-blue-200 rounded-md"
        onClick={() => {
          loginFaceBook();
        }}
      >
        <div className="flex flex-nowrap px-[3px] items-center text-sm w-full font-semibold cursor-pointer ">
          <img
            height="40"
            width="40"
            src={process.env.PUBLIC_URL + "f_logo_RGB-Blue_58.png"}
          />
          <span className="capitalize ml-[10px]">login with facebook</span>
        </div>
      </div>

      <div
        className="mx-auto bg-blue-200 flex flex-nowrap justify-center items-center w-[180px] mt-[10px] h-[55px] hover:ring-2 hover:ring-blue-200 rounded-md"
        onClick={() => {
          loginDiscord();
        }}
      >
        <div className="flex flex-nowrap px-[3px] items-center text-sm w-full font-semibold cursor-pointer">
          <img
            height="40"
            width="40"
            src={process.env.PUBLIC_URL + "Discord-Logo-Color.png"}
          />
          <span className="capitalize ml-[10px]">login with discord</span>
        </div>
      </div>

      <div
        className="mx-auto bg-blue-200 flex flex-nowrap justify-center items-center w-[180px] mt-[10px] h-[55px] hover:ring-2 hover:ring-blue-200 rounded-md"
        onClick={() => {
          loginTwitch();
        }}
      >
        <div className="flex flex-nowrap ml-[5px] items-center text-sm w-full font-semibold cursor-pointer">
          <img
            height="35"
            width="35"
            src={process.env.PUBLIC_URL + "twitch.png"}
          />
          <span className="capitalize ml-[13px]">login with twitch</span>
        </div>
      </div>
    </div>
  );
};

export default CurrentUser;
