import axios, { AxiosResponse } from "axios";
import Recipe from "../../interfaces/RecipeInterface";
import UserInterface from "../../interfaces/UserInterface";
import OAuthToken from "../../interfaces/OAuthToken";
import {
  setOfflineCart,
  getOfflineCart,
  setOfflineFavorites,
  getOfflineFavorites,
  addOfflineRecipeToCart,
  removeOfflineRecipeFromCart,
  addOfflineRecipeToFavorites,
  removeOfflineRecipeFromFavorites,
  updateOfflineRecipeInFavorites,
  updateOfflineRecipeInCart,
} from "./OfflineAPI";
import jwt_decode from "jwt-decode";
import SearchOptions from "../../interfaces/SearchOptions";

let token = "";

//const externalIP = "https://192.168.0.234:8080"
//const externalIP = "https://192.168.0.46:8080";
//const externalIP = "https://192.168.0.118:8080";
//const externalIP = "https://34.71.133.115:8080";

//development
//export const externalIP = "https://127.0.0.1:8080";
//prod
export const externalIP = "https://www.calpie.com:8080"

// export const getAPIUser = (): UserInterface | null => {
//   return user;
// };

// export const login = async(username: string, password: string): Promise<boolean> => {
//   const response = await axios.post('login.???', {username, password});

//   token = response.token;

//   return true;
// }



export const clearToken = () => {
  // if(refreshTokenTimeout){
  //   clearTimeout(refreshTokenTimeout)
  // }
  token = "";
};

export const getRecipesFromCart = async (): Promise<Recipe[]> => {
  if (token.length > 0) {
    const response = await axios.get<Recipe[]>(externalIP + "/user/cart/", {
      headers: {
        Authorization: "basic " + token,
      },
    });
    if (response.status === 200) {
      //console.log(response.data);

      response.data.sort((a, b) => (a.Title > b.Title ? 1 : -1));
      setOfflineCart(response.data);
      return response.data;
      // setOfflineCart(JSON.parse(response.data));
      // return JSON.parse(response.data);
    }
  }

  return getOfflineCart();
};

export const addRecipeToCart = async (recipe: Recipe): Promise<void> => {
  //console.log(recipe);
  if (token.length > 0) {
    const response = await axios.post(externalIP + "/user/cart/", recipe, {
      headers: {
        Authorization: "basic " + token,
      },
    });
  }

  addOfflineRecipeToCart(recipe);
};

export const updateRecipeInCart = async (recipe: Recipe): Promise<void> => {
  //console.log("hitting api")
  if (token.length > 0) {
    const response = await axios.post(externalIP + "/user/cart/", recipe, {
      headers: {
        Authorization: "basic " + token,
      },
    });
  }

  updateOfflineRecipeInCart(recipe);
};

export const deleteRecipeFromCart = async (recipe: Recipe): Promise<void> => {
  if (token.length > 0) {
    const response = await axios.delete(externalIP + "/user/cart/", {
      headers: {
        Authorization: "basic " + token,
      },
      params: {
        RecipeID: recipe.ID,
      },
    });
  }

  removeOfflineRecipeFromCart(recipe);
};

export const getRecipesFromFavorites = async (): Promise<Recipe[]> => {
  if (token.length > 0) {
    const response = await axios.get<Recipe[]>(externalIP + "/user/favorite/", {
      headers: {
        Authorization: "basic " + token,
      },
    });

    if (response.status === 200) {
      response.data.sort((a, b) => (a.Title > b.Title ? 1 : -1));
      setOfflineFavorites(response.data);
      return response.data;
    }
  }
  return getOfflineFavorites();
};

export const addRecipeToFavorites = async (recipe: Recipe): Promise<void> => {
  if (token.length > 0) {
    const response = await axios.post(externalIP + "/user/favorite/", recipe, {
      headers: {
        Authorization: "basic " + token,
      },
    });
  }
  addOfflineRecipeToFavorites(recipe);
};

export const updateRecipeInFavorites = async (
  recipe: Recipe
): Promise<void> => {
  if (token.length > 0) {
    const response = await axios.post(externalIP + "/user/favorite/", recipe, {
      headers: {
        Authorization: "basic " + token,
      },
    });
  }
  updateOfflineRecipeInFavorites(recipe);
};

export const deleteRecipeFromFavorites = async (
  recipe: Recipe
): Promise<void> => {
  if (token.length > 0) {
    const response = await axios.delete(externalIP + "/user/favorite/", {
      headers: {
        Authorization: "basic " + token,
      },
      params: {
        RecipeID: recipe.ID,
      },
    });
  }
  removeOfflineRecipeFromFavorites(recipe);
};

//const refreshInterval = (60000/4)



export const refreshToken = async ():Promise<boolean> => {
  if(token.length > 0) {
    const response: AxiosResponse<string> = await axios.get(
      externalIP + "/user/token/refresh",
      {
        headers: {
          Authorization: "basic " + token,
        },
      }
    );
  
    if(response.status === 200) {
      token = response.data
      return true
    }
  }
  return false
};

export const oAuthLogin = async (
  oAuthToken: OAuthToken,
  onSuccess?: Function
): Promise<UserInterface | null> => {
  const response = await axios.post<string>(
    externalIP + "/user/oauthtoken/login",
    oAuthToken
  );

  if (response.status === 200) {
    //console.log(response.data);
    var currentUser = jwt_decode<UserInterface>(response.data);
    token = response.data;
    if (onSuccess) {
      onSuccess(currentUser);
      refreshToken();
    }
    return currentUser;
  }
  return null;
};

export const setSettings = async (
  hideFavorites: boolean,
  displayName: string
) => {
  if (token.length > 0) {
    const response = await axios.post(
      externalIP + "/user/settings",
      {
        DisplayName: displayName,
        HideFavoriteS: hideFavorites,
      },
      {
        headers: {
          Authorization: "basic " + token,
        },
      }
    );
  }
};

export const updateRecipe = async (recipe: Recipe): Promise<boolean> => {
  if (token.length > 0) {
    const response = await axios.post(
      externalIP + "/user/updaterecipe",
      { Recipe: recipe, RecipeID: recipe.ID },
      {
        headers: {
          Authorization: "basic " + token,
        },
      }
    );

    return response.status === 200;
  }
  return false;
};

export const getOwnedRecipes = async (): Promise<Recipe[]> => {
  if (token.length > 0) {
    const response: AxiosResponse<Recipe[]> = await axios.get(
      externalIP + "/user/ownedrecipes",
      {
        headers: {
          Authorization: "basic " + token,
        },
        params: {
          from: 0,
        },
      }
    );
    if (response.status === 200) {
      response.data.forEach((recipe) => {
        recipe.CustomServings = recipe.Servings;
      });
      return response.data;
    }
  }
  return [];
};

// export const getOwnedRecipe = async (recipeID: string): Promise<Recipe | null> => {

//   if (token.length > 0 && recipeID.length > 0) {
//     const response: AxiosResponse<Recipe> = await axios.get(
//       externalIP + "/user/ownedrecipe",
//       {
//         headers: {
//           Authorization: "basic " + token,
//         },
//         params: {
//           recipeID: recipeID,
//         },
//       }
//     );
//     if (response.status === 200) {
//       response.data.CustomServings = response.data.Servings
//       console.log("SINGLE RECIPE")
//       console.log(response.data)
//       return response.data;
//     }
//   }
//   return null;
// };

export const newRecipe = async (recipe: Recipe): Promise<boolean> => {
  if (token.length > 0) {
    const response = await axios.post(
      externalIP + "/user/createrecipe",
      recipe,
      {
        headers: {
          Authorization: "basic " + token,
        },
      }
    );

    return response.status === 200;
  }
  return false;
};

export const getAllRecipes = async (from: number): Promise<Recipe[]> => {
  const response: AxiosResponse<Recipe[]> = await axios.get(
    externalIP + "/recipe/getall/" + from
  );

  if (response.status === 200) {
    response.data.forEach((value) => {
      value.CustomServings = value.Servings;
    });
    response.data.sort((a, b) => (a.Title > b.Title ? 1 : -1));
    return response.data;
  }
  return [];
};

export const searchRecipes = async (
  options: SearchOptions
): Promise<Recipe[]> => {
  const response: AxiosResponse<Recipe[]> = await axios.post(
    externalIP + "/recipe/searchtitle",
    options
  );

  if (response.status === 200) {
    response.data.forEach((value) => {
      value.CustomServings = value.Servings;
    });
    response.data.sort((a, b) => (a.Title > b.Title ? 1 : -1));
    return [...response.data];
  }
  return [];
};

export const getPublicRecipe = async (
  recipeID: string
): Promise<Recipe | null> => {
  const response = await axios.get(externalIP + "/recipe", {
    params: {
      RecipeID: recipeID,
    },
  });

  if (response.status === 200) {
    const result: Recipe = JSON.parse(response.data);
    return result;
  }

  return null;
};

export const getPendingRecipes = async (from: number): Promise<Recipe[]> => {
  if (token.length > 0) {
    const response = await axios.get<Recipe[]>(externalIP + "/user/admin/pendingapproval", {
      params: {
        from: from
      },
      headers: {
        Authorization: "basic " + token,
      },
    });

    if (response.status === 200) {
      response.data.sort((a, b) => (a.Title > b.Title ? 1 : -1));
      return response.data;
    }
  }

  return []
};

export const approveRecipe = async (recipeID: string): Promise<boolean> => {
  if(token.length > 0) {
    const response = await axios.post(externalIP + "/user/admin/approverecipe", {RecipeID: recipeID},{
      headers: {
        Authorization: "basic " + token,
      },
    });

    return response.status === 200
  }
  return false
}

export const unapproveRecipe = async (recipeID: string): Promise<boolean> => {
  if(token.length > 0) {
    const response = await axios.post(externalIP + "/user/admin/unapproverecipe", {RecipeID: recipeID},{
      headers: {
        Authorization: "basic " + token,
      },
    });

    return response.status === 200
  }
  return false
}